.wrapper {
  overflow: scroll;
}

.modal-wrapper {
  min-height: 200px;
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 16px;
}

.heart-image {
  width: 240px;
  margin: 0 auto 16px;
  display: block;
  margin-bottom: 0;
}
.congrats {
  color: #fc9e43;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 0;
  position: relative;
}
.congrats::after {
  content: 'CONGRATULATIONS';
  font-size: 30px;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  color: #fc9e43;
  font-weight: 900;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
}

.main-text {
  text-align: center;
  color: #838383;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.expand-register-modal {
  height: 300px;
}

.bottom-button {
  padding: 7px 10px;
}

.loader-container {
  position: relative;
  min-height: 50px;
  width: 100%;
}

.image-container {
  position: relative;
  height: 175px;
  background-color: red;
}
.computer-image {
  position: absolute;
  height: 266px;
  top: -49px;
}

.sessions-link {
  font-size: 14px;
  margin-top: 16px;
  display: block;
  text-align: center;
  font-style: italic;
}

.bottom-title {
  margin-top: 32px;
  font-weight: bold;
  color: #212427;
  font-size: 16px;
  display: none;
}

.bottom-row {
  display: none;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 16px;
}

.bottom-item {
  background-color: #f4f4ff;
  padding: 12px;
  line-height: 1.2;
  font-size: 12px;
  margin: 0 16px;
  border-radius: 25px;
}

.bottom-image {
  max-width: 100px;
}

.bottom-text {
  margin-top: 12px;
}

@media (min-width: 768px) {
  .expand-register-modal {
    height: 360px;
  }

  .congrats {
    font-size: 32px;
  }
  .congrats::after {
    font-size: 50px;
  }

  .bottom-title {
    display: block;
  }

  .bottom-row {
    display: flex;
  }
}

@media (min-width: 992px) {
  .title {
    font-size: 18px;
  }
}
