.container {
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(198, 198, 198);
  display: flex;
  height: 40px;
  margin-bottom: 8px;
  width: 100%;
  cursor: pointer;
  border-radius: 100px;
  color: rgb(56, 56, 56);
  font-weight: 500;
  line-height: 28px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  position: relative;
  transition: all 0.3s ease-out 0s;
  z-index: 0;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  padding: 0.75rem 0.75rem;
}

.google{
  background: rgb(255, 255, 255);
}

.facebook{
  background: rgb(67, 107, 191);
  color: white;
}

.container::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 100px;
  opacity: 0;
  transition: all 0.3s ease-out 0s;
  z-index: -1;
}

.container:hover::after {
  opacity: 1;
}

.text {
  padding-left: 12px;
  font-size: 16px;
}
