.organizer-container{
  flex-basis: 100%;
  margin-bottom: 28px;
}

.organizer-row {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #151519;
}
.organizer-img-container {
  height: 45px;
  width: 45px;
  position: relative;
}

.organizer-clickable {
  display: flex;
  align-items: center;
}

.logged-in .organizer-name {
  cursor: pointer;
}
.logged-in .organizer-name:hover{
  text-decoration: underline;
}

.organizer-img {
  position: relative;
  z-index: 2;
  border-radius: 50%;
}

.organizer-name, .num-users-joined{
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}
.num-users-joined{

}

.circle-divider{
  top: -3px;
  color: white;
  font-size: 22px;
  position: relative;
  margin: 0 5px;
}


@media (min-width: 768px){
  .organizer-container{
    flex-basis: auto;
    margin-bottom: 0;
  }
}
