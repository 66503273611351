

.user-image,
.user-image-loader {
  position: relative;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  display: inline-block;
  border: 10px solid white;
}
.user-image-loader {
  background-color: white;
}
.new-photo-clickable {
  z-index: 2;
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: linear-gradient(135deg, #58a3e7, #25ba6c);
  padding: 3px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid white;
}
.new-photo-clickable:hover {
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  /* border: 1px solid white; */
}
.pencil {
  width: 20px;
  height: 20px;
}

.hidden-upload {
  display: none;
}

@media (min-width: 992px) {
  .user-image-loader,
  .user-image {
    height: 145px;
    width: 145px;
  }

}
