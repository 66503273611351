/* New items */
.outside {
  background-color: rgba(242, 242, 242, 255);
  min-height: 90vh;
  padding-bottom: 32px;
}

@media (min-width: 992px) {
  .outside {
    padding-bottom: 64px;
  }
}

/*********************** CREATOR ************************************************/

.input-container {
  /* min-height: 200px; */
  margin-bottom: 30px;
  max-width: 900px;
  margin: 16px auto;
}

.input-container label {
  font-size: 16px !important;
}

.textarea {
  min-height: 75px !important;
}

.creator-post-survey-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.btn-container {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 32px;
}

.earn-credit-container {
  margin-top: 16px;
}

.btn-container {
  margin-top: -10px;
}
.submit-survey {
  padding: 6px 12px;
}

.hostless-session label {
  font-size: 20px !important;
  margin-bottom: 10px;
  text-align: left;
  color: #2b7bb9;
  font-weight: 600;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .hostless-session label {
    font-size: 24px !important;
  }
}
