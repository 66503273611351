.container {
  display: none;
  position: relative;
  background-color: #687ceb;
  max-width: 23.5625rem;
  width: 400px;
  min-height: 100vh;
  flex-shrink: 0;
  overflow-y: clip;
  z-index:1;
}

.logo {
  position: absolute;
  bottom: -55px;
  right: 0%;
  width: 20rem;
  transform: translateX(50%);
}

@media (min-width: 1200px) {
  .container {
    display: block;
  }
}

@media (min-width: 1444px) {
  .logo {
    width: 23rem;
  }
}
