.joined {
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 10px;
  font-size: 13px;
}

/*Joined Status*/
.joined-status-red {
  color: #d55750;
}

.joined-status-green {
  color: #50d56d;
}
.joined-status-green-background {
  background-color: #50d56d;
  color: white;
}
.joined-status-orange {
  color: #ffb82f;
}
.joined-status-orange-background {
  background-color: #ffb82f;
}
.joined-status-blue {
  color: #70b5f4;
}
.joined-status-blue-background {
  background-color: #70b5f4;
}
.joined-status-purple {
  color: #d92fff;
}
.joined-status-purple-background {
  background-color: #d92fff;
}

.joined-status-red-background {
  background-color: var(--color-error);
}

.joined-status-grey-background {
  background-color: #e8e4e4;
}

.joined-status-pink {
  color: rgba(202, 0, 122, 255);
}

.joined-status-pink-background {
  background-color: rgba(202, 0, 122, 255);
}

.is-small {
  position: absolute;
  right: -21px;
  top: 5px;
  width: 100px;
  height: 0;
}

.new-banner-container {
  position: absolute;
  right: -2px;
  top: -2px;
  width: 100px;
  height: 0;
}
