.well-modal {
  background: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  left: 50%;
  top: 48%;
  max-width: 700px;
  position: fixed;
  transform: translate(-50%, -50%);
  text-align: left;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.05s ease-in;
  pointer-events: none;
  width: 0;

  color: black;
  display: block;
  opacity: 1;
  pointer-events: auto;
  width: 95%;
  background-color: #ffffff;
  overflow: auto;
}

.well-modal-container {
  position: relative;
  padding: 0;
}

.modal-close-btn {
  color: black;
  font-size: 1.75rem;
  position: absolute;
  right: 5px;
  top: -7px;
  cursor: pointer;
  pointer-events: all;
  z-index: 2;
}

.modal-title {
  padding: 20px;
  pointer-events: all;
  position: relative;
  width: 100%;
  text-align: center;

  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 0;
  word-break: break-word;
  color: #151519;

  text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-stroke: 0.025em rgba(51, 51, 51, 0.5);
}

.modal-content {
  padding: 20px;
  pointer-events: all;
  overflow: auto;
  width: 100%;
  position: relative;
  overflow: visible;
}

.modal-body-container {
  color: rgba(131, 131, 131, 255);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.no-visibility {
  visibility: hidden;
}

.modal-btns-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
  width: 100%;
  pointer-events: all;
}

.no-btn,
.yes-btn {
  min-width: 120px;
}

.modal-input textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  background: #efefef;
  resize: none;
  height: 135px;
}

.modal-input textarea:focus {
  border: 1px solid transparent;
  outline: none;
  background: #efefef;
}

.after-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  text-align: center;
}

@media (min-width: 768px) {
  .modal-title {
    font-size: 22px;
  }
}
/* ------------------------------ Sign up modal ------------------------------ */

.register-image {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.congrats {
  padding-top: 0;
  color: #fc9e43;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 0;
  position: relative;
}
.congrats::after {
  content: 'CONGRATULATIONS';
  font-size: 30px;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  color: #fc9e43;
  font-weight: 900;
  letter-spacing: 0;
  text-align: center;
  width: 100%;
}
.confetti-modal {
  overflow: hidden;
}
.confetti-modal .modal-body-container {
  text-align: center;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
}

.go-to-sessions,
.go-to-chat {
  margin-top: 1rem;
  height: 40px;
  width: 160px;
}

.how-it-works-link {
  font-weight: bold;
}

@media (min-width: 768px) {
  .congrats {
    font-size: 32px;
  }
  .congrats::after {
    font-size: 50px;
  }
}

/*EXTERNAL SESSION NOTICE*/
.external-session-link {
  color: #3eb4e3;
  text-decoration: underline;
}

/*COUNTDOWN*/
.modal-countdown-title {
  padding-bottom: 0;
  width: 100%;
}
.modal-countdown-content {
  text-align: center;
}

@media (min-width: 576px) {
  .well-modal {
    width: 90%;
  }

  .no-btn,
  .yes-btn {
    min-width: 120px;
  }
}

/*VIDEO*/
.video-modal {
  width: 100%;
  height: 315px;
  border-radius: 10px;
}

.video-modal iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

@media (min-width: 576px) {
  .video-modal {
    width: 560px;
  }
}

/*GROUP ENTER*/
.modal-title-group {
  position: relative;
  width: 100%;
  text-align: center;

  font-size: 16px;
  font-weight: 400;
  word-break: break-word;
  color: #151519;
  padding: 15px 28px 10px;
}
.code-of-conduct-link {
  cursor: pointer;
}

.modal-content-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.session-join-group-info {
  word-break: break-word;
  color: #151519;
  padding: 0 25px 25px;
  text-align: center;
}
.session-join-group-info h5 {
  color: var(--color-primary);
}
.session-join-group-info p {
  margin-bottom: 0;
}

.checkbox-item-container {
  flex-basis: 100%;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 10px 0;
}

.checkbox-item-container input[type='checkbox'] {
  flex-basis: 100%;
  height: 15px;
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.checkbox-item-container label {
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 0;
}

.btn-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px 0 20px;
  width: 100%;
}
.group-join-btn {
  margin-top: 1rem;
  height: 40px;
  width: 160px;
}

.group-join-btn.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.group-ending-text {
  padding: 0 20px 20px;
  text-align: center;
}
.email-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  color: white;
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  height: 40px;
  width: 125px;
}
.email-link:hover {
  color: white;
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
}

@media (min-width: 576px) {
  .checkbox-item-container {
    margin: 0 auto;
  }

  .checkbox-item-container input[type='checkbox'] {
    flex-basis: auto;
  }
}

@media (min-width: 992px) {
  .modal-title-group {
    font-size: 18px;
  }

  .checkbox-item-container {
    flex-wrap: nowrap;
  }
}

/* WELCOME */
.welcome-image-container {
  background-color: rgba(252, 239, 191, 255);
  width: 100%;
  text-align: center;
}
.welcome-image {
  width: 100%;
  max-width: 500px;
}

.welcome-text.no-margin {
  margin-bottom: 0 !important;
}

.host-cta-image-container {
  background-color: rgba(112, 181, 244, 0.6);
  width: 100%;
  text-align: center;
}

.host-cta-image {
  width: 100%;
  max-width: 375px;
}

@media (min-width: 769px) {
  .welcome-text {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

/* VIDEO */
.iframe {
  display: block;
  width: 100%;
  margin: 0 auto;
  min-height: 250px;
}
.iframe-session-congrats {
  display: block;
  width: 100%;
  margin: 0 auto;
  min-height: 373px;
}
.download-link {
  margin-top: 16px;
  margin-bottom: 0;
}
