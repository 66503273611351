.container {
  position: relative;
  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));
}

.bottom-content {
  position: relative;
  padding-bottom: 20px;
}

.sessions-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sessions-row > div {
  margin: 10px;
}

.loader-container {
  padding: 20px 0;
  position: relative;
}
