.article-image-container {
  border-radius: 10px;
}

.article-image {
  border-radius: 10px;
}

.article-title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
}

.article-category {
  color: #58a3e7;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
}
.article-title {
  color: #151519;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 49px;
}
.article-subtitle {
  color: #151519;
  font-size: 24px;
  font-weight: 400;
}

.author-row {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -2px;
  flex-wrap: wrap;
}
.author-img-container {
  position: relative;
  height: 65px;
  width: 65px;
  margin-right: 5px;
  border-radius: 50%;
  z-index: 1;
}
.logged-in .author-img-container {
  cursor: pointer;
}

.author-name-row {
  display: flex;
  flex-wrap: wrap;
  padding-left: 3px;
  text-align: left;
  flex-basis: 100%;
  text-align: center;
}
.author-name {
  flex-basis: 100%;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 0;
}
.author-title {
  flex-basis: 100%;
  opacity: 0.5;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}

.author-img {
  position: relative;
  border-radius: 50%;
  z-index: 2;
}

.call-to-action-link {
  color: var(--color-primary-dark);
  cursor: pointer;
}

.call-to-action-link:hover {
  color: var(--color-primary);
  cursor: pointer;
}

@media (min-width: 420px) {
  .article-image {
    height: 350px;
    width: 420px;
  }
}

@media (min-width: 992px) {
  .article-title-container {
    display: block;
    padding-left: 20px;
    margin-bottom: 0;
    height: 350px;
  }

  .article-title {
    padding-top: 2rem;
  }

  .author-row {
    justify-content: flex-start;
    left: 19px;
    position: absolute;
    bottom: 3px;
    cursor: pointer;
  }

  .author-name-row {
    flex-basis: auto;
    text-align: left;
  }
}
