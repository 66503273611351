.group-item {
  position: relative;
  cursor: pointer;
  box-shadow: 0.0425rem 0.0425rem 0.425rem hsl(0deg 0% 45% / 40%);
  width: 333px;
  height: 300px;
  margin: 0 5px 35px;
  border-radius: 10px;
  color: #151519;
  background-color: white;
}

.group-item:hover {
  text-decoration: none;
  -webkit-box-shadow: 0.1475rem 0.1475rem 0.525rem #8c8c8c;
  box-shadow: 0.1475rem 0.1475rem 0.525rem #8c8c8c;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: #151519;
}

.image-container {
  position: relative;
  width: 100%;
  height: 200px;
  margin: 0 auto 30px;
}

.group-image,
.no-image {
  width: 100%;
  height: 100%;
}

.no-image {
  background: linear-gradient(90deg, #58a3e7 0, #25ba6c);
}

.wave-svg {
  fill: white;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 30px;
  width: 100%;
  pointer-events: none;
  z-index: 2;
}

.inside {
  position: relative;
  z-index: 1;
  overflow-wrap: anywhere;
  word-break: break-word;
}

.badge-container {
  position: absolute;
  top: -7px;
  right: 22px;
}

.group-content {
  text-align: center;
  padding: 0 10px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  color: #424242;
  margin-bottom: 0;
}

.highlight-item {
  background-color: rgba(112, 181, 244, 1);
  color: white;
  border-radius: 3px;
}

.new-badge {
  position: absolute;
  top: -2px;
  right: -3px;
  z-index: 2;
  width: 125px;
}

@media (min-width: 992px) {
  .group-item {
    margin: 0 60px 65px 0;
  }
}

@media (min-width: 1188px) {
  .group-item {
    margin: 0px 39px 65px 0;
  }
}

@media (min-width: 1570px) {
  .group-item {
    margin: 0 25px 65px 0;
  }
}
