
.card-info-container{
  margin-top: 1rem;
}

.container {
  border-radius: 8px;
}

.error-container {
  font-size: 14px;
  color: var(--color-error);
}
.input-item {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  gap: 0.75rem;
}

.input-item :global(.StripeElement),
.loading {
  width: 100%;
  height: 2.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  border: 1px solid #f5f5f7;
  background: #fff;
  width: 100%;
}

.input-item-inside {
  width: 100%;
}

.input-label {
  color: #292b2e;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.button-container {
  margin-top: 8px;
}

.submit-button {
  min-height: 55px;
  margin-top: 16px;
  width: 100%;
}

.new-card {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px 0;
  border: 1px solid #fbd8b4;
}
.new-card:hover {
  background-color: #fcf5ee;
}

.new-card-check {
  margin-right: 8px;
}

.card-input-container {
  position: relative;
}

.use-prev-card {
  position: absolute;
  right: 0;
  bottom: -20px;
  text-align: right;
  font-size: 14px;
  cursor: pointer;
  display: block;
  padding-right: 5px;
}

.use-prev-card:hover {
  text-decoration: underline;
  color: #565959;
}

.buttons-container {
  margin-top: 16px;
  text-align: center;
  position: relative;
}

.skip span {
  color: #565959;
  font-size: 16px;
  margin-top: 10px;
  display: block;
  cursor: pointer;
}

.skip:hover {
  text-decoration: underline;
}

.zip-code {
  width: 100%;
  padding: 12px;
  background-color: #fafafb;
  border-radius: 10px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  margin-right: 5px;
  font-size: 17px;
  font-weight: 500;
  color: #495057;
  outline: none;
}
