.container {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1 0 0;
  border-radius: 1.125rem;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(134, 132, 152, 0.09),
    0px 0px 11px 0px rgba(205, 205, 205, 0.18);
}

.title {
  color: black;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.575rem */
  margin-bottom: 0;
}

.text {
  color: #4a4a52;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.05rem */
}

.person-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image {
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 0.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.1875rem;
}

.loading{
  background: #ddd;
}

.name {
  color: #4a4a52;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 114.286% */
  letter-spacing: -0.0175rem;
  margin-bottom: 0;
}

