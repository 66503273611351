.well-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  text-decoration: none;
  font-weight: 600;
  padding: 6px 10px;
}

.well-btn:hover {
  text-decoration: none;
}

.well-btn-primary {
  color: white;
  background: linear-gradient(to bottom, #87bdee 0%, #3e94e3 100%);
  box-shadow: 0 0px 6px rgb(0 0 0 / 20%);
}

.well-btn-primary:hover {
  background: #70b5f4;
  color: white;
}

.well-btn-secondary {
  color: #3e94e3;
  border: 1px solid #d4d4d3;
  background: #ffffff;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  padding: 0.75rem 2.25rem;
  min-width: 160px;
  min-height: 40px;
}

.well-btn-secondary:hover {
  border: 1px solid rgba(112, 181, 244, 0.4);
  color: rgba(112, 181, 244, 1);
}

.well-btn-less {
  color: white;
  border: 1px solid white;
  background: transparent;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  padding: 0.75rem 1.25rem;
  min-width: 125px;
  min-height: 40px;
}

.well-btn-less:hover {
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.well-btn-enter {
  color: white;
  padding: 0.75rem 1.25rem;
  min-width: 163px;
  min-height: 45px;
  border: 1px solid #eb1383;
  background: linear-gradient(to right, #f470b5 0%, #f0419c 100%);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
}

.well-btn-enter:hover {
  background: #f0419c;
  color: white;
}

/*NEW BUTTONS*/
.well-btn-new-primary {
  color: white;
  background: linear-gradient(to bottom, #87bdee 0%, #3e94e3 100%);
  box-shadow: 0 0px 6px rgb(0 0 0 / 20%);
}

.well-btn-new-primary:hover {
  background: #70b5f4;
  color: white;
}

.well-btn-orange {
  color: white;
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
}

.well-btn-orange:hover {
  color: white;
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
  /*background: linear-gradient(to right, #F07592 0%, #D94970 100%);*/
}

.well-btn-new-secondary {
  color: #7a7676;
  background: #ffffff;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  border: 1px solid #7a7676;
}

.well-btn-new-secondary:hover {
  color: white;
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
}

.well-btn-red {
  background: var(--color-error);
  color: white;
  box-shadow: 0 0px 6px rgb(0 0 0 / 20%);
}

.btn-end {
  background: linear-gradient(to bottom, #50d56d 0%, #14b06d 100%);
  opacity: 0.7;
  cursor: default;
}

.btn-end:hover {
  background: linear-gradient(to bottom, #50d56d 0%, #14b06d 100%);
}

.inner-btn {
  display: flex;
  justify-content: space-around;
}

.hidden {
  visibility: hidden;
}

.icon {
  margin-right: 5px;
  width: 20px;
  position: relative;
  top: 0;
  display: none;
}

.disabled {
  opacity: 0.65;
  pointer-events: none;
}

.well-btn-purchase {
  -webkit-box-align: center;
  align-items: center;
  background: linear-gradient(to bottom, #87bdee 0%, #3e94e3 100%);
  border-radius: 100px;
  border: none;
  box-shadow: none;
  color: rgb(255, 255, 255);
  display: inline-flex;
  font-size: 1rem;
  font-weight: 600;
  line-height: 28px;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  outline: none;
  min-height: 48px;
  min-width: 48px;
  padding: 0.75rem 1.11111rem;
  margin-top: 16px;
  width: 100%;
}

.well-btn-purchase:hover {
  background: #70b5f4;
}
@media (min-width: 578px) {
  .icon {
    display: block;
  }
}
