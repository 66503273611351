.outside-container {
  position: relative;
  margin-bottom: 30px;
  display: block;
  position: relative;
}

.message-row {
  display: flex;
  flex-wrap: wrap;
}

.my-message .message-row {
  flex-direction: row-reverse;
}

.message-content {
  position: relative;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: end;
}

.my-message .message-content {
  flex-direction: row-reverse;
}

.message-content img {
  border-radius: 100%;
}

.message {
  position: relative;
  display: block;
  min-height: 32px;
  color: #000;
  border-radius: 16px 16px 16px 0;
  background: rgb(239, 243, 244);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-left: 0;
  max-width: 230px;
  padding: 7px 14px 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.my-message .message {
  background: rgb(29, 155, 240);
  border-radius: 16px 16px 0 16px;
  color: white;
  max-width: 260px;
  padding: 7px 14px 7px;
}

.deleted-message {
  background: #ecebeb !important;
}

.message-username {
  color: black;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
}

.message-username:hover {
  color: black;
}

.message-text-outer {
  width: 100%;
  display: inline-block;
  overflow-wrap: break-word;
}

.deleted-outer {
  padding: 5px 7px;
  border: none;
}

.message-text-inner p,
.message-text-inner ol li,
.message-text-inner ul li {
  font-size: var(--message-font-size);
  line-height: var(--message-line-height);
  font-weight: var(--message-font-weight);
  -webkit-font-smoothing: antialiased;
  overflow-wrap: break-word;
  white-space: initial;
  overflow: hidden;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.message-text-inner p :global(.str-chat__message-mention) {
  cursor: pointer;
}

.message-text-inner ol,
.message-text-inner ul {
  margin-left: 0;
  padding-left: 0;
  list-style-position: inside;
}

.message-text-inner ol li {
  list-style-type: decimal !important;
}
.message-text-inner ul li {
  list-style-type: disc !important;
}

.my-message .message-text-inner p {
  font-weight: 600;
}

.my-message .message-text-inner p a,
.my-message .message-text-inner p a:hover {
  color: white;
  text-decoration: underline;
  white-space: initial;
  overflow-wrap: break-word;
}

.deleted-inner {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 300;
}

.message-text-inner p:last-child {
  margin-bottom: 0;
}

.bottom-items {
  position: relative;
  left: 45px;
}

.my-message .bottom-items {
  left: 0;
}

.message-timestamp {
  float: left;
  font-size: 13px;
  color: rgb(83, 100, 133);
}

.my-message .message-timestamp {
  float: right;
}

/* ********************************************* */

.message-options-container {
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  display: flex;
  width: 40px;
  justify-content: space-between;
}

.my-message .message-options-container {
  margin-right: 10px;
}

/* ********************************************* */

/* ATTACHMENTS */

.attachment-container {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  text-align: left;
}
.my-message .attachment-container {
  text-align: right;
}

.attachment-container > div {
  flex-basis: 100%;
}

.attachment-container img {
  max-width: 550px !important;
}

.my-message .attachment-container img {
  margin-left: auto;
}

/* ********************************************* */

/* REACTIONS */

.open-reaction-btn {
  position: relative;
}

.submitted-reactions {
  z-index: 5;
  display: flex;
  justify-content: flex-start;
}

.submitted-reactions > div {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2px;
}

.submitted-reactions button {
  border: none;
  background: none;
  cursor: default;
}

.my-message .submitted-reactions {
  justify-content: flex-end;
}

.submitted-reactions ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitted-reactions ul li {
  display: inline;
  text-align: center;
  height: 16px;
  width: 16px;
}

.submitted-reactions ul li:last-child {
  color: rgb(101, 103, 107);
  font-size: 14px;
}

.reaction-container {
  top: -56px;
  left: -158px;
  position: absolute;
}

.short-text .reaction-container {
  left: -103px;
}

.my-message .reaction-container {
  left: -32px;
}

.my-message.short-text .reaction-container {
  left: -113px;
}
