.session-item-list {
  position: relative;
  display: flex;
  align-items: center;
  color: black;
  flex-basis: 100%;
}

.right{
  max-width: 200px;
  overflow: hidden;  
  text-overflow: ellipsis;
}

.title {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  word-break: break-word;
}

.date {
  font-size: 14px;
  flex-basis: 100%;
  font-weight: 400;
  margin-bottom: 0;
  color: rgb(104, 118, 141);
}

.image {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  margin-right: 5px;
}

.image-small{
  height: 22px;
  width: 22px;
  margin-right: 12px;
  position: relative;
  left: 4px;
}