.why-peer-support-one {
  background-color: rgba(248, 247, 243, 255);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section-title {
  font-size: var(--section-title);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin-bottom: 20px;
  color: #58a3e7;
  widows: 2;
}

.section-subtitle {
  font-size: var(--section-text);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: 0.5rem;
  text-align: center;
}

.section-text {
  font-size: var(--section-text);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin-bottom: 0;
}

/* Buttons */
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.start-now {
  font-size: 18px;
  height: 40px;
  width: 150px;
}

/* tri-graphics */
.tri-graphics {
  margin-top: 32px;
}

.graphic-item {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 10px 20px;
}

.tri-graphic-image {
  max-width: 200px;
  margin: 0 auto;
}

.tri-graphic-text {
  font-size: 20px;
  margin-bottom: 0;
  color: #7d7a7a;
}

@media (min-width: 768px) {
  .tri-graphics {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .why-peer-support-one {
    padding-top: 64px;
    padding-bottom: 32px;
  }
}
