.main {
  padding-bottom: 32px;
}

.wave-image-container {
  position: absolute;
  top: 0;
  width: 100vw;
  /* max-height: 200px; */
  height: 155px;
  z-index: 0;
  background: linear-gradient(to right, #0DA6B4 0%, #088DCE 100%);
}

.wave-svg {
  fill: rgba(248, 247, 243, 255);
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.container {
  text-align: center;
  padding: 16px 10px 0;
  margin: 0 auto;
  z-index: 1;
}

.welcome-image {
  max-width: 1200px;
  margin: 16px auto 32px;
  position: relative;
  max-width: 820px;
}

.middle-text {
  z-index: 1;
  position: relative;
}

.middle-text h1 {
  font-weight: bold;
  font-size: 22px;
}

.middle-text p {
  font-weight: 400;
  font-size: 18px;
  max-width: 900px;
  margin: 0 auto;
}


.loader-container{
  position: relative;
  min-height: 125px;
}

.sessions-row {
  margin: 32px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.sessions-row > div {
  margin: 16px 5px;
}

.stat {
  font-size: 18px;
}

.blue-stat {
  color: var(--text-color-blue);
  font-weight: bold;
  font-style: italic;
}

.bottom {
  margin: 32px;
}

@media (min-width: 576px) {
  .wave-image-container {
    height: 200px;
  }

}


@media (min-width: 768px) {
  .wave-image-container {
    height: 240px;
  }
}

@media (min-width: 768px) {
  .sessions-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media (min-width: 992px) {
  .wave-image-container {
    height: 260px;
  }

  .middle-text h1 {
    font-size: 26px;
  }
  .middle-text p {
    font-size: 20px;
  }
  .stat {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .main {
    padding-bottom: 64px;
  }

  .container {
    max-width: 1297px;
  }
}
