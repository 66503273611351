/*FAQ*/
.faq{
  background-color: rgba(242,242,242,255);
  padding-bottom: 4rem;
}




.dropdown-container{
  align-items: center;
  padding: 24px 20px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.05);  
  margin-bottom: 20px;
  cursor: pointer;
}


.faq-item{
  padding-top: 1rem;
}
.faq-item h4{
  font-size: 20px;
}

.dropdown-title{
  display: inline-block;
  margin-bottom: 0;
  font-size: 26px;
}
.dropdown-container:hover .dropdown-title{
  color: var(--color-primary);
}

.dropdown{
  border-radius: 10px;
  transform: scaleY(0);    
  transform-origin: top;
  transition: transform 0.26s ease;
  max-height: 0;
}
.dropdown.open{
  transform: scaleY(1);
  max-height: 50000px;
  padding:  10px;
  padding-left: 0;
}
.link{
  color: var(--color-primary);
}
.link:hover{
  text-decoration: underline;
  cursor: pointer;
}