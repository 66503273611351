.header {
  --header-title: 24px;
  --header-subtitle: 18px;
}

@media (min-width: 768px) {
  .header {
    --header-title: 32px;
    --header-subtitle: 20px;
  }
}

/*header*/
.header {
  position: relative;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  color: white;
  min-height: 200px;
  width: 100% !important;
  position: relative;
  text-align: center;
  padding: 2rem 0 4rem;
}

.header-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  max-width: 1015px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.title {
  font-size: var(--header-title);
  width: 100%;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

.center-title {
  padding-top: 50px !important;
}

.subtitle {
  font-size: var(--header-subtitle);
  width: 100%;
  max-width: 800px;
  font-weight: 400;
}

.wave-svg-desktop {
  display: inline-block;
  position: absolute;
  bottom: -1px;
  left: 0;
  stroke: none;
  fill: rgba(242, 242, 242, 255);
  height: 35px;
}

@media (min-width: 768px) {
  .title {
    line-height: 38px;
  }

  .wave-svg-desktop {
    height: 100px;
  }
}

@media (min-width: 992px) {
  .header {
    text-align: left;
    min-height: 265px;
  }

  .title {
    padding-top: 13px;
  }
}

@media (min-width: 1200px) {
  .header-container {
    max-width: 1350px;
  }
}
