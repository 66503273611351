.outside {
  background-color: rgba(242, 242, 242, 255);
  min-height: 90vh;
  padding-bottom: 32px;
}

.main-container {
  text-align: center;
  padding-bottom: 32px;
}

.container {
  padding: 0 10px;
  margin: 0 auto;
}

.header-class {
  min-height: 200px;
}

.thumbs-container,
.open-question-container,
.buttons-container {
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.thumbs-container {
  flex-wrap: wrap;
}

.open-question-container {
  max-width: none;
  display: block;
}

.section-title {
  flex-basis: 100%;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.thumbs-up,
.thumbs-down {
  width: 75px;
  transition: 0.2s;
  cursor: pointer;
  padding: 10px;
  border-radius: 10%;
}

.thumbs-up:hover,
.thumbs-down:hover {
  transform: scale(1.25);
}

.thumbs-up.active,
.thumbs-down.active {
  background-color: var(--color-primary);
}

.off {
  pointer-events: none;
  opacity: 0.5;
  display: none;
}

.open-question-container {
  margin: 20px 0;
  text-align: left;
}

.textarea-title {
  font-size: 22px;
}

.buttons-container {
  margin-left: 0;
  margin-top: 20px;
}

.make-review {
  font-size: 22px;
  width: 186px;
  margin-right: 10px;
  margin-bottom: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.input-textarea {
  min-height: 100px;
}

.ten-minimum {
  position: absolute;
  right: 0;
  bottom: -25px;
  font-size: 12px;
  font-weight: 400;
}

.post-survey-title {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 2rem;
}

.submit-container {
  text-align: center;
  padding-bottom: 4rem;
  display: none;
}

.survey-complete-button {
  margin-top: 32px;
}

.sessions-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.sessions-row > div {
  margin-bottom: 10px;
}

.buttons-row {
  text-align: center;
  margin-top: 16px;
}

.submit-btn {
  display: block;
  margin: 0 auto;
  max-width: 100px;
}

@media (min-width: 992px) {
  .outside {
    padding-bottom: 64px;
  }
  .sessions-row > div {
    margin: 10px 30px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1297px;
  }
}
