.group-list-header-parent {
  position: relative;
}
.group-list-header {
  position: relative;

  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  z-index: 1;

  color: white;
  width: 100% !important;
  text-align: center;
  padding: 3rem 0;
  height: 265px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin-bottom: 16px;
}
.subtitle {
  margin-bottom: 26px;
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
}

.wave-svg {
  display: inline-block;
  position: absolute;
  bottom: -1px;
  left: 0;
  stroke: none;
  fill: rgba(248, 247, 243, 255);
  height: 35px;
}

.group-search-container {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
}

.menu-items {
  background-color: rgba(248, 247, 243, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;
}

.create-group-btn {
  height: 38px;
  width: 160px;
  border-radius: 8px;
  margin-left: 10px;
}


.blog-link,
.blog-link:hover {
  color: white;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .group-list-header {
    height: 300px;
  }

  .title {
    font-size: 30px;
  }
  .subtitle {
    font-size: 18px;
  }
  .wave-svg {
    height: 60px;
  }
  .menu-items {
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .menu-items {
    padding-left: 15px;
  }
}

@media (min-width: 1188px) {
  .menu-items {
    margin-right: auto;
    margin-left: auto;
    max-width: 1188px;
  }
}

@media (min-width: 1570px) {
  .menu-items {
    max-width: 1570px;
  }
}
