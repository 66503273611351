.list-item {
  background: #fff;
  transition: background 0.1s ease-in-out;

  height: 62px;
  border-radius: 8px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 8px;
  width: 100%;
  border: none;
}

.list-item:hover {
  background: #f7f9f9;
}

.content-row {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}

.chat-image-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

.image-place-holder {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: linear-gradient(to right, #58A3E7 0%, #25BA6C 100%);
}

.chat-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.unread {
  position: relative;
  left: -20px;
  z-index: 2;
}

.text-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.title {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  margin: 0;
  /* max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  word-break: break-word;
  text-align: left;
  line-height: 17px;
}

.last-message {
  font-size: 13px;
  flex-basis: 100%;
  line-height: 16px;
  margin: 0;
  color: #858688;
  height: 20px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.last-message p {
  font-size: 13px;
}
