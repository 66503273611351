.payment {
  border-top: 1px solid rgb(198, 198, 198);
  color: rgb(28, 28, 28);
  padding-top: 16px;
  margin-top: 16px;
  text-align: left;
  padding-bottom: 32px;
}

.payment h3 {


  color: #292b2e;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 2.8rem */
}

.payment h4 {
  color: #292b2e;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.575rem */
}

.billing-information {
  margin-top: 32px;
}

.errors {
  color: var(--color-error);
  font-size: 16px;
  margin: 10px 0;
}
