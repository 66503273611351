.open-chat-btn {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(12px);
  height: 53px;
  box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px,
    rgb(101 119 134 / 15%) 0px 0px 3px 1px;
  width: 200px;
  z-index: 6;
}

.in-room {
  bottom: 75px;
  right: 0;
  left: auto;
  transform: none;
  width: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  display: none;
}

.messages-note {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

.in-room .messages-note {
  display: none;
}

.svg {
  width: 30px;
  height: 24px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.in-room .svg {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.unread-count {
  position: absolute;
  top: -9px;
  right: 17px;
}

.display-none {
  display: none;
}
/* .no-channels { */
/*   display: none; */
/* } */

@media (min-width: 768px) {
  .in-room {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 200px;
  }

  .in-room .messages-note {
    display: block;
  }

  .in-room .svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .open-chat-btn {
    left: auto;
    right: 100px;
    transform: none;
  }

  /* .no-channels:not(.display-none) { */
  /*   display: flex; */
  /* } */
}
