.container {
  width: 100%;
  height: 0.4375rem;
  flex-shrink: 0;
  border-radius: 3.75rem;
  position: relative;
  background: rgba(104, 124, 235, 0.2);
  max-width: 1200px;
  margin: 0 auto;
}

.filling {
  border-radius: 3.75rem;
  height: 100%;
  background: rgba(104, 124, 235, 0.8);
}
