/* Card item */
.card-item{
  background-color: #fcf5ee;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fbd8b4;
}

.card-item-inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.top{
  font-size: 14px;
  color: #565959;
}
.bottom{
  font-size: 16px;
  font-weight: 500;
}

.credit-card-image{
  width: 50px;
  height: 50px;
}
