/* START SESSION ITEM */

.session-item {
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  color: #392f2f;
  background: white;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  transition: box-shadow 0.1s ease-in, color 0.1s ease-in;

  width: 100%;
}

.session-item:hover {
  -webkit-box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: #392f2f;
}

.session-item a,
.session-item:hover {
  color: #392f2f !important;
  text-decoration: none;
}

.image-container {
  width: 100%;
  position: relative;
  height: 150px;
}
.image {
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
}

.image-placeholder {
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
}

.image-container::before {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.02;
  background-color: black;
  position: absolute;
  border-radius: 10px;
  z-index: 0;
}

.bottom {
  position: relative;
  height: 115px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.session-time-container {
  font-size: 15px;
  font-weight: 500;
  position: relative;
  color: #6e7680;
  text-align: center;
  width: 100%;  
}

.in-session {
  color: var(--color-error) !important;
}

.time-divider {
  /* font-size: 12px; */
  padding: 0 6px;
}

.session-item-title {
  font-size: 18px;
  font-weight: bold;

  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-word;
  position: relative;
  z-index: 2;
  margin-bottom: 3px;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  width: 100%;
}

.status-container {
  position: absolute;
  left: 8px;
  top: -15px;
}

.host {
  position: absolute;
  right: 8px;
  top: -35px;
  display: none;
}

.host-image-container {
  width: 65px;
  height: 65px;
}

.host-image,
.host-image-placeholder {
  border: 3px solid rgba(112, 181, 244, 0.7);
  height: 100%;
  border-radius: 100%;
}

.host-image-placeholder {
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  width: 100%;
}

.expert-check {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 2px;
  bottom: -5px;
}
.price-session-notice {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
  text-align: right;

  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 6px;

  box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
  color: #222230;
  font-size: 12px;
  padding: 4px 8px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: -3px;
}

.expert-banner {
  position: absolute;
  right: -26px;
  top: 5px;
  z-index: 1;
  width: 120px;
}

.description {
  display: none;
}

.register-button {
  position: absolute;
  z-index: 2;
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  cursor: pointer;
  transition-duration: 600ms;
  transition: all 0.2s ease-in-out;
  left: 10px;
  top: 10px;
  border-radius: 10px;
  font-weight: 600;
  padding: 5px 8px;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  color: white;
  min-width: 95px;
  min-height: 34px;
}

.register-button.is-small{
  font-size: 13px;
  min-width: 80px;
  min-height: 30px;
}

.register-button:hover {
  transform: translate(1px, -1px);
}


.bonus-badge{
  position: absolute;
  right: -2px;
  top: -2px;
  z-index: 2;
  width: 128px;
}

@media (min-width: 324px) {
  .session-item-title,
  .session-time-container {
    width: auto;
    text-align: left;
  }

}

@media (min-width: 370px) {
  .session-item {
    width: 365px;
  }
  .bottom {
    padding-right: 60px;
  }

  .square.session-item {
    width: auto;
  }
  .host {
    display: block;
  }
}

@media (min-width: 425px) {
  .square.session-item {
    width: 365px;
  }
}

@media (min-width: 576px) {
  .bottom {
    height: 100px;
  }
}

@media (min-width: 768px) {
  .joined-status-container {
    padding: 3px 10px;
  }
}

@media (min-width: 992px) {
  .square {
    height: 350px;
    width: 350px;
  }

  .square .bottom {
    padding-right: 10px;
    width: 100%;
    height: 200px;
  }

  .square .session-item-title {
    padding-right: 60px;
    margin-bottom: 5px;
  }

  .description {
    color: #333333;
    display: -webkit-box;
    width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 85px;
    overflow: hidden;
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.4;

    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-word;
    word-break: break-word;
  }
}

/* EMPTY ITEM */
.session-item-empty {
  background-color: #e5e8ee;
  background: none;
  color: #4d5461;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 250px;
  font-weight: 500;
}

.session-item-empty:hover {
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  color: white;
  font-size: 18px;
  transform: scale(1.001);
  -webkit-transform: scale(1.001);
}

.plus-sign {
  font-size: 32px;
}

.session-item-empty:hover .plus-sign {
  font-size: 36px;
}
