.well-timer-container{
  font-weight: 600;
  text-align: center;
  min-height: 100px;
  display: flex;
  align-items: center;
}

.well-timer-container > span{
  width: 100%;
}

.countdown-row{
	display: flex;
	width: 100%;
	height: 100px;
  position: relative;
  top: 6px;
}
.time-item{
	display: flex;
	justify-content: center;
	position: relative;
	flex-basis: 33.3333%;

  font-size: 38px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 64px;
}
.time-name{
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
.time-name{
  color: #7A7676;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.divider{
  font-size: 26px;
  font-weight: bold;
  align-items: center;
  display: flex;
  position: relative;
  top: -23px;
}

.days-left{
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 64px;	
  text-align: center;
  width: 100%;
  display: block;
  position: relative;
  /*margin-bottom: 38px;*/
  /*top: 14px;*/
}



.blink {
	animation: blink-animation 1.75s steps(5, start) infinite;
	-webkit-animation: blink-animation 1.75s steps(5, start) infinite;
  display: flex;
  justify-content: center;
  position: relative;
  flex-basis: 33.3333%;

  font-size: 26px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 64px;
  color: var(--color-error) !important;
  font-weight: 600;
  margin-bottom: 37px;
  position: relative;
  top: 17px; 
}
@keyframes blink-animation {
	to {
	  visibility: hidden;
	}
}
@-webkit-keyframes blink-animation {
	to {
	  visibility: hidden;
	}
}