/*ABOUT*/
.about {
  padding: 16px 15px;
  position: relative;
  overflow: visible;
  width: 100%;
}

.about-row {
  position: relative;
}

.about-title {
  color: #575757;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  word-break: break-word;
}
.about-text {
  color: #151519;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  /*margin-bottom: 0;*/
  /*margin-bottom: 20px;*/
  word-break: break-word;
  margin-bottom: 0;
  /* text */
}

.has-none {
  text-align: center;
}

.input-textarea {
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 5px;
}

.input-textarea::placeholder {
  color: #90949c;
}
.small-textarea {
  height: 40px;
  min-height: 40px !important;
}

.options-container {
  position: absolute;
  top: -33px;
  right: 0;
}

.pencil-icon {
  right: 15px;
  width: 20px;
  cursor: pointer;
}

.cancel-icon,
.save-icon {
  right: 15px;
  width: 24px;
  cursor: pointer;
}

.no-save {
  opacity: 0.35;
  pointer-events: none;
}

.read-more {
  color: #58a3e7;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.error {
  position: absolute;
  bottom: -18px;
  font-size: 13px;
  color: var(--color-error);
}

@media (min-width: 992px) {
  .input-textarea {
    min-height: 150px;
  }
}
