.well-tool-tip {
	position: absolute;
	right: 0;
	top: 3px;
}

.well-tool-tip-icon {
	width: 25px;
	height: 25px;
}

.well-tool-tip-item {
	position: absolute;
	background-color: var(--color-primary);
	padding: 10px;

	z-index: 3;
	box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
	transition: box-shadow 0.1s ease-in, color 0.1s ease-in;
	top: -59px;
	right: 0;
	width: 250px;
	border-radius: 10px;
	color: white;

	display: none;
}

.well-tool-tip-item.open {
	display: block;
}

.well-tool-tip-text {
	text-align: center;
	font-size: 0.9rem;
	font-weight: 400;
}

.colored {
	width: 15px;
	height: 15px;
	position: relative;
	left: -3px;
}

@media (min-width: 567px) {
	.well-tool-tip-item {
		width: 392px;
	}
}



@media (min-width: 768px) {
	.well-tool-tip-item {
		right: -184px;
	}

}