.checkbox-container {
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
}

.checkbox {
  position: relative;
  min-height: 15px;
  min-width: 15px;
  cursor: pointer;
}

.checkbox:checked::after {
  content: '\2713';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 15px;
  width: 15px;
  background-color: #0088ce;
  color: white;
  border-radius: 4px;
  text-align: center;
  line-height: 1;
}

.checkbox-label {
  display: inline-block !important;
  margin-bottom: 0 !important;
  position: relative;
  top: -1.5px;
  left: 8px;
  font-size: 16px;
  cursor: pointer;
  color: #6e7680;
}

@media (min-width: 576px) {
  .checkbox-container {
    display: inline-flex;
    align-items: center;
  }
}
