
.select-container{
  display: none;
}
.select-container > div {
  width: 175px;
  z-index: 5;
}


@media (min-width: 768px) {
  .select-container{
    display: block;
  }
}

