.header {
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  color: white;
  min-height: 440px;
  width: 100% !important;
  position: relative;
  text-align: center;
  padding-top: 6rem;
}

.title {
  letter-spacing: 0;
  line-height: 1;
  font-weight: 600;
  font-size: var(--header-title);
  width: 100%;
  widows: 2;
}

.subtitle {
  margin-top: 20px;
  font-size: var(--header-subtitle);
  width: 100%;
}

.special-break {
  display: none;
}

.header-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.header-row {
  justify-content: center;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.right {
  position: relative;
  display: none;
}

.quote-container {
  background-color: white;
  border-radius: 60px;
  min-height: 300px;
  min-width: 200px;
  padding: 25px 27px 10px;
  padding: 50px 50px 30px;
  padding-left: 100px;
}
.quote {
  font-style: italic;
  color: #424242;
  text-align: right;
  max-width: 250px;
  margin-left: auto;
  margin-bottom: 5px;
}

.quote-author {
  font-style: italic;
  color: #424242;
  text-align: right;
  max-width: 250px;
  margin-left: auto;
}

.quote-image {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 220px;
  transform: translate(-35%, 30%);
}

.quote-brackets {
  position: absolute;
  top: 14px;
  max-width: 50px;
  transform: scaleX(-1);
  left: 39px;
}

.learn-more {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.8px;
  font-weight: 600;
}

.learn-more:hover {
  text-decoration: underline;
}

.desktop-video-preview {
  display: none;
}

.join-now-btn-container {
  flex-basis: 100%;
  width: 200px;
  padding-top: 2rem;
}

.join-now-btn {
  width: 170px;
  height: 45px;
  font-size: 18px;
}

.logged-in {
  width: 150px;
  height: 45px;
  font-size: 17px !important;
}

.wave-svg-desktop {
  display: inline-block;
  position: absolute;
  bottom: -1px;
  left: 0;
  stroke: none;
  fill: rgba(248, 247, 243, 255);
  height: 35px;
}

.arrows {
  width: 160px;
  height: 72px;
  position: absolute;
  left: 50%;
  bottom: 120px;
  display: none;
  transform: translateX(-50%);
}

.arrows path {
  stroke: white;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2 infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/*Safari and Chrome*/
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .wave-svg-desktop {
    height: 100px;
  }
}

@media (min-width: 992px) {
  .header {
    text-align: left;
    height: 90vh;
    max-height: 530px;
    padding-top: 2rem;
  }

  .subtitle {
    line-height: 1.5;
  }

  .header-row {
    flex-wrap: nowrap;
  }

  .right {
    display: block;
  }

  .desktop-video-preview {
    display: inline;
    width: 100%;
    position: relative;
  }
  .mobile-video-preview {
    display: none;
  }
  .header-graphic {
    border-radius: 10px;
    position: relative;
    top: 13px;
  }
  .header-graphic-image {
    max-height: 400px;
    display: block;
  }

  .line-break {
    display: none;
  }
  .join-now-btn {
    width: 191px;
    height: 49px;
    font-size: 22px;
  }

  .arrows {
    display: block;
    bottom: 20px;
  }

  .special-break {
    display: block;
  }
}

@media (min-width: 1110px) {
  .header-row {
    justify-content: space-around;
  }
}

@media (min-width: 1200px) {
  .header {
    max-height: 735px;
  }

  .header-container {
    max-width: 1350px;
  }
  .arrows {
    bottom: 30px;
  }

  .quote-container {
    padding: 50px 50px 30px;
    padding-left: 100px;
  }

  .quote-image {
    max-width: 280px;
  }

  .quote-brackets {
    top: 20px;
    max-width: 75px;
    left: 41px;
  }
}

@media (min-width: 1320px) {
  .header-graphic {
    min-width: 528px;
  }
}

@media (min-width: 1520px) {
  .header-graphic {
    min-width: 700px;
  }
}
