.item-container {
  min-height: 200px;
  position: relative;
}

.poll-question {
  flex-basis: 100%;
  /* border-bottom: 1px solid #efefef; */
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  white-space: pre-line;
  overflow-wrap: anywhere;
  word-break: break-word;
  /* color: #58A3E7; */
  line-height: 25px;
  font-weight: 500;
}

.header-container {
  display: flex;
}

.header-text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.user-link {
  display: flex;
  align-items: center;
}
.user-link:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.post-username {
  color: #151519;
  display: inline-block;
  margin-bottom: 0;
  font-weight: var(--header-font-weight);
  font-size: 18px;
}

.poll-answers-container {
  width: 100%;
}

.answered,
.poll-answer {
  position: relative;
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 2px;
  margin-left: 0;
  padding-left: 0;
  border: 1px solid rgba(117, 138, 174, 0.4);
}

/* Need to Answer */

.poll-answer {
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 10px;
}
.poll-answer:hover {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  background-color: var(--color-primary);
  color: white;
}

/* already answered */
.answer-text {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  z-index: 2;
  margin: 0 5px;
}

.percent {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  z-index: 2;
}

.bar {
  position: absolute;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
  background-color: var(--color-primary);
}

.check-container {
  position: relative;
  width: 15px;
  z-index: 4;
  display: inline-block;
  height: 0;
  top: -2px;
  right: -5px;
}

.num-responses {
  font-size: 14px;
  font-weight: 600;
  color: #9398a0;
}

@media (min-width: 576px) {
  .answer-text {
    font-size: 16px;
  }
  .percent {
    font-size: 15px;
  }
}
