.new-post {
  /* font-size: var(--header-font-size);
  font-weight: var(--header-font-weight);
  margin-bottom: 0; */
  font-size: 18px;
  color: #151519;
  font-weight: 500;
  margin-bottom: 15px;
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.is-loading {
  opacity: 0.7;
  pointer-events: none;
}

.row-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 100%;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-activity-btn {
  height: 30px;
  width: 80px;
}

.submit-activity-btn span {
  font-size: 13px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.deactivated {
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed;
}

.upload-item-container {
  margin-right: 5px;
  display: inline-block;
}

.upload-item-container label {
  margin-bottom: 0;
}

.disabled-image-input {
  opacity: 0.4;
  pointer-events: none;
}

/*Disable */
:global(.rfu-thumbnail-placeholder) {
  display: none;
  pointer-events: none;
}

:global(.rfu-icon-button) svg {
  z-index: 2 !important;
}
