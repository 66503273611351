/*EMAIL PAGES*/
.email-page {
  background-color: rgba(242, 242, 242, 255);
  padding-bottom: 4rem;

  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));
}

.email-container {
  position: absolute;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 10px;
  padding: 40px 20px;
  z-index: 2;
  background: white;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  width: 99%;
}

.email-logo {
  display: inline-block;
  width: 150px;
}

.email-text {
  text-align: center;
  font-size: 22px;
  padding-top: 2rem;
}

@media (min-width: 576px) {
  .email-container {
    min-width: 575px;
    width: auto;
    margin: 0;
  }

  .email-text {
    font-size: 28px;
  }
}