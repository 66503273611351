.activity-item {
  position: relative;
}

.unseen {
  box-shadow: rgb(3 102 214 / 30%) 0px 0px 2px 3px !important;
}

.header-container {
  display: flex;
}

.header-text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.user-link {
  display: flex;
  align-items: center;
}
.user-link:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.group-title {
  flex-basis: 100%;
  margin-bottom: 0;
  font-size: 14px;
  color: #58a3e7;
  font-weight: 500;
}

.post-username {
  color: #151519;
  display: inline-block;
  margin-bottom: 0;
  font-weight: var(--header-font-weight);
  font-size: var(--header-font-size);
}

.circle-divider {
  /* font-size: 28px; */
  margin: 0 3px;
  color: #9398a0;
}

.from-now {
  font-size: 12px;
  color: #9398a0;
  position: relative;
  bottom: -1px;
}
.is-edited {
  font-size: 14px;
  margin-left: 5px;
}

.top-content,
.bottom-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.top-content {
  margin-bottom: 5px;
  justify-content: space-between;
}

.top-content-second-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  width: 100%;
  border-bottom: 1px solid #efefef;
  margin-bottom: 5px;
}

.comment-button {
  flex-basis: 50%;
  width: 50%;
  padding: 7px 0;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.comment-button:hover {
  background-color: #f2f2f2;
}

.comment-button svg {
  width: 20px;
  margin-right: 5px;
}

.comment-text {
  font-size: 14px;
  font-weight: bold;
  color: rgb(28, 30, 33);
}

.reply-container > div:last-child {
  margin-bottom: 10px;
}

.post-content {
  flex-basis: 100%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 5px;
  font-size: 16px;
  white-space: pre-line;
  overflow-wrap: anywhere;
  word-break: break-word;
  /* color: #58A3E7; */
  line-height: 25px;
  font-weight: 500;
}

.post-content.showing-more {
  display: flex;
}

.image-container {
  margin: 20px 0;
}

.likes-and-comments {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number-comments {
  margin-bottom: 0;
  color: rgb(101, 103, 107);
  font-size: 14px;
  font-weight: 600;
}

.options-container {
  position: absolute;
  right: 0;
  top: 18px;
}

.save-btn,
.cancel-btn {
  margin: 15px 15px 15px 0;
  height: 30px;
  width: 80px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/*Replies*/
.submit-reply-container {
  flex-basis: 100%;
  margin: 20px 0 5px;
  position: relative;
}

.submit-btn-container {
  margin-top: 9px;
  display: flex;
  justify-content: flex-end;
}

.inline-btns-container {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-btns-container :global(.rfu-image-upload-button) svg {
  width: 17px !important;
  height: 17px !important;
  margin-right: 2px;
  position: relative;
  top: 1px;
}
.inline-btns-container label {
  margin-bottom: 0;
}

.submit-reply-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.submit-reply-btn {
  margin-top: 3px;
  height: 30px;
  width: 60px;
}

.load-more {
  font-size: 13px;
  color: var(--color-primary);
  font-weight: 600;
  cursor: pointer;
  flex-basis: 100%;
}
.load-more:hover {
  text-decoration: underline;
}

.post-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.copy-text {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.section-loading {
  opacity: 0.7;
  pointer-events: none;
}
