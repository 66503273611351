/* FORMS */
.well-form-group {
  text-align: left;
  margin: 0 auto 16px;
  position: relative;
}
.full-width {
  margin: 0 0 16px !important;
  max-width: none !important;
}

.well-form-group-no-max {
  max-width: none !important;
}

.well-form-label {
  font-weight: 400;
  margin: 0px;
  padding-left: 4px;
  padding-bottom: 4px;
  text-align: left;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgb(104, 118, 141);
}

.well-form-item-container {
  position: relative;
  flex: 0 0 auto;
  -webkit-box-pack: initial;
  justify-content: initial;
  align-self: initial;
  border-radius: 5px;
  border: 1px solid rgb(117, 138, 174);
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.well-form-item-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 5px;
  height: 36px;
  width: 100%;
  border: none;
}
.character-count {
  font-size: 13px;
  color: rgb(104, 118, 141);
  position: absolute;
  right: 0;
  top: -20px;
}

.maxed-reached {
  font-size: 13px;
  color: rgb(104, 118, 141);
  text-align: right;
  display: inline-block;
  position: absolute;
  bottom: -18px;
  right: 0;
}
.form-errors {
  color: #721c24;
}

.form-input-error {
  border: 2px solid var(--color-error);
}

.form-error-msg {
  color: var(--color-error);
  height: 0;
  float: left;
  font-size: 0.75rem;
  font-weight: 400;
  position: relative;
  left: 5px;
}

.form-initial-msg{
  color: var(--color-information);
  height: 0;
  float: left;
  font-size: 0.75rem;
  font-weight: 400;
  position: relative;
  left: 5px;  
}
:global(.form-initial-msg-hidden){
  display: none;
}

.read-only {
  cursor: default;
  outline: none;
  background-color: #f8f8f8 !important;
}

@media (min-width: 360px) {
  .well-form-group {
    min-width: 285px;
    max-width: 350px;
  }
}

@media (min-width: 768px) {
  .well-form-group {
    margin: 0 10px 1rem;
  }
  .well-form-item-search-container{
    width: 350px;
  }
}

/*Text area*/
.well-form-group-textarea {
  position: relative;
  text-align: left;
}

.well-form-textarea-container {
  border-radius: 5px;

  position: relative;
  border: 1px solid rgb(117, 138, 174);
  background-color: rgb(255, 255, 255);
}

.well-form-textarea {
  min-height: 100px;
  border-radius: 10px;
}

.well-form-textarea-container textarea {
  height: 100%;
  width: 100%;
  padding: 0.375rem 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 5px;
  border: none;

  vertical-align: top;
  min-height: 100px;
}

/*PASSWORD*/
.update-password {
  position: absolute;
  right: 2px;
  font-size: 12px;
  color: var(--color-primary);
  cursor: pointer;
  font-weight: 500;
}

.update-password:hover {
  text-decoration: underline;
}

/*SEARCH*/
.magnifying-glass {
  width: 24px;
  height: 20px;
  position: absolute;
  z-index: 2;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}












/*FILE INPUT*/
.custom-file-upload input[type='file'] {
  display: none;
}
.custom-file-upload {
  color: #fff;
  background-color: #70b5f4;
  border-color: #3e94e3;
  cursor: pointer;
  height: 100%;
  font-size: 1rem;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin: 0;
  position: relative;
}

.file-input-text {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  width: 100px;
  min-height: 50px;
  display: flex;
  align-items: center;
}
.file-input-name {
  padding-left: 10px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 50px;
  display: flex;
  align-items: center;
}