.forum {
  position: relative;
}

.forum-container {
  width: 100%;
  padding: 1rem 15px 4rem;

  max-width: 800px;
}



@media (min-width: 992px) {
  .forum-container {
    justify-content: normal;
    min-height: 400px;
  }

}

@media (min-width: 1188px) {
  .forum-container {
    margin-right: auto;
    margin-left: auto;
    /*max-width: 1188px;*/
  }

}

@media (min-width: 1570px) {
  .forum-container {
    /*max-width: 1570px;*/
  }
}