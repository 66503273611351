

/*Participants*/
.participants-container {
  display: inline-block;
}

.participants-row {
}

.participant-item {
  display: inline-flex;
  align-items: center;
  text-align: center;
  position: relative;
  color: black;
}
.participant-item:hover{
  text-decoration: none;
}

.user-image-container{
  margin-right: 5px;
}

.user-image {
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  height: 30px;
  width: 30px;
}

.user-image-can-click {
  position: relative;
  cursor: pointer;
}

.user-image-can-click::before {
  content: '';
  display: inline-block;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 33px;
  width: 33px;
}
