.list {
  border-top: 1px solid rgb(198, 198, 198);
  color: rgb(28, 28, 28);
  padding-top: 16px;
  margin-top: 16px;
  text-align: left;
}

.no-border{
  border: none;
  margin-top: 0;
  padding-top: 0;
}

.list-title {
  font-size: 20px;
  font-weight: 400;
}

.check-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.check-item svg {
  margin-right: 5px;
  height: 22px;
  width: 22px;
}

.yes-check path {
  fill: #0b96c5;
}

.check-item p {
  color: rgb(28, 28, 28);
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  margin-bottom: 0;
}


@media (min-width: 768px) {
  .list-title {
    font-size: 22px;
  }
  .check-item p {
    font-size: 20px;
  }

}
