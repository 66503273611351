.input-grouping {
  max-width: 900px;
  margin: 0 auto 16px;
  padding: 16px 10px;
}

.input-grouping h2 {
  color: black;
  font-size: 24px;
}

.textarea {
  width: 100%;
  border: none;
  min-height: 150px;
}

.deactivated {
  display: none;
  opacity: 0.5;
  pointer-events: none;
}

.double-inputs > div {
  margin-right: 15px;
}

.input-container-textarea{
  margin-top: 25px;
}

.additional{
  position: absolute;
  top: 1px;
  right: 0;
  font-style: italic;
  font-size: 14px;
  display: none;
}

@media (min-width: 576px) {
  .additional{
    display: block;
  }

}

@media (min-width: 992px) {
  .double-inputs {
    display: flex;
  }
}
