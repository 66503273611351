.session-info-right {
  background-color: white;
  padding: 20px;
  position: relative;

  height: 100%;
}

.title {
  font-size: var(--title-font-size);
  color: var(--title-color);
  font-weight: var(--title-font-weight);
  letter-spacing: var(--title-letter-spacing);
  line-height: var(--title-line-height);
}

/* timer */
.time-row {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #f2f2f2;
  border-radius: 10px;
}
.in-session-notice {
  display: flex;
  justify-content: center;
  position: relative;
  flex-basis: 33.3333%;

  font-size: 26px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 64px;
  color: var(--color-error) !important;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
}

/*Buttons*/
.buttons-row-first,
.buttons-row-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.buttons-row-second {
  justify-content: flex-start;
  margin-bottom: 2rem;
}
.no-margin-bottom {
  margin-bottom: 0.5rem;
}
.buttons-row-second > * {
  margin-right: 10px;
}
.no-margin-bottom > * {
  margin-right: 0;
}

.register-btn {
  flex-basis: 100%;
  height: 40px;
}

.unregister-btn {
  flex-basis: 100%;
  height: 32px;
}
.two-buttons{
  margin-right: 5px;
}

.btn-place-holder {
  height: 40px;
  width: 155px;
}

.can-not-enter {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.share-btn {
  height: 40px;
  width: 91px;
}
.no-cal-share-btn {
  flex-basis: 100%;
  margin-left: 0 !important;
}

/*availability*/
.availability-container {
  margin-top: 32px;
}
.availability-row {
  background-color: #f2f2f2;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.availability-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.availability-number {
  color: #000000;
  /*font-size: 40px;*/
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 52px;
}

.availability-text {
  flex-basis: 100%;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

/*Attendees*/
.participants-row {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}

.external-tool-tip {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 130%;
  padding: 10px;
  width: 150px;
  /*box-shadow: 0 10px 30px rgb(65 72 86 / 5%);*/
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  background-color: #ffffff;
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.external-tool-tip::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tool-tip-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  right: 0;
}

.modal-p {
  font-weight: 400;
  text-align: center;
  margin-top: 16px;
}

@media (min-width: 992px) {
  .buttons-row-second {
    justify-content: space-between;
  }
  .no-cal {
    justify-content: flex-end;
  }
  .buttons-row-second > * {
    margin-right: 0;
  }
  .no-cal > * {
    margin-left: 10px;
  }

  .participants-row::after {
    content: '';
    /* flex: 1; */
    height: 115px;
    width: 130px;
  }
}
