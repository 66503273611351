.container {
  background-color: var(--link-color);
  padding: 2px 5px;
  position: fixed;
  bottom: 0;
  z-index: 1000000;
  width: 100vw;
  box-shadow: rgb(101 119 134 / 31%) 0px 0px 15px,
    rgb(101 119 134 / 44%) 0px 0px 3px 1px;
  display: inline-block;
}

.text {
  color: white;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  width: 80%;
}

.buttons-container {
  display: inline-grid;
  min-width: 60px;
  align-items: center;
  justify-content: center;
  vertical-align: top;
}

.btn {
  width: 40px;
  height: 30px;
  margin: 5px;
}
.special-break {
  display: none;
}

@media (min-width: 640px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttons-container {
    display: flex;
    width: 200px;
  }
  .text {
    display: block;
  }
}

@media (min-width: 992px) {
  .container {
    padding: 5px 20px;
  }
  .btn {
    width: 80px;
  }
  /* .special-break {
    display: block;
  } */
}

@media (min-width: 1200px) {
  /* .special-break {
    display: none;
  } */
}
