.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 15;
}

.slide-container {
  width: 90%;
  max-height: 100vh;
  top: 0;
  position: fixed;
  right: 0;
  height: 100%;
  background: #ececec;
  pointer-events: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 16;
  padding: 20px 10px 10px;
  overflow-y: scroll;
  box-shadow: 0 10px 10px 10px rgb(0 0 0 / 30%);
}

.slide-container.slide-out {
  transform: translateX(0);
}

.close-slide {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-slide span,
.close-slide svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .slide-container {
    max-height: 100vh;
    top: 0;
    max-width: 700px;
    padding: 50px 50px 20px;
  }
}



@media (min-width: 1200px) {
  .slide-container {
    max-width: 800px;
  }
}
