.form-container {
  width: 100%;
  position: relative;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: 20px 10px 0;
  padding: 20px 10px 10px;
  flex-shrink: 0;
}

.input-container {
  height: 45px;
  margin-bottom: 10px;
}

.login-submit-btn {
  width: 100%;
  height: 40px;
  margin-top: 12px;
  font-size: 20px !important;
  font-weight: bold;
}

.link-container {
  text-align: center;
  margin: 16px 0;
}

.divider {
  position: relative;
  height: 1px;
  opacity: 0.1;
  background-color: black;
  width: 100%;
}

.divider-padding{
  margin: 16px 0;
}

.server-error {
  color: var(--color-error);
  font-size: 13px;
  text-align: center;
}

.recover-password-btn {
  width: 175px;
  height: 35px;
  margin-top: 5px;
}


.form-title{
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
}
.form-subtitle{
  font-size: 18px;
  font-weight: 400;
  /* margin-bottom: px; */
}

.other-sign-ins{
  position: relative;
  margin-top: 12px;
}

@media (min-width: 425px) {
  .form-container {
    width: 396px;
  }
}

@media (min-width: 900px) {
  .form-container {
    margin-top: 0;
    width: 350px;
  }
}

@media (min-width: 1024px) {
  .form-container {
    width: 430px;
  }
}
