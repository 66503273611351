/*START STATUS*/
.breakout-room-status-box {
  position: absolute;
  left: 50%;
  /* bottom: 0; */
  top: 50%;

  transform: translate(-50%, -50%);
  background: #fff;
  background-image: linear-gradient(48deg, #fff 0%, #e5efe9 100%);
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-radius: 10px;
  z-index: 5;
  color: black;

  padding: 10px;
  width: 100%;
  height: 100%;

  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgb(0 0 0 / 30%);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgb(0 0 0 / 30%);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

.close-breakout-room-status-box {
  position: absolute;
  right: 10px;
  top: -3px;
  font-size: 2rem;
  cursor: pointer;
}

.component-title-container {
  min-height: 30px;
}

.component-title {
  text-align: center;
  display: none;
}

.move-people-btn-container {
  text-align: center;
  width: 100%;
  padding-top: 1rem;
  min-height: 75px;
}
.move-people-btn {
  padding: 0 15px;
  position: absolute !important;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
}

.invalid-arrangement .move-people-btn {
  opacity: 0.6;
  pointer-events: none;
}

/* *********************************************************************************** */

/*START BREAKOUT ROOM BOX*/
.breakout-room-box {
  background-color: white;
  padding: 5px 20px 30px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 90px;
  box-shadow: 0px 2px 7px rgba(31, 31, 33, 0.18);
}
.breakout-room-box-row {
  align-items: center;
  /*justify-content:  space-around;*/
}
.breakout-room-box-title {
  font-size: 1.25rem;
  margin-bottom: 12px;
  margin-left: 10px;
}
.invalid {
  border: 1px solid red;
}

.invalid-notice {
  font-size: 0.75rem;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  color: var(--color-error) !important;
}

/* *********************************************************************************** */

/*START BREAKOUT ROOM PERSON*/
.breakout-room-person {
  border-radius: 20px;
  color: black;
  cursor: pointer;
  z-index: 6;
  margin: 5px;
  position: relative;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border: 1px solid rgba(112, 181, 244, 0.5);
  padding: 5px;
  /*width: 100%;
    height: 50px;*/
}
.person-name-container {
  /*padding-top: 7px;*/
  flex-basis: 100%;
  text-align: center;
  width: 85px;
}
.person-username,
.person-first-name {
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.person-pic {
  width: 45px;
  height: 45px;
  border-radius: 20px;
  display: block;
  width: auto !important;
  display: none;
}

.no-move {
  pointer-events: none;
  /*opacity: 0.6;*/
}

@media (min-width: 576px) {
  .component-title {
    display: block;
  }

  .person-pic {
    display: block;
  }
}

@media (min-width: 768px) {
  .breakout-room-status-box {
    padding: 20px;
    max-width: 780px;
    min-width: 500px;
    height: auto;
    min-height: 400px;
  }
}

@media (min-width: 992px) {
  .breakout-room-person {
    max-width: 175px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: nowrap;
    padding: 0px;
  }

  .person-name-container {
    text-align: left;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-top: 0;
  }

  .person-first-name {
    display: block;
  }
}



/* *********************************************************************************** */

/* Modal */

.invalid-timer-length{
  border: 1px solid var(--color-error) !important;
}

.button-disabled{
  opacity: 0.7;
  pointer-events: none;
}

.invalid-time-notice{
  font-size: 14px;
  color: var(--color-error) !important;
  position: absolute;
  bottom: -21px;
}

.modal-title{
  font-size: 22px;
  text-align: center;
}
.modal-subtitle{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #7a7a7a;
  padding-bottom: 10px;
}
