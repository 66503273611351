.container {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  border-radius: 1.125rem;
  background: rgba(104, 124, 235, 0.1);
  width: 100%;
}

.billing-notice {
  margin-top: 16px;
  font-size: 12px;
  color: #3d3d4e;
  text-align: center;
}

.left {
  text-align: left;
}

.billing-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  color: #737383;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
}

.discount {
  color: var(--color-error);
  font-weight: bold;
}

.strike {
  text-decoration: line-through;
}
.strike-left-price {
  color: var(--color-error);
  padding-right: 10px;
}

.bold {
  color: #292B2E;
}

.line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}
