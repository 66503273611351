/* START SESSIONS PAGE */
.sessions-container {
  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));
  background-color: rgba(248, 247, 243, 255);
  position: relative;
  z-index: 1;
}

.sessions-section-container {
  width: 100%;
  padding: 1rem 5px 4rem;
  min-height: 100px;
  position: relative;
}

.menu-col-item {
  width: 100%;
}

.bottom-loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.list-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  text-align: center;
}

.list-title-container.is-soon {
  position: absolute;
  right: 30px;
}

@media (min-width: 768px) {
  .sessions-section-container {
    padding: 1rem 15px 4rem;
  }
}

@media (min-width: 992px) {
  .sessions-section-container {
    justify-content: normal;
  }

  .sessions-section-container > div {
    margin-right: auto;
  }

  .sessions-container {
    margin-top: 3rem;
    margin-top: 0;
  }

  .list-title-container {
    text-align: left;
    justify-content: space-between;
  }
}

@media (min-width: 1188px) {
  .sessions-section-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1188px;
  }
}

@media (min-width: 1570px) {
  .sessions-section-container {
    max-width: 1570px;
  }
}

/* END SESSIONS PAGE */
