.well-dropdown-container{
    position: relative;
    display: inline-block;
}

.well-dropdown-btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    z-index: 2;
    font-weight: 600;
    display: inline-flex;
    background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    height: 40px;
    width: 32px;
    margin-bottom: 0;
}

.well-dropdown-btn:hover{
  color: white;
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
}


.well-dropdown{
    display: none;
    background-color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    width: 175px;
    border-radius: 10px;
}

.well-dropdown.open{
    display: block;
}

.well-dropdown:after, .well-dropdown:before{
    content: "";
    position: absolute;
    z-index: 11;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.well-dropdown:before{
    border-bottom: 11px solid #DCDCDD;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    height: 0;
    top: -10.5px;
    width: 0;
}

.dropdown-item{
    display: block;
    cursor: pointer;
    color: #6a89af;
    font-size: 0.85rem;
    padding: 7px 10px;
    border: 1px solid #DCDCDD;
}


.dropdown-item:hover{
    text-decoration: none;
    background-color: #eaeaea;
}


.dropdown-item:first-child{
  border-top: 1px solid #DCDCDD;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.dropdown-item:last-child{
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}



.dropdown-item:hover{
    text-decoration: none;
    background-color: #eaeaea;
}


.meatball-items{
    position: relative;
    top: -2px;
    left: -1px;
}