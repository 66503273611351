.members-container {
  padding-bottom: 6rem;
}

.members-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}


.loader{
  padding: 20px 0;
  min-height: 100px;
  position: relative;
}

@media (min-width: 992px) {
  .members-container {
    min-height: 400px;
  }
  .members-row {
    justify-content: flex-start;
  }
}

@media (min-width: 1188px) {
  .members-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1188px;
  }
}

@media (min-width: 1570px) {
  .members-container {
    max-width: 1570px;
  }
}
