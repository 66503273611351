.list-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  border-radius: 10px;
  height: var(--total-height);
}

.view-in-chat {
  border-top: 1px solid var(--color-primary);
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  border-bottom: 1px solid black;
  padding-left: 16px;
  padding-right: 16px;
}

.header-title {
  color: var(--header-large-color);
  font-size: var(--header-large-font);
  font-weight: 700;
  margin-bottom: 0;
}

.inner-row {
  height: calc(100% - var(--header-height));
}

.children {
  overflow-y: scroll;
  height: 100%;
}

.view-in-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 16px;
  z-index: 2;
  background-color: red;
  cursor: pointer;
}

.no-messages-notice {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
