.face-item{
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f4f4f4;
  border-radius: 20px;
  max-width: 230px;
  min-height:190px;
}

.face-item.is-white{
  background-color: white;
}

.face-image{
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.face-percent{
  font-size: 36px;
  font-weight: bold;
  margin: 16px auto;
  position: relative;
  left: 3px;
}

.is-white .face-percent{
  margin: 5px auto 0;
}

.orange .face-percent{
  color:#fda43e;
}
.green .face-percent{
  color:#3dc86d;
}
.blue .face-percent{
  color:#6da9e8
}

.face-text{
  line-height: 1.2;
  text-align:center;
}

.is-white .face-text{
  color: #424242;
}

