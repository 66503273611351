.page-container {
  position: relative;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.center {
  position: absolute;
  left: 50%;
  bottom: -181px;
  transform: translate(-50%, -50%);
  text-align: center;
}

/*Coming soon*/
.coming-soon-container {
  padding-top: 2rem;
  text-align: center;
}

.well-logo {
  max-width: 200px;
}
.coming-soon-text {
  padding-top: 4rem;
  margin: 0 auto;
  max-width: 1000px;
}

/*COMING SOON*/
.email-capture-container {
  margin: 0 auto;
}
.email-capture {
  padding-top: 2rem;
}
.email-submit-btn {
  margin-top: 2rem;
}

/*WELCOME*/
.welcome-btn {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .page-container {
    margin-bottom: 4rem;
  }
}

/*EMAIL PAGES*/
.email-page {
  background-color: rgba(242, 242, 242, 255);
  padding-bottom: 4rem;

  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));
}

.email-container {
  position: absolute;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 10px;
  padding: 40px 20px;
  z-index: 50;
  background: white;
  border: 1px solid #25ba6c;
  width: 99%;
}

.email-logo {
  display: inline-block;
  width: 150px;
}

.email-text {
  text-align: center;
  font-size: 22px;
  padding-top: 2rem;
}

@media (min-width: 576px) {
  .email-container {
    min-width: 575px;
    width: auto;
    margin: 0;
  }

  .email-text {
    font-size: 28px;
  }
}

/* Content Policy */
.content-policy {
  background-color: rgba(242, 242, 242, 255);
  padding-bottom: 4rem;
}

.content-policy-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.content-policy h3 {
  color: #151519;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: left;
}

.content-policy h4 {
  color: #151519;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 20px;
  text-align: left;
}

.content-policy p,
.content-policy ul li {
  color: #575757;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
}

.content-policy p,
.content-policy ul {
  margin-bottom: 40px;
}

.italic {
  font-style: italic;
}

.no-margin {
  margin-bottom: 0 !important;
}

.link{
  color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
}

