.options {
  width: 100%;
  background-color: white;
  z-index: 2;
  position: relative;
  overflow: visible;
  display: flex;
  display: inline-flex;
  align-items: center;
  justify-content: left;
  position: relative;
}

.options-btn,
.share-btn-container,
.add-to-calendar-container {
  color: white !important;
  background: linear-gradient(to bottom, #87bdee 0%, #3e94e3 100%);
  position: relative;
  padding: 4px;
  height: 30px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
}

.is-orange{
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
}

.options-btn:hover,
.share-btn-container:hover,
.add-to-calendar-container:hover{
  background-color: rgba(104, 172, 233, 0.9) !important;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
}

.options-btn {
  flex-shrink: 1;
  margin-right: 0;
  min-width: 80px;
}

.options-btn div:first-child > div {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.options-btn:hover div::after {
  color: black !important;
}

.share-btn-container {
  width: 100px;
  justify-content: space-evenly;
}

.share-text {
  display: none;
}

.add-to-calendar-container {
  position: relative;
  width: 153px;
  flex-shrink: 0;
}

.add-to-calendar-container svg {
  display: none;
}

.add-to-calendar-text {
  color: white;
}

.add-to-calendar-container :global(.chq-atc--button) {
  padding: 0 5px;
}

.add-to-calendar-container :global(.chq-atc--button):focus,
.add-to-calendar-container :global(.chq-atc--button):hover{
  background-color: inherit;
}

.add-to-calendar-container :global(.chq-atc--dropdown) {
  z-index: 15 !important;
  display: block;
  top: 36px;
  transform: translateX(-50%);
  left: 50%;
  width: 170px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  border: none;
}

.add-to-calendar-container :global(.chq-atc--dropdown a) {
  display: block;
  cursor: pointer;
  font-size: 16px;
  padding: 7px 10px;
  border-bottom: 1px solid #dcdcdd;
  text-align: center;
  color: black;
  font-weight: 400;
}

.add-to-calendar-container :global(.chq-atc--dropdown):after,
.add-to-calendar-container :global(.chq-atc--dropdown):before {
  content: '';
  position: absolute;
  z-index: 11;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.add-to-calendar-container :global(.chq-atc--dropdown):before {
  border-bottom: 11px solid #dcdcdd;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  height: 0;
  top: -10.5px;
  width: 0;
}

@media (min-width: 428px) {
  .share-text {
    display: inline;
  }
}
