.image-preview-container {
  margin-top: 16px;
}

.image-preview-container h3 {
  font-weight: 500;
  margin: 0px;
  padding-bottom: 2px;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgb(104, 118, 141);
  padding-left: 1px;
}

.image-preview {
  max-width: 300px;
  height: 100px;
}

.no-image[type='file'] {
  color: transparent;
}

