.main-container {
  bottom: 0;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  --item-border-radius: 20px;
  --main-color: rgb(29, 155, 240);
  padding: 6px 10px;
  background: white;
}

.main-container::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  box-shadow: rgb(3 102 214 / 30%) 0px 0px 1px 1px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container {
  border-radius: var(--item-border-radius);
  border-color: rgb(207, 217, 222);
  background-color: rgb(255, 255, 255);
  display: flex;
  width: 500px;
  border: 2px solid var(--main-color);
  padding: 5px 10px;
}

.input-container > div {
  height: auto !important;
}

.input-container :global(.str-chat__textarea__textarea) {
  padding: 0 !important;
  border: none !important;
  /* height: auto !important; */
  min-height: 100%;
  min-width: 221px;
  resize: none;
}

.input-container :global(.str-chat__textarea__textarea):focus {
  outline: none !important;
  box-shadow: none !important;
}

.input-container :global(.rta__autocomplete) {
  z-index: 2000;
}

.file-upload-container {
  margin-right: 10px;
}

.message-input-textarea {
  font-size: 14px;
  border-radius: var(--item-border-radius);
  caret-color: var(--main-color);
  min-height: 20px;
  width: 100%;
  /* min-height: calc(var(--footer-height) - 10px); */
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.submit-svg-container {
  margin-right: 10px;
}

.submit-svg {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

@media (min-width: 768px) {
  .main-container {
    bottom: 2px;
    width: var(--total-width);
  }
}
