.button {
  display: flex;
  padding: 1rem 4.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}

.green {
  background: #32a7ad;
  color: white;
}

.white {
  background: white;
  box-shadow: 0px 4px 16px 0px rgba(24, 23, 89, 0.25);
  color: #32a7ad;
}
