.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.question-container {
  width: 300px;
  padding-right: 1rem;
  margin-bottom: 0.3rem;
}
.question {
  color: #4a4a52;
  text-align: left;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 1rem 0;
}

.answers-container {
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  min-width: 400px;

  transition-duration: 600ms;
  transition: left 0.3s ease-in-out;
}

.answers-container.line::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  flex-shrink: 0;
  background: rgba(104, 124, 235, 0.3);
  bottom: 5px;
  transform: translateY(-50%);
  z-index: 0;
}

.answer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.answer::before {
  content: '';
  position: absolute;
  background: transparent;
  height: 40px;
  width: 100px;
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
}

.answer-ball {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  border-radius: 100%;
  border: 1px solid rgba(104, 124, 235, 0.3);
  background: #ecedf8;
  cursor: pointer;
  z-index: 1;
}

.answer-ball.selected {
  border: 2px solid var(#687ceb);
  background: rgba(104, 124, 235, 0.8);
}

.answer-response {
  color: #4a4a52;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.answer-response,
.answer {
  width: 125px;
}

.answer-text {
  color: #0a0a0a;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.01875rem;
  padding-bottom: 8px;
}

@media (min-width: 622px) {
  .answers-container {
    position: relative;
    left: 0 !important;
  }
}

@media (min-width: 768px) {
  .answers-container {
    min-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    flex-direction: row;
    margin-bottom: 1.75rem;
  }

  .question {
    text-align: right;
    margin: 0;
  }

  .answers-container.line::after {
    top: 50%;
    bottom: auto;
  }

  .answer {
    flex-direction: row;
  }

  .answer-text {
    display: none;
  }
}


