.inside-wrapper {
  display: flex;
  height: 100%;
}

.right-wrapper {
  background-color: white;
  padding: 10px;
}

.input-container {
  height: 45px;
  margin-bottom: 10px;
}

.server-errors {
  position: relative;
  color: #dc3545;
  font-size: 14px;
  margin-bottom: 10px;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
  padding-left: 5px;
  text-align: center;
}

.subtitle {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0;
  padding-left: 5px;
  color: #606770;
  padding-top: 3px;
  text-align: center;
}

.checkbox-container {
  margin-top: 16px;
  font-size: 13px;
}

.label {
  padding-left: 10px;
  margin-bottom: 0;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.payment-cta {
  font-size: 20px;
}

@media (min-width: 576px) {
  .label {
    text-align: left;
  }
}

/**********************************************/

/* start branded */
.left-wrapper {
  display: none;
  position: relative;
  text-align: center;
  flex-basis: 50%;
  flex-shrink: 0;
  background-color: #f5f6fa;
  border-radius: 10px;
  padding: 10px;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
}

.logo-container {
  border-radius: 100%;
  padding: 5px;
}

.logo-container:first-child {
  position: relative;
  left: 10px;
}
.logo-container:nth-child(2) {
  position: relative;
  right: 10px;
}

.logo {
  border-radius: 100%;
}

.psi-logo{
  width: 300px;
}

.branded-header {
  font-size: 24px;
  color: #3e94e3;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 1.2;
}
.branded-text {
  font-size: 17px;
  color: #333;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1.2;
}

.branded-bottom-notice{
  position: absolute;
  bottom:0;
  text-align: center;
  font-size: 13px;
  left:0;
  padding: 0 10px;

}

.quote {
  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 10px;
  font-style: italic;
  font-weight: 400;
  color: rgb(118, 118, 118);
}

.author {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  color: rgb(118, 118, 118);
}

.already-member {
  text-align: center;
  font-size: 14px;
  position: relative;
  display: block;
  padding-top: 10px;
}

@media (min-width: 992px) {
  .branded-modal-wrapper {
    min-width: 900px;
    min-height: 400px;
  }

  .left-wrapper {
    display: block;
  }
}
