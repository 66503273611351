.large-container {
  padding-top: 3rem;
}

.sessions-container {
  margin-top: 3rem;
}

.loader-div {
  position: relative;
  height: 160px;
}

.groups-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.groups-row > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.groups-row > a {
  margin-bottom: 20px;
}

.view-groups-btn-container {
  text-align: center;
}

.view-groups-btn {
  height: 48px;
  width: 265px;
  font-size: 18px;
}

@media (min-width: 992px) {
  .groups {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media (min-width: 1400px) {
  .large-container {
    max-width: 1340px;
  }

  .groups-row > a {
    width: 365px;
  }
}
