.select-item {
  position: relative;
  display: flex;
  height: 2.25rem;
  padding: 0.25rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 1.3125rem;
  background: rgba(104, 124, 235, 0.1);
  border-radius: 1.625rem;
  cursor: pointer;
  flex-shrink: 0;

  color: #475569;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
}

.select-item.selected {
  color: white;
  background: rgba(104, 124, 235, 0.8);
  /* padding: 0.25rem 0.5rem 0.25rem 0.75rem; */
}

.x-container {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 1200px) {
  .select-item {
    min-width: 10.2rem;
  }
}
