.left {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: visible;
  /* height: calc(100vh - var(--top-nav-height)); */
  height: 100vh;

  -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
  padding: 10px 0;
  background-color: white;
  max-width: 300px;
  padding-top: 25px;

  /* Slider options */
  position: sticky;
  z-index: 5;
  /* top: var(--top-nav-height); */
  top: 0;
  left: 0;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateX(-130%);
  transition: transform 0.3s;
  will-change: transform;
  padding-bottom: 75px;
}

.left.open {
  position: absolute;
  transform: translateX(0);
  min-width: 230px;
}

.item-container {
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 36px;
  width: 100%;
  position: relative;
  white-space: normal;
  cursor: pointer;
  padding: 7px 5px 7px 12px;
  position: relative;
}

.item-container:hover {
  background-color: rgba(135, 189, 238, 0.4);
}

.item-container:hover a {
  color: black;
}

.selected:hover,
.selected {
  background-color: rgba(135, 189, 238, 0.6) !important;
}
.selected::after {
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: rgba(135, 189, 238, 1);
}

.list-item-text {
  font-size: 18px;
  font-weight: 600;
  color: #2c333d;
}

.header-container {
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #2c333d;
  letter-spacing: 0;
  margin: 5px 0 10px 16px;
}

.group-item-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 10px;
  width: 100%;
}

.divider {
  height: 1px;
  width: 95%;
  background-color: #ced0d4;
  /* background-color: black; */
  margin: 10px auto;
}

.small-divider {
  height: 1px;
  width: 85%;
  background-color: rgba(0, 0, 0, 0.05);
  /* background-color: black; */
  margin: 1px;
  margin-left: 10px;
}

.close-icon {
  position: absolute;
  right: 0px;
  top: -3px;
  cursor: pointer;
}

.open-icon {
  position: absolute;
  left: 54%;
  top: 5px;
  transform: translateX(-50%);
  cursor: pointer;
}

.create-session-btn{
  margin: 10px 0;
  margin-left: 15px;
}

.join-session-text {
  font-size: 14px;
  padding-left: 16px;
  padding-right: 10px;
}

.join-session-btn {
  margin-left: 15px;
  margin-bottom: 10px;
  height: 35px;
  width: 140px;
}

.my-sessions-container {
  min-height: 30px;
  width: 100%;
  position: relative;
}

.past-sessions-link {
  color: #58a3e7;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: 10px 15px;
}

@media (min-width: 768px) {
  .left {
    display: flex;
    transform: translateX(0) !important;
    position: sticky;
    min-width: 230px;
    margin-top: 0;
    padding-top: 0;
  }

  .left.open {
    position: sticky;
  }

  .open-icon,
  .close-icon {
    display: none;
  }
}
