.parent {
  width: 100% !important;
  position: relative;
}

.group-info-parallax {
  position: absolute;
  height: 100%;
  width: 100% !important;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
}

.no-image {
  position: absolute;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
}

.parallax-content {
  color: white;
  position: relative;
  z-index: 2;
  padding: 40px 20px;
  text-align: center;
}

.parallax-row {
  display: flex;
  flex-wrap: wrap;
}

.group-title {
  flex-basis: 100%;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
}

.group-desc {
  flex-basis: 100%;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-word;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  display: none;
}

.bottom-row {
  z-index: 2;
  position: relative;
}

.btn-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.subscribe-btn {
  position: relative;
  z-index: 2;
  height: 35px;
  width: 120px;
  margin-right: 10px;
}

.options-btn {
  position: relative;
  z-index: 2;
  margin: 0 5px;
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);

  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  height: 35px;
  width: 32px;
}

.options-btn:hover {
  color: white;
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
}

.email-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  color: white;
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  padding: 8px;
  height: 35px;
  margin: 0 5px;
}
.email-link:hover {
  color: white;
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
}
.share-icon {
  width: 20px;
  height: 20px;
}

.wave-svg-desktop {
  display: inline-block;
  position: absolute;
  bottom: -1px;
  left: 0;
  stroke: none;
  fill: rgba(248, 247, 243, 255);
  height: 25px;
}


.members-container{
  margin: 8px auto;
}

.member-number{
  font-weight: bold;
  font-size: 16px;
}

.member-number span{
  font-weight: 400;
}

@media (min-width: 768px) {
  .parent {
    min-height: 275px;
  }
  .parallax-content {
    text-align: left;
  }

  .group-desc {
    display: flex;
  }

  .members-container{
    margin: 8px 0;
  }  

  .btn-row {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1188px) {
  .parallax-content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1188px;
  }
}

@media (min-width: 1570px) {
  .parallax-content {
    max-width: 1570px;
  }
}
