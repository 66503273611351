.organizer-row {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  color: #151519;
}
.organizer-row:hover {
  text-decoration: none !important;
}

.title {
  font-size: var(--title-font-size);
  color: var(--title-color);
  font-weight: var(--title-font-weight);
  letter-spacing: var(--title-letter-spacing);
  line-height: var(--title-line-height);
}

.organizer-img-container {
  height: var(--user-image-size);
  width: var(--user-image-size);
  position: relative;
  flex-shrink: 0;
  right: -2px;
}

.organizer-clickable {
  display: flex;
  align-items: center;
}

.logged-in {
  overflow: hidden;
}

.logged-in .organizer-row {
  cursor: pointer;
}
.logged-in .organizer-row:hover {
  text-decoration: underline;
}

.logged-in .organizer-img-container {
  cursor: pointer;
}

.logged-in .organizer-img-container::after {
  content: '';
  display: inline-block;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: var(--user-image-outline-size);
  width: var(--user-image-outline-size);
  z-index: 1;
}
.organizer-img {
  position: relative;
  z-index: 2;
  border-radius: 50%;
}

.organizer-name {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  /* line-height: 28px; */
  margin-bottom: 2px;
}
.organizer-about {
  flex-basis: 100%;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 1rem;
  word-break: break-word;
}

.badges-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
}

.badges-row div {
  margin: 0 2px;
}

.badges-row img {
  width: 28px;
}

.badges-row span {
  font-size: 12px;
  display: none;
}

.credentials {
  align-items: center;
  justify-content: flex-start;
  display: none;
}

.credential {
  position: relative;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 10px;
  font-size: 14px;
  background-color: #e8e4e4;
  margin-right: 5px;
  white-space: nowrap;
}

.organizer-row:hover .credentials {
  color: black;
  text-decoration: none !important;
}

.top-stats-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .credentials {
    display: flex;
  }
}

@media (min-width: 992px) {
}
