.page {
  height: calc(100vh);
  background-color: rgba(112, 181, 244, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-height: 650px;
}

.container {
  max-width: 980px;
  position: relative;
  padding: 0 10px;
}

.page-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-container {
  text-align: center;
  padding: 0 15px 0;
}

.logo-container p {
  font-size: 20px;
  line-height: 28px;
  width: auto;
  font-weight: normal;
  max-width: 425px;
}
.page-logo {
  max-width: 300px;
}

/* register page */
.register-form-container {
  width: 100%;
  position: relative;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: 20px 10px 0;
  padding: 20px 10px 10px;
  flex-shrink: 0;
}

@media (min-width: 425px) {
  .container {
    padding: 0;
  }
}

@media (min-width: 900px) {
  .page-row {
    position: relative;
    flex-wrap: nowrap;
  }

  .logo-container {
    text-align: left;
    /* margin-right: 50px; */
    max-width: 500px;
    padding: 0;
  }
  .page-logo {
    max-width: 375px;
  }

  .logo-container p {
    font-size: 24px;
    max-width: none;
  }
}

@media (min-width: 1024px) {
  .logo-container {
    margin-right: 125px;
    max-width: 550px;
  }

  .logo-container p {
    font-size: 28px;
  }
}

/* REGISTER & RESET PASSWORD */
.form-container {
  width: 100%;
  position: relative;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: 20px 10px 0;
  padding: 20px 10px 10px;
  flex-shrink: 0;
}

@media (min-width: 425px) {
  .form-container {
    width: 396px;
  }
}

@media (min-width: 900px) {
  .form-container {
    width: 350px;
  }

  .form-width {
    width: 400px;
  }

  .register-text {
    font-size: 35px !important;
    line-height: 1.3 !important;
  }
}

@media (min-width: 1024px) {
  .form-container {
    width: 500px;
  }

  .form-width {
    width: 450px;
  }
}
