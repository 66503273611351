.panel-container {
  position: relative;
  min-height: 50px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
  margin: 0 0 16px;
  width: 100%;

  /*Vars*/
  --header-font-size: 16px;
  --header-font-weight: 500;

  border: 1px solid #dbdae3;
}

.panel-heading {
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  display: -webkit-flex;
  display: flex;
  padding: 15px 16px 10px;
  position: relative;
}
.title {
  font-size: 18px;
}

.panel-content {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 15px 16px 10px;
}

.panel-footer {
  position: relative;
  padding: 10px 20px;
  border-top: 1px solid #EFEFEF;
}
