/*Nav*/
.nav-color {
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 100000;
  height: var(--top-nav-height);
  width: 100%;
  background: linear-gradient(
    to right,
    rgb(88, 163, 231) 0%,
    rgb(37, 186, 108) 100%
  );
}

.header-nav {
  width: 100%;
  height: var(--top-nav-height);
  font-size: 1rem;
  display: flex;
  width: 100%;
  z-index: 12;
  position: fixed;
  top: 0;
  background-color: white;
  padding: 0;
}

.header-nav.in-room {
  /* position: relative; */
  display: none;
}

.header-nav:not(.is-transparent) {
  border-bottom: 1px solid rgba(112, 181, 244, 0.05);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.hover-item {
  position: fixed;
  top: 0;
  height: var(--top-nav-height);
  width: 100%;
  background-color: transparent;
  z-index: 3;
}
.hover-item:hover + .header-nav,
.header-nav:hover {
  top: 0;
}

.is-only-show-on-hover {
  top: -75px;
  transition: top 0.5s ease;
}

.header-nav :global(.navbar-collapse) {
  background: transparent;
}

.top-nav-container {
  z-index: 100002;
  position: relative;
  padding: 0.5rem 1rem;
}

.nav-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  text-align: justify;
}

.top-nav-item {
  color: #4a4a53;
  text-decoration: none;
  padding: 0 24px;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  position: relative;
  white-space: nowrap;
}

.top-nav-item-active {
  color: #70b5f4;
}

.top-nav-item:hover {
  color: #70b5f4 !important;
  text-decoration: none;
  position: relative;
}

.is-transparent .top-nav-item:hover {
  color: #f07592 !important;
}

.faq {
  left: 9px;
}

.logo-link-white {
  position: absolute;
}

.logo-link,
.logo-link-white {
  font-size: 1.35rem;
  color: #4a4a53;
  cursor: pointer;
}

.logo-link-white img, 
.logo-link img {
  width: 225px;
}

/* .nav-logo-white {
  width: 181px;
  position: relative;
  top: -4px;
  left: 1px;
} */

.logo-link:hover,
.logo-link-white:hover {
  color: #4a4a53;
  text-decoration: none;
}

:global(.navbar-collapse) {
  background-color: white;
  width: 100%;
}

/*DROP DOWN*/
#top-nav :global(.dropdown-toggle::after) {
  display: none;
}

#top-nav :global(.dropdown) {
  margin-left: 12px;
  display: block;
}
#top-nav :global(.dropdown-menu) {
  position: absolute;
  left: -101px;
  top: 67px;
  z-index: 2;
  padding: 0;
  opacity: 0;
  border-radius: 5px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  display: none;
}

#top-nav :global(.dropdown-menu.show) {
  opacity: 1;
  display: flex;
}

.dropdown-item {
  width: 100%;
  text-align: center;
  padding: 8px 0 !important;
}

.dropdown-item:hover {
  color: #70b5f4 !important;
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-break {
  width: 100%;
  border-top: 1px solid #dcdcdd;
}

.user-image {
  border-radius: 50%;
  width: 51px;
  height: 51px;
  position: relative;
  left: 0.5px;
}

.user-image-container::before {
  content: '';
  display: inline-block;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 52px;
  width: 52px;
}

.user-image-container:hover:before {
  background: linear-gradient(to right, #70b5f4 0%, #70b5f4 100%);
}

.is-transparent .user-image-container:hover:before {
  background: linear-gradient(to right, #f07592 0%, #f07592 100%);
}

.small-hidden {
  display: none;
}

.login-btn {
  height: 36px;
  width: 107px;
  font-size: 17px;
}

.beta-tag {
  width: 37px;
  position: absolute;
  top: -12px;
  right: 10px;
}

@media (min-width: 768px) {
  .top-nav-login {
    border: 1px solid #909097;
  }

  .small-hidden {
    display: flex;
  }
}

@media (min-width: 992px) {
  .top-nav-container {
    max-width: none !important;
  }
}

@media (min-width: 1200px) {
  .top-nav-item {
    padding: 0 28px;
  }
}

/* Homepage buttons */
.top-nav-item-btn,
.top-nav-item-btn-2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  text-decoration: none;
  font-weight: 600;
  color: white;
  padding: 7px 20px;
}
.top-nav-item-btn {
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  margin-right: 16px;
}
.top-nav-item-btn:hover {
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
  color: white;
}

.top-nav-item-btn-2 {
  color: #7a7676;
  background: #ffffff;
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
  padding: 7px 30px;
}
.top-nav-item-btn-2:hover {
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
  color: white;
}
