.group-edit-page{
   text-align: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: rgba(220, 216, 197, 0.2);
  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height)); 
}



.form-container {
  position: relative;
  max-width: 860px;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);

  margin-left: 15px;
  margin-right: 15px;
}

.title{
  margin-bottom: 26px;
}


.form-container > div{
  max-width: none;
  margin: 0 auto 16px;
}


.save-btn{
  margin-top: 24px;
  height: 40px;
  width: 150px;  
}


@media (min-width: 860px) {
  .form-container {
    margin-left: auto;
    margin-right: auto;
  }
}
