.session-info {
  --background-color: rgb(248, 244, 244);

  --title-font-size: 18px;
  --title-color: #151519;
  --title-font-weight: 500;
  --title-letter-spacing: 0;
  --title-line-height: 27px;

  --user-image-size: 60px;
  --user-image-outline-size: 62px;

  --timer-size: 136px;

  --left-percent: 58.333333%;
  --right-percent: 41.666667%;

  background-color: var(--background-color);
}

.session-info-parallax {
  height: 320px;
  width: 100% !important;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  animation: blur-mas 1s ease-out forwards;
  position: absolute;
  z-index: 0;
}

@keyframes blur-mas {
  0% {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
  100% {
    -webkit-filter: blur(15px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
  }
}

.session-info-body {
  padding: 0;
  position: relative;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
}

.related-sessions {
  padding: 32px 0 16px;
  display: none;
}
.related-sessions-title {
  font-size: var(--title-font-size);
  color: var(--title-color);
  font-weight: var(--title-font-weight);
  letter-spacing: var(--title-letter-spacing);
  line-height: var(--title-line-height);
  text-align: center;
}
.related-sessions-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.options-row {
  width: 100%;
  height: 40px;
  border-top: 1px solid #dbdae3;
  border-bottom: 1px solid #dbdae3;
  background-color: white;
  position: relative;
  z-index: 2;
  display: none;
}

.upgrade-text {
  color: white;
}
.upgrade-text:hover {
  color: white;
  text-decoration: underline;
}

.desktop {
  display: none;
}
.mobile {
  padding: 20px 20px 32px 20px;
  background-color: white;
  margin-bottom: 48px;
}

@media (min-width: 768px) {
  .related-sessions-row {
    justify-content: space-around;
  }
}

@media (min-width: 992px) {
  .session-info {
    --title-size: 20px;
    padding-bottom: 64px;
  }

  .session-info-parallax {
    height: 600px;
  }
  .options-row {
    display: block;
  }

  .left-container {
    flex: 0 0 var(--left-percent);
    max-width: var(--left-percent);
  }

  .right-container {
    flex: 0 0 var(--right-percent);
    max-width: var(--right-percent);
  }

  .related-sessions {
    display: block;
  }

  .related-sessions-title {
    text-align: left;
  }
  .related-sessions-row {
    justify-content: left;
  }

  .related-sessions-row > div {
    margin-right: 10px;
    max-width: 355px;
  }

  .desktop {
    display: flex;
  }

  .mobile {
    display: none;
  }
}

@media (min-width: 1200px) {
  .session-info {
    --left-percent: 66.666667%;
    --right-percent: 33.333333%;
  }
}
