.group-item-list {
  position: relative;
  display: flex;
  align-items: center;
  color: black;
}

.place-holder {
  background: linear-gradient(90deg, #58a3e7 0, #25ba6c);
}

.content{
  /* display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; */
}

.title {
  margin-bottom: 0;
  word-break: break-word;
  font-size: 15px;
  font-weight: 500;
  max-width: 225px;
  overflow: hidden;
  margin-bottom: 0;
}

.members {
  color: #9398a0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
}

.image {
  flex-shrink: 0;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  margin-right: 5px;
}
