.messages-list-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
  border-radius: 10px;
  z-index: 20;
}

:global(.persistent-message-list) {
  padding: 0 5px !important;
  flex: 1 1;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(
    var(--total-height) - var(--header-height) - var(--footer-height)
  );
}

.messages-list-container :global(.str-chat__ul) {
  padding: 0;
  list-style-type: none;
  position: relative;
}

.messages-list-container :global(.str-chat__ul) li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hidden {
  display: none;
  top: 0;
}

@media (min-width: 768px) {
  .messages-list-container {
    height: 100%;
  }
}
