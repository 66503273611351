/*  */
.outside-container {
  /* Sections */
  --section-margin-bottom: 32px;

  /* section title */
  --section-title-size: 24px;
  --section-title-weight: 500;
  --section-title-color: #151519;
  min-height: var(--page-min-height);
}

.outside-container {
  position: relative;
  background-color: #f2f2f2;
}

.container {
  padding: 0 10px 150px;
}


.header-class{
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  height: 100px;
  width: 100%;
}


@media (min-width: 992px) {
  .outside-container {
    --section-title-size: 22px;
  }

  .header-class{
    position: absolute;
  }  

  .container {
    padding-bottom: 75px;
    display: flex;
    justify-content: flex-start;
    padding-top: 125px;
  }
  .left-column {
    width: 420px;
    flex-shrink: 0;
    margin-right: 20px;
  }

  .left-column.is-host {
    position: relative;
    top: 5px;
  }
}

@media (min-width: 1188px) {
  .container {
    --section-margin-bottom: 32px;
  }

  .container {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1570px) {
  .container {
    max-width: 1570px;
  }
}
