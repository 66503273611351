.container {
  padding: 15px;
  background-color: white;
  margin-bottom: 20px;
}

/* Username & image */
.top-items {
  position: relative;
  padding-top: 15px;
}

.username-and-photo {
  color: black;
}

.user-image-container {
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
}

.member-since {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.earn-credit-container {
  text-align: center;
}

.earn-credit {
  font-weight: 500;
  font-size: 14px;
}

.user-image-container {
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
}

.user-image,
.user-image-loader {
  position: relative;
  height: 125px;
  width: 125px;
  border-radius: 50%;
  display: inline-block;
  border: 10px solid white;
}
.user-image-loader {
  background-color: white;
}
.new-photo-clickable {
  z-index: 2;
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: linear-gradient(135deg, #58a3e7, #25ba6c);
  padding: 3px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid white;
}
.new-photo-clickable:hover {
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  /* border: 1px solid white; */
}
.pencil {
  width: 20px;
  height: 20px;
}

.username {
  color: #151519;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  word-break: break-word;
  margin-bottom: 0;
}

.user-age {
  color: #9398a0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

/* Badges */
.my-badges {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 10px;
}

/* stats */
.top-stats-row {
  display: flex;
  justify-content: center;
}

.stat-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  flex-basis: 33%;
  min-height: 120px;
  margin: 5px;
  border-radius: 2px;
  max-width: 175px;
}

.stat-item-small{
  max-width: 100px;
  padding: 5px;
  min-height: 50px;
}

.state-item-number {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
}

.stat-item-small .state-item-number {
  font-size: 22px;
}

.stat-item-text {
  text-align: center;
  color: #575757;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
}

.stat-item-small .stat-item-text {
  font-size: 14px;
}

/* Input items */
.input-items {
  margin-top: 20px;
}

/* Has nothing */
.has-nothing {
  position: relative;
  text-align: center;
  max-width: 500px;
  margin: 20px auto 20px;
}
.nothing-top-text {
  color: #151519;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.nothing-bottom-text {
  color: #151519;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.has-nothing-button {
  margin-top: 20px;
  height: 40px;
  width: 175px;
}

.hidden-upload {
  display: none;
}

@media (min-width: 992px) {
  .top-items {
    padding-top: 20px;
  }

  .user-image-container {
    top: -120px;
  }

  .username {
    font-size: 22px;
    margin-top: 10px;
  }

  /* Had nothing */
  .nothing-top-text {
    font-size: 18px;
  }

  .nothing-bottom-text {
    font-size: 16px;
  }
}
