.ribbon {
  width: 24px;
  height: 36px;
  background-color: var(--color-primary);
  position: absolute;
  right: 0;
  top: 0;
  /* right: 100px;
  top: -350px; */
}
.ribbon::before {
  content: '';
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: -10px;
  border-left: 12px solid var(--color-primary);
  border-right: 12px solid var(--color-primary);
  border-bottom: 12px solid transparent;
}

.star {
  position: relative;

  display: inline-block;
  width: 0;
  height: 0;

  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.3em solid transparent;

  /* controls the size of the stars. */
  font-size: 10px;
  position: absolute;
  left: 53%;
  top: 36%;
  transform: translate(-50%, -50%);
}

.star::before,
.star::after {
  content: '';

  display: block;
  width: 0;
  height: 0;

  position: absolute;
  top: 0.6em;
  left: -1em;

  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 1em solid transparent;

  transform: rotate(-35deg);
}

.star::after {
  transform: rotate(35deg);
}



.star12 {
 background: var(--color-primary-dark);
 width: 35px;
 height: 35px;
 position: relative;
}
.star12::before, .star12::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 height: 35px;
 width: 35px;
 background: var(--color-primary-dark);
}
.star12::before {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}
.star12::after {
 -webkit-transform: rotate(60deg);
 -moz-transform: rotate(60deg);
 -ms-transform: rotate(60deg);
 -o-transform: rotate(60deg);
 transform: rotate(60deg);
}


.new-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
}