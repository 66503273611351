.parent {
}

.container {
  text-align: left;
  margin: 5px 0;
  position: relative;
}

.label {
  font-weight: 500;
  margin: 0px;
  padding-bottom: 2px;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgb(104, 118, 141);
  padding-left: 1px;
}

.input-container {
  border-radius: 5px;
  border: 1px solid #dddfe2;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.input-container input,
.input-container textarea {
  border: none;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
}

.input-container input::placeholder,
.input-container textarea::placeholder {
  /* color: var(--color-primary); */
  color: #90949c;
  font-weight: 500;
  font-size: 16px;
}

.input-container input:focus::placeholder,
.input-container textarea:focus::placeholder {
  color: #c3c7cd;
}

.input-container:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.input-container input:focus,
.input-container textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(0 123 255 / 25%);
  caret-color: var(--color-primary-dark);
}

.error {
  color: var(--color-error);
  height: 0;
  float: right;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  right: 4px;
  top: -1px;
  z-index: 2;
}

.error-with-label {
  top: 24px;
}

.char-count {
  position: absolute;
  right: 2px;
  bottom: -18px;
  font-size: 12px;
  color: rgb(104, 118, 141);
}
