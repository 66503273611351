.bullet-item {
  display: flex;
  align-items: center;
  padding-right: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.align-top {
  align-items: flex-start;
}

.bullet-item-text {
  color: #151519;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
  padding-left: 10px;
}
.bullets {
  font-size: 22px;
  padding-left: 10px;
}

.green-check-container,
.red-x-container {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green-check svg,
.red-x svg {
  width: 100%;
}

.number-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.number {
  width: 40px;
  height: 40px;
}

.circle-container {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 25px;
  height: 25px;
}


@media (min-width: 992px) {
  .bullet-item-text {
    font-size: 18px;
  }


}