.container {
  background-color: white;
  padding: 15px 15px 15px;
  margin-bottom: 20px;
  margin-bottom: 32px;
  position: relative;
}

.top-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.container .item:nth-child(n + 2) {
  display: none;
}

.container .item:nth-child(n + 1)::after {
  display: block;
}

.item:last-of-type::after {
  display: none !important;
}

.section-title {
  color: var(--section-title-color);
  font-size: var(--section-title-size);
  font-weight: var(--section-title-weight);
  text-align: center;
  margin-bottom: 0;
}

.view-all {
  display: none;
  top: -5px;
  color: #58a3e7;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  right: 0;
  position: relative;
}

.view-all:hover {
  text-decoration: underline;
}

/* items */
.item {
  width: 100%;
  padding: 20px 0 10px;
  position: relative;
}

.item-inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.item::after {
  content: '';
  width: 95%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000;
  height: 1px;
  opacity: 0.1;
}


.left {
  margin-bottom: 20px;
}

.pic-container {
  position: relative;
  margin-bottom: 10px;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.pic {
  width: 55px;
  height: 55px;
  border-radius: 100%;
}

.user {
  color: #151519;
  font-size: 16px;
  font-weight: bold;
}

.session {
  color: #575757;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.date {
  color: #575757;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.right {
  color: #151519;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 20px;
  max-width: 700px;
}

.make-public-container {
  text-align: center;
  margin-top: 10px;
}

.make-public {
  border-radius: 10px;
  background-color: #58a3e7;
  border: 1px solid #58a3e7;
  padding: 1px 4px;
  cursor: pointer;
  display: inline-block;
}

.make-public:hover {
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  border: 1px solid #25ba6c;
}

.make-public-text {
  color: white;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -1px;
}

.bottom-items {
  position: relative;
  position: absolute;
  bottom: 0;
  left: 10px;
}

.thumb-container {
  padding-top: 10px;
}

.thumbs-up,
.thumbs-down {
  width: 30px;
}

@media (min-width: 768px) {
  .item-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }

  .left {
    display: flex;
    width: 250px;
    margin-right: 40px;
    flex-shrink: 0;
  }

  .right {
    margin-top: 10px;
  }

  .view-all{
    display: block;
  }

  .pic-container {
    margin-bottom: 0;
    margin-right: 15px;
  }

  .make-public-container {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .top-container {
    justify-content: space-between;
  }

  .section-title {
    text-align: left;
  }

  .item {
    padding: 30px 0 10px;
  }

  .container .item:nth-child(n + 1) {
    display: block;
  }
  .container .item:nth-child(n + 1)::after {
    display: block;
  }
}

@media (min-width: 1200px) {
  .item {
    padding: 20px 0 10px;
  }
}
