.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  /* overflow: hidden; */
}

.menu-item {
  margin-top: 2px;
  position: relative;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
}

/* .inactive {
  opacity: 0.5;
} */

.menu-item-text {
  color: #9398a0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}

.menu-item-text.active {
  color: #151519;
  font-weight: bold;
}

.menu-item-text.active::after {
  content: '';
  width: 100px;
  height: 2px;
  background: linear-gradient(135deg, #58a3e7 0%, #25ba6c 100%);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.badge-container {
  position: absolute;
  top: -5px;
  right: 19px;
}

@media (min-width: 576px) {
  .menu-item {
    padding: 10px 15px;
  }
  .menu-item-text {
    font-size: 16px;
  }
  .menu-item-text.active::after {
    content: '';
    width: 110px;
  }
}

@media (min-width: 992px) {
  .menu {
    justify-content: flex-start;
  }
}

@media (min-width: 1188px) {
  .menu {
    margin-right: auto;
    margin-left: auto;
    max-width: 1188px;
  }
}

@media (min-width: 1570px) {
  .menu {
    max-width: 1570px;
  }
}
