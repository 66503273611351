.main-container {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: rgba(30, 178, 169, 255);
  color: #ffffff;
}

.section-title {
  font-size: var(--section-title);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin-bottom: 16px;
}

.section-text {
  font-size: var(--section-text);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 16px;
}

/* TOP SECTION */
.top-section,
.middle-section {
  margin-bottom: 32px;
}

/* MIDDLE SECTION */
.middle-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  min-height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  --title-font-size: 16px;
  --title-font-weight: 500;
}

.session-container {
  background-color: white;
  padding: 20px;
  padding-top: 40px;
  max-width: 768px;
  color: black;
  border-radius: 20px;
  position: relative;
  margin: 0 10px;
  text-align: center;
}

.group-name {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #64a9e8;
}

.session-name {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.about-session {
  color: #64a9e8;
  font-weight: 300;
  font-size: 18px;
}

.session-desc {
  font-size: 16px;
  margin-bottom: 0;
}

.host-info {
  position: absolute;
  padding: 15px;

  background-color: white;
  border-radius: 20px;
  border: 5px solid #ffb72f;
  left: 50%;
  bottom: 0;
  transform: translate(-53%, 80%);
  display: none;
  width: 370px;
  overflow: hidden;
}

.featured-badge {
  position: absolute;
  width: 150px;
  top: -2px;
  right: -32px;
}

/* BOTTOM SECTION */
.bottom-section {
}

.faces-row {
  text-align: center;
}

.faces-row > div {
  margin: 16px auto;
}

.button-container{
  text-align: center;
  margin-top: 16px;
}

@media (min-width: 768px) {
  .faces-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .faces-row > div {
    margin: 16px;
  }
}

@media (min-width: 992px) {
  .main-container {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  .section-text {
    margin-bottom: 32px;
  }

  .top-section,
  .middle-section {
    margin-bottom: 64px;
  }

  .middle-section {
    min-height: 600px;
  }

  .session-container {
    padding: 40px;
    max-width: 960px;
    text-align: left;
  }
  .has-host {
    margin-bottom: 100px;
  }

  .group-name {
    font-size: 20px;
  }

  .session-name {
    font-size: 26px;
  }

  .session-desc {
    font-size: 20px;
  }

  .featured-badge {
    width: 200px;
    top: 15px;
  }
  .button-container{
    margin-top: 32px;
  }

}

@media (min-width: 1200px) {
  .has-host {
    padding-right: 180px;
    margin-bottom: 100px;
    margin-left: -100px;
  }

  .host-info {
    right: 0;
    bottom: 0;
    left: auto;
    transform: translate(53%, 49%);
    display: block;
  }
}
