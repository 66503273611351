

.btn-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 16px
}


.textarea{
  min-height: 75px;
}