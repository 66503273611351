.main {
  padding-bottom: 32px;
}

.container {
  padding: 5px 10px;
  position: relative;
  margin: 0 auto;
}

.team-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.no-link {
  pointer-events: none;
}

.team-member {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  border-bottom: 2px solid #ebebe5;

  padding: 0 10px 16px;
}

.team-member:hover .member-image {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.member-image {
  height: 125px;
  width: 125px;
  border-radius: 100%;
  margin-right: 25px;
  transition: 0.2s;
}

.member-name {
  color: #191e25;
  margin-bottom: 7px;
  font-size: 24px;
  font-weight: 600;
  font-size: 21px;
  letter-spacing: 0.11em;
  line-height: 1.3em;
  text-transform: uppercase;
}

.member-title {
  font-size: 14px;
  color: #d68231;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.11em;
  line-height: 1.3em;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .team-member {
    flex-basis: 50%;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 20px;
    border: none;
    text-align: center;
  }

  .member-image {
    height: 235px;
    width: 235px;
    margin-right: 0;
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .main {
    padding-bottom: 64px;
  }
  .team-member {
    flex-basis: 33.33333%;
    margin-bottom: 64px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* ****************************** */
/*ABOUT US*/
.letter-ceo-container {
  padding: 64px 0;
}
.letter-ceo {
  position: relative;
  margin: 0 15px;
  background-color: white;
  max-width: 940px;
  padding: 30px;
  border-radius: 20px;
  background: white;
}
.section-title {
  color: #151519;
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin-bottom: 35px;
}
.letter-ceo p {
  color: #151519;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 35px;
}

.signature-container {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.community-leader-image {
  float: left;
  width: 145px;
  margin-right: 1rem;
  border-radius: 10px;
}

.community-leader-signature {
  max-width: 170px;
  padding-bottom: 1rem;
}

.cece-name {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  flex-wrap: wrap;
}
.name {
  flex-basis: 100%;
  color: #151519;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
}

.top-left {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 75px;
  width: 75px;
}

.bottom-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 75px;
  width: 75px;
}

@media (min-width: 768px) {
  .community-leader-signature {
    max-width: 200px;
  }

  .letter-ceo p,
  .name {
    font-size: 18px;
  }

  .top-left {
    top: 15px;
    left: 15px;
    height: 100px;
    width: 100px;
  }

  .bottom-right {
    bottom: 15px;
    right: 15px;
    height: 100px;
    width: 100px;
  }
}

@media (min-width: 992px) {
  .letter-ceo {
    margin: 0 auto;
    background-color: white;
    max-width: 940px;
    padding: 60px;
  }
}
