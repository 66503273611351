.pre-join-screen {
  position: relative;
  padding: 15px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;

  flex-wrap: wrap;
  width: 100%;
}

.left-side {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  color: white;
  flex-wrap: wrap;
  /* max-width: 215px; */
  flex-grow: 1;
  flex-basis: 100%;
  border-radius: 20px;
  flex-direction: column;
  padding: 20px 0;
  display: none;
}

.logo {
  height: 75px;
  margin-bottom: 5px;
}

.left-side-text {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.right-side {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  padding: 20px;
  flex-shrink: 1;
  width: 100%;
}

.video-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.errors-container {
  flex-basis: 100%;
}

.error-text {
  font-size: 16px;
  color: var(--color-error);
  text-align: center;
  margin-bottom: 10px;
}

.title-container {
  flex-basis: 100%;
}

.session-title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  overflow-wrap: anywhere;
  word-break: break-word;
}

.options-container {
  margin-top: 20px;
  display: flex;
  flex-basis: 100%;
  justify-content: center;
}

.device-settings {
  margin-right: 10px !important;
  background: linear-gradient(to bottom, #87bdee 0%, #3e94e3 100%);
  color: white !important;
}

.join-room {
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  color: white !important;
}

.disabled {
  opacity: 0.5;
}

@media (min-width: 576px) {
  .video-container {
    width: 400px;
  }
}

@media (min-width: 768px) {
  .pre-join-screen {
    width: 700px;
    flex-wrap: nowrap;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }

  .left-side {
    max-width: 215px;
    width: 215px;
    display: flex;
  }

  .error-text {
    text-align: left;
  }

  .session-title {
    text-align: left;
  }

  .options-container {
    justify-content: flex-start;
  }
}
