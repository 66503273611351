.alternating-columns-new {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.section-title {
  font-size: var(--section-title);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin-bottom: 2rem;
  color: #64a9e8;
}

.section-subtitle {
  font-size: var(--section-subtitle);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
  margin-bottom: 0.5rem;
  text-align: center;
}

.section-text {
  font-size: var(--section-text);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  display: flex;
}

.alternating-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3rem;
  text-align: center;
}
.alternating-columns-title {
  font-size: var(--section-subtitle);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 1.5rem;
}

.alternating-columns-text {
  font-size: var(--section-text);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  display: flex;
  align-items: flex-start;
}

.how-it-works-container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-it-works-link {
  width: 200px;
  height: 40px;
  font-size: 17px !important;
}

.green-check {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .green-check {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .section-title {
    margin-bottom: 16px;
  }

  .alternating-columns {
    text-align: left;
    padding-top: 3rem;
    padding-bottom: 0;
  }

  .alternating-columns-title {
    position: relative;
    line-height: 38px;
  }

  .green-check {
    width: 50px;
    height: 50px;
  }
}
