.session-create {
  padding-bottom: 32px;
}

.input-grouping {
  max-width: 900px;
  margin: 16px auto;
  padding: 16px 10px;
}

.input-grouping h2 {
  color: black;
  font-size: 24px;
}

.disabled-notice {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.textarea {
  width: 100%;
  border: none;
  min-height: 150px;
}

.deactivated {
  opacity: 0.5;
  pointer-events: none;
}

.double-inputs > div {
  margin-right: 15px;
}

.btn-container {
  max-width: 900px;
  margin: 25px auto 25px;
  align-items: center;
  display: flex;
  padding: 0 10px;
}

.continue-btn {
  height: 35px;
  width: 175px;
}

.modal-btn {
  padding: 5px;
  min-width: 150px;
  height: 40px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.modal-title {
  text-align: center;
  font-weight: 400;
}

@media (min-width: 992px) {
  .double-inputs {
    display: flex;
  }
}
