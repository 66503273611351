.ribbon {
  width: 24px;
  height: 28px;
  background-color: var(--color-primary);
  position: absolute;
  right: 25px;
  top: 0;
  animation: drop forwards 0.8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.ribbon::before {
  content: '';
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: -12px;
  border-left: 12px solid var(--color-primary);;
  border-right: 12px solid var(--color-primary);;
  border-bottom: 12px solid transparent;
}


@media (min-width: 576px) {
    .ribbon{
    height: 37px;
    right: 50px;
  }
}