.sessions-menu-container {
  position: relative;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  z-index: 1;
  color: white;
  width: 100% !important;
  text-align: center;
  padding: 3rem 0;
}

.title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin-bottom: 16px;
}
.subtitle {
  display: none;
  margin-bottom: 26px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.header-link,
.header-link:hover {
  color: white;
  text-decoration: underline;
}

.wave-svg {
  display: inline-block;
  position: absolute;
  bottom: -1px;
  left: 0;
  stroke: none;
  fill: rgba(248, 247, 243, 255);
  height: 35px;
}

.sessions-search-container {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
}

.my-sessions-btn {
  height: 38px;
  width: 150px;
  border-radius: 8px;
  margin-left: 10px;
}

.image-container{
  text-align: center;
  margin-bottom: 16px;
}

.image{
  max-width: 250px;
  margin: 0 auto;
}

.modal-text{
  text-align: center;
}

@media (min-width: 768px) {
  .sessions-menu-container {
    height: 300px;
  }

  .title {
    font-size: 30px;
  }
  .subtitle {
    font-size: 18px;
    display: block;
  }
  .wave-svg {
    height: 60px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1188px) {
}

@media (min-width: 1570px) {
}
