.attendee-item {
  align-items: center;
  text-align: center;
  margin-bottom: 0.75rem;
  padding: 10px;
  position: relative;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 75px;
  width: 130px;
  margin: 4px;
  cursor: default;
  pointer-events: none;
}

.can-click,
.attendee-item.center {
  position: relative;
  box-shadow: 0.0225rem 0.0225rem 0.225rem hsl(0deg 0% 55% / 40%);
  cursor: pointer;
  height: 115px;
  pointer-events: auto;
}

.can-click:hover,
.attendee-item.center:hover {
  text-decoration: none;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
}

.attendee-item.center {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-image {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: relative;
  margin: 0 auto;
}

.plus-sign-container {
  background-color: #b8dcfc;
  border-radius: 50%;
  height: var(--user-image-size);
  width: var(--user-image-size);
  cursor: pointer !important;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.plus-sign {
  width: 25px;
}

.attendee-name {
  color: #000000;
  font-size: 16px;

  font-weight: 500;

  max-width: 150px;
  margin: 0.5rem auto 0;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  text-align: center;
}

.blur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.register-text {
  font-size: 10px;
  color: white;
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
