/*Password Reset*/
.password-reset {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: rgba(220, 216, 197, 0.2);
  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));
}
.password-reset-row {
  position: relative;
  max-width: 860px;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);

  margin-left: 15px;
  margin-right: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.back-arrow {
  font-size: 40px;
  position: absolute;
  left: 10px;
  top: -3px;
  cursor: pointer;
  transition: left 0.26s ease;
}

.back-arrow:hover {
  left: 5px;
}

.image-container,
.reset-form-item {
  flex-basis: 100%;
  text-align: center;
  display: inline-block;
}

.image-container {
  margin-bottom: 16px;
}
.page-notice {
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  max-width: 528px;
  margin: 0 auto 16px;
}

.password-reset-logo {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
}

.reset-form-item > div {
  margin-right: auto;
  margin-left: auto;
}

.submit-btn {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 148px;
  height: 38px;
  font-size: 18px;
}

@media (min-width: 768px) {
  .password-reset {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 860px) {
  .password-reset-row {
    margin-left: auto;
    margin-right: auto;
  }
}
