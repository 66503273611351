.container {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: rgba(220, 216, 197, 0.2);
  min-height: 100vw;
}

.inside {
  position: relative;
  max-width: 860px;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);

  margin-left: 15px;
  margin-right: 15px;
}

.image {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.inside h2 {
  font-size: 18px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .inside {
    margin: 15px auto;
  }

  .inside h2 {
    font-size: 22px;
  }
}
