.loader-container {
  position: relative;
  height: calc(100vh - var(--top-nav-height));
}

.container {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 768px;
  padding-top: 32px;
  position: relative;
}

.title {
  font-size: 28px;
  margin: 0 0 0.55em;
  color: #333;
  font-weight: 400;
}

.billing-section {
  border-top: 1px solid #999;
  padding-bottom: 32px;
}

.top {
  margin-right: 25px;
}

.section-title {
  font-size: 20px;
  color: #757575;
  font-weight: 400;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.item {
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.item h4 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}

.main {
  font-weight: 700;
}

.update-link {
  color: #3e72e3;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
.update-link:hover {
  text-decoration: underline;
  color: #3ea1e3;
}

.cancel-container {
  position: absolute;
  right: 5px;
  top: 57px;
}

.cancel-btn {
  padding: 3px;
  cursor: pointer;
  font-size: 14px;
  color: grey;
}

.cancel-btn:hover {
  text-decoration: underline;
}

.table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.table td,
.table th {
  padding: 8px;
  border: 1px solid rgba(74, 74, 83, 0.5);
}

.table td {
  font-size: 14px;
}

.table thead th {
  color: #ffffff;
  background: #4fc3a1;
}

.table thead th:nth-child(odd) {
  color: #ffffff;
  background: #324960;
}

.table tr:nth-child(even) {
  background: #f8f8f8;
}

.red{
  color: var(--color-error);
  font-weight: 600;
}

@media (min-width: 768px) {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

