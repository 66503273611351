/*START STATISTICS*/
.statistics {
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  color: white;
  padding: 2rem 0;
}
.stats-item-new {
  text-align: center;
  width: 100%;
}
.statistics-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.statistics-row > h2,
.statistics-row > h3 {
  flex-basis: 100%;
}

.line-one {
  margin-bottom: 2px;
  font-size: var(--section-subtitle);
  font-weight: bold;
}
.line-two {
  margin-bottom: 20px;
  font-size: var(--banner-text);
  font-weight: 300;
}

.stat-graphic-container {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 20px;
}

.signup-today{
  position: relative;
}

.ribbon{
  position: absolute;
  top: 0;

}

@media (min-width: 768px) {
  .statistics {
    padding: 2rem 0;
  }
  .stats-item {
    margin: 0;
  }
}

/*START SIGNUP TODAY*/
.signup-today {
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  padding: 4rem 0;
}

.signup-row {
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.signup-title {
  font-size: var(--section-title);
  font-weight: bold;
  letter-spacing: 0;
  flex-basis: 100%;
  margin-bottom: 16px;
}

.signup-text {
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  flex-basis: 100%;
  font-size: 20px;
}

.signup-btn {
  margin-top: 2rem;
  font-size: 18px !important;
  font-weight: bold !important;
}

@media (min-width: 992px) {
  .signup-text {
    font-size: 24px;
  }
}
