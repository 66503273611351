.container-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.repeat-text {
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}

.deactive {
  pointer-events: none;
  opacity: 0.5;
}
