.container {
  display: flex;
  padding: 2.30469rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.92188rem;
  border-radius: 1.7285rem;
  background: #fff;
  box-shadow:
    0px 3.6875px 14.75px 0px rgba(134, 132, 152, 0.09),
    0px 0px 10.140625px 0px rgba(205, 205, 205, 0.18),
    0px 16.59375px 55.3125px 0px rgba(41, 44, 69, 0.07);
}

.container.featured {
  display: flex;
  padding: 2.21981rem;
  gap: 1.10994rem;
  border-radius: 1.66488rem;
  background: linear-gradient(180deg, #798df9 0%, #485bc7 100%);
  box-shadow:
    0px 3.5517239570617676px 14.20689582824707px 0px rgba(134, 132, 152, 0.09),
    0px 0px 9.767240524291992px 0px rgba(205, 205, 205, 0.18),
    0px 15.982757568359375px 53.27585983276367px 0px rgba(41, 44, 69, 0.07);
  color: white;
}

.loading {
  background: #ddd;
  width: 22rem;
  height: 24rem;
}

.title {
  color: #4a4a52;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.38281rem; /* 133.333% */
  letter-spacing: 0.072rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

.featured .title {
  color: white;
  font-variant-numeric: stacked-fractions;
  font-feature-settings:
    'cv09' on,
    'cv04' on,
    'ss01' on,
    'cv03' on,
    'ss03' on,
    'cv06' on;
}

.divider {
  width: 100%;
  height: 0.05rem;
  background: #687ceb;
}

.featured .divider {
  background: white;
}

.pricing-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.price {
  margin-bottom: 0;
  color: #292b2e;
  font-size: 2.53519rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.45rem; /* 136.364% */
  letter-spacing: -0.038rem;
}

.featured .price {
  color: white;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'cpsp' on,
    'cv06' on,
    'cv09' on,
    'cv04' on,
    'cv03' on,
    'case' on,
    'ss03' on;
  font-size: 2.4rem;
  letter-spacing: -0.03663rem;
}

.price-divider {
  background: #e5e7eb;
  width: 0.0625rem;
  height: 1rem;
}

.featured .price-divider {
  background: #fff;
}

.year-price {
  color: #292b2e;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.61331rem; /* 175% */
  letter-spacing: -0.01038rem;
}

.featured .year-price {
  color: white;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.61331rem; /* 175% */
  letter-spacing: -0.01038rem;
}

.subtitle {
  margin-bottom: 0;
  color: white;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.33rem; /* 120% */
}

.text {
  color: #4a4a52;
  font-size: 0.80663rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38281rem; /* 171.429% */
  letter-spacing: -0.005rem;
  margin-bottom: 0;
}

.featured .text {
  color: #e5e7eb;
  font-size: 1rem;
}

.cancel-container {
  margin: 0.75rem 0;
  display: flex;
  align-items: center;
}

.cancel {
  color: #4a4a52;
  text-align: center;
  font-size: 0.80663rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38rem; /* 171.429% */
  letter-spacing: -0.0052rem;
}

.featured .cancel {
  color: #f5f5f7;
}

.button {
  margin: auto;
}

@media (min-width: 776px) {
  .container {
    display: flex;
    padding: 2.5rem;
    gap: 1rem;
    border-radius: 1.875rem;
    max-width: 22rem;
  }

  .featured.container {
    /* max-width: 22.8rem; --original */
    max-width: 25rem;
    min-height: 450px;
  }

  .title {
    font-size: 1.125rem;
    line-height: 1.5rem; /* 133.333% */
  }

  .price {
    font-size: 2.75rem;
    line-height: 3.75rem; /* 136.364% */
    letter-spacing: -0.04125rem;
  }

  .featured .price {
    font-size: 2.95rem;
  }

  .year-price {
    font-size: 1rem;
    line-height: 1.75rem; /* 175% */
    letter-spacing: -0.01125rem;
  }

  .featured .year-price {
    /* font-size: 0.875rem; */
    font-size: 1rem;
  }

  .subtitle {
    font-size: 1.25rem;
    line-height: 1.5rem; /* 120% */
  }

  .text {
    font-size: 0.875rem;
    line-height: 1.5rem; /* 171.429% */
    letter-spacing: -0.00563rem;
  }

  .cancel {
    font-size: 0.875rem;
    line-height: 1.5rem; /* 171.429% */
    letter-spacing: -0.00563rem;
  }
}
