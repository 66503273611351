/* START SESSION ITEM */
.session-item {
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  color: #392f2f;
  background: white;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  transition: box-shadow 0.1s ease-in, color 0.1s ease-in;
  width: 290px;
  margin-bottom: 15px;
}

.session-item a,
.session-item a:hover {
  color: #392f2f;
}

.session-item:hover {
  -webkit-box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: #392f2f;
}

.image-container {
  width: 100%;
  position: relative;
  height: 75px;
}
.image {
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
}

.image-placeholder {
  border-radius: 10px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  height: 100%;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
}

.image-container::before {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.02;
  background-color: black;
  position: absolute;
  border-radius: 10px;
  z-index: 0;
}

.bottom {
  position: relative;
  height: 85px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.session-time-container {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  color: #6e7680;
}

.in-session {
  color: var(--color-error) !important;
}

.time-divider {
  /* font-size: 12px; */
  padding: 0 6px;
}

.session-item-title {
  font-size: 16px;
  font-weight: 600;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  position: relative;
  z-index: 2;
  margin-bottom: 0;
  word-break: break-word;
}

.status-container {
  position: absolute;
  left: 8px;
  top: -15px;
}

.register-button {
  position: absolute;
  z-index: 2;
  background: linear-gradient(to right, #fc9e43 0%, #ffb62f 100%);
  cursor: pointer;
  transition-duration: 600ms;
  transition: all 0.2s ease-in-out;
  right: 10px;
  top: 10px;
  border-radius: 10px;
  font-weight: 600;
  padding: 5px 8px;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  color: white;
  min-width: 95px;
  min-height: 34px;
  font-size: 14px;
}

.register-button:hover {
  transform: translate(1px, -1px);
}

.bonus-badge{
  position: absolute;
  right: -2px;
  top: -2px;
  z-index: 2;
  width: 90px;
}

@media (min-width: 768px) {
  .status-container {
    padding: 3px 5px;
  }
}

/* Empty */
/* EMPTY ITEM */
.session-item-empty {
  background-color: #e5e8ee;
  background: none;
  color: #4d5461;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  border-radius: 10px;
  width: 290px;
  height: 160px;
  overflow: hidden;
  margin-bottom: 15px;
}

.session-item-empty:hover {
  background-color: #c6cbd3;
  color: black;
}

.plus-sign {
  font-size: 28px;
}

.no-create {
  opacity: 0.7;
  pointer-events: none;
}
