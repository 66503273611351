.react-switch-checkbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}

.react-switch {
  cursor: pointer;
  border-radius: 10px;
  width: 55px;
  height: 20px;
  background: #656665;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch .react-switch-button {
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 2px;
  width: 17px;
  height: 17px;
  border-radius: 17px;
  transition: 0.2s;
  background: #cfcfcf;
  box-shadow: 0 0 2px 0, rgba(10 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-checkbox:active + .react-switch-button {
  width: 12px;
}

.react-switch-labels {
  display: relative;
  z-index: 0;
  height: 100%;
  color: white;
  font-size: 9px;
}

.react-switch-labels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
}
.react-switch-labels span:first-child {
  left: 0;
}
.react-switch-labels span:last-child {
  right: 0;
}

.label {
  display: block !important;
  margin-bottom: 0 !important;
  position: relative;
  top: -1.5px;
  font-size: 16px;
  cursor: pointer;
}
