/*TIMER*/
.session-timer-container {
  position: fixed;
  left: 50%;
  color: white !important;
  top: 14px;
  font-weight: 500;
  font-size: 1.1rem;
  background-color: #70b5f4;
  height: 45px;
  width: 240px;
  z-index: 10;
  transform: translateX(-50%);
  opacity: 0.9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  z-index: 50;
}

.overrun {
  background-color: #f47f70 !important;
}

.ended-notice {
  margin-bottom: 0;
}

.close-session-timer {
  font-size: 13px;
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  transform: translateY(-50%);
}

@media (min-width: 992px) {
}
