html,
body {
  height: 100%;
  position: relative;
}

body {
  font-family: 'Arimo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  color: #151519;
  background-color: rgba(248, 247, 243, 255);
}

:global(#app) {
  height: 100%;
}

/*FONTS, WEIGHTS, AND SIZES*/
:global(.sharewell-logo-text) {
  font-family: 'Arimo', sans-serif;
  font-weight: 500;
}
body p,
label,
body ul li {
  font-family: 'Arimo', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
}
body h1 {
  font-family: 'Arimo', sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}
body h2 {
  font-family: 'Arimo', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0;
}
body h3 {
  font-family: 'Arimo', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}
body h4 {
  font-family: 'Arimo', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #3e72e3;
  font-weight: 600;
}
a:hover {
  text-decoration: none;
  color: #3ea1e3;
}

@media (min-width: 992px) {
  /*FONTS, WEIGHTS, AND SIZES*/
  body p {
    font-size: 1rem;
  }
  body ul li {
    font-size: 1rem;
  }
  body h1 {
    font-size: 2rem;
  }
  body h2 {
    font-size: 2rem;
  }
  body h3 {
    font-size: 1.75rem;
  }
  body h4 {
    font-size: 1.35rem;
  }
}

/*Images*/
img {
  width: 100%;
}

b {
  font-weight: bold;
}

/* GLOBAL CSS VARIABLES */
:global(:root) {
  /*--color-primary: #70B5F4;*/
  --color-primary: rgb(112, 181, 244);
  --color-primary-light: #87bdee;
  --color-primary-dark: #3e94e3;

  --color-secondary: #f5f5f5;
  --top-nav-height: 75px;
  --footer-height: 76px;

  --color-information: #575757;

  /* notices */
  --color-error: #dc3545;
  --color-success: #50d56d;

  --link-color: #3e72e3;

  --page-height: calc(100vh - var(--top-nav-height));
  --page-min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));

  --text-color-blue: #69ace9;
}

:global(.router-container) {
  position: relative;
}

:global(.darkened) {
  pointer-events: none;
  background: rgba(15, 23, 42, 0.1);
  /*position: fixed;*/
  position: relative;
  opacity: 0.9;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 5;
  pointer-events: none;
}

:global(.darkened)::after {
  content: '';
  pointer-events: none;
  background: rgba(15, 23, 42, 0.8);
  position: absolute;
  opacity: 0.9;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 30;
  height: 100%;
  width: 100%;
}

:global(.page-content) {
  position: relative;
  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));
}

:global(.standard-disabled) {
  opacity: 0.65;
  pointer-events: none;
}

/*BOOTSTRAP OVERRIDE*/
:global(.row) {
  margin-left: 0;
  margin-right: 0;
}

:global(.form-control:disabled),
:global(.form-control[readonly]) {
  opacity: 0.6;
  background-color: white;
}

@media (min-width: 1440px) {
  :global(.col-xxl-4) {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 768px) {
  :global(:root) {
    --page-min-height: calc(100vh - var(--top-nav-height));
  }

  :global(.page-content) {
    min-height: calc(100vh - var(--top-nav-height));
  }

  :global(.well-form-group) {
    min-width: 285px;
    max-width: 400px;
  }
}

@media (min-width: 992px) {
  :global(.page-content) {
    padding-top: var(--top-nav-height);
    min-height: 100vh;
  }
  :global(.no-header-menu) {
    padding-top: 0;
  }
}

/*ANIMATIONS*/
:global(.blink) {
  animation: blink-animation 1.75s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.75s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@keyframes play-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:global(.shimmer) {
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

@-webkit-keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

/* Want to remove */
:global(.toastr) {
  min-width: 350px !important;
  min-height: 75px !important;
  border-radius: 10px !important;
  opacity: 0.99 !important;
}

:global(.redux-toastr .top-center) {
  width: auto !important;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0 !important;
}
:global(.rrt-middle-container) {
  text-align: center !important;
  width: 100% !important;
  margin-left: 0 !important;
  padding: 22px 50px !important;
}

:global(.rrt-message) :global(.rrt-text) {
  text-align: center;
}

:global(.rrt-left-container) {
  display: none;
}

:global(.redux-toastr .toastr .close-toastr) {
  opacity: 1 !important;
}
:global(.redux-toastr .toastr .close-toastr span) {
  right: -8px !important;
  top: -5px !important;
  transform: none !important;
  color: white;
  font-weight: bold;
}
:global(.redux-toastr .toastr.rrt-success) {
  background-color: #50d56d !important;
}
:global(.redux-toastr .toastr.rrt-error) {
  background-color: #d55750 !important;
}

/* Want to remove this */

/*SWIPER OVERRIDES*/
:global(.swiper-button-prev),
:global(.swiper-button-next) {
  width: 30px !important;
  height: 30px !important;
  background: linear-gradient(180deg, #fc9e43 0%, #ffb62f 100%) !important;
  border-radius: 50%;
}

:global(.swiper-button-prev)::after,
:global(.swiper-button-next)::after {
  color: white;
  font-size: 20px !important;
  font-weight: 900;
  position: relative;
}
:global(.swiper-button-next)::after {
  right: -2px;
}
:global(.swiper-button-prev)::after {
  left: -2px;
}

/* FORMS SHOULD REMOVE */
:global(.well-form-row) {
  justify-content: center;
  margin: 0.25rem 0;
}
:global(.well-form-group) {
  text-align: left;
}

:global(.well-form-group-no-max) {
  max-width: none !important;
}

:global(.well-form-label) {
  font-weight: 400;
  margin: 0px;
  padding-left: 4px;
  padding-bottom: 4px;
  text-align: left;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: rgb(104, 118, 141);
}

:global(.well-form-item-container) {
  flex: 0 0 auto;
  -webkit-box-pack: initial;
  justify-content: initial;
  align-self: initial;
  border-radius: 5px;
  border: 1px solid rgb(117, 138, 174);
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

:global(.well-form-item-container) input,
:global(.well-form-item-container) select {
  border-radius: 10px;
  height: 45px;
}

:global(.well-form-textarea-container) {
  min-height: 100px;
  border-radius: 10px;
}

:global(.well-form-item) {
  display: inline-block;
  border: none;
  width: 100%;
}

:global(.well-form-textarea) {
  min-height: 100px;
  /*height: 100% !important;  */
  border-radius: 10px;
}

:global(.form-errors) {
  color: #721c24;
}

:global(.form-input-error) {
  border: 1px solid var(--color-error) !important;
}
:global(.form-error-msg) {
  color: var(--color-error);
  height: 0;
  float: left;
  font-size: 0.75rem;
  font-weight: 400;
  position: relative;
  left: 5px;
  margin-bottom: 2px;
}

:global(.rdt) {
  width: 100%;
}
