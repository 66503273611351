.upcoming-session-banner {
  width: 100%;
  background: linear-gradient(to left, #f07592, #d94970);
  cursor: pointer;
  z-index: 5;
  opacity: 0.75;
  position: relative;
}

.upcoming-session-banner-container {
  padding: 0.5rem 1rem;
  min-height: 55px;
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
  top: 4px;
}

.starting-notice {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0;
  color: white;
}

.btn {
  margin: 0 auto;
  padding: 10px 25px !important;
  font-weight: 600;
  letter-spacing: 0.8px;
  min-width: 8rem !important;
  height: 40px !important;
  margin-right: 0.5rem;
  display: none !important;
}

.arrow-container {
  margin-left: 10px;
  position: relative;
  right: 17px;
  transition: right 0.2s ease-out;
}

.arrow {
  cursor: pointer;
  width: 30px;
}
.upcoming-session-banner:hover .arrow-container {
  right: -1px;
  color: red;
}

.loading {
  color: white;
  margin-left: 30px;
}
.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0s;
  animation: dot 1.3s infinite;
  animation-delay: 0s;
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@-webkit-keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .loading {
    position: relative;
    top: 4px;
  }
}

@media (min-width: 992px) {
  .upcoming-session-banner-container {
    max-width: none !important;
  }
  .arrow-container {
    padding-right: 15px;
  }
  .loading {
    right: 17px;
  }
}
