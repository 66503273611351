.profile-page {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.form-container {
  position: relative;
  max-width: 860px;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);

  margin-left: 15px;
  margin-right: 15px;
}

.user-image-container {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.user-image-container::before {
  content: '';
  display: inline-block;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 132px;
  width: 132px;
}

.user-image {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  cursor: pointer;
  z-index: 2;
  position: relative;
  left: -1px;
}
.user-image:hover {
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
}
.user-image-is-loading {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  position: relative;
  border: 2px solid rgba(112, 181, 244, 0.5);
  margin-right: auto;
  margin-left: auto;
}
.user-image-change-notice {
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  font-size: 0.65rem;
  color: white;
  z-index: 2;
  pointer-events: none;
}

.password-reset {
  font-size: 12px;
  opacity: 0.75;
  right: 5px;
  position: relative;
}

.hidden-upload {
  display: none;
}

.inputs-container input:read-only {
  cursor: default;
  outline: none;
  background-color: #f8f8f8 !important;
}

.checkbox-container {
  margin-top: 16px;
}

.btn-container {
  margin-top: 16px;
}

.left-input {
  margin: 10px 0;
}

.checkbox-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.delete-account {
  font-size: 14px;
  color: var(--color-error);
  opacity: 0.9;
  cursor: pointer;
  display: inline-block;
}

.past-sessions {
  font-size: 16px;
  color: var(--color-primary);
  opacity: 0.9;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
}

.modal-title {
  font-weight: 500;
  text-align: center;
  font-size: 22px;
}

.delete-btn {
  width: 150px;
  height: 40px;
}

.error-container {
  font-size: normal;
}

@media (min-width: 768px) {
  .profile-page {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .account-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-container {
    margin-left: auto;
    margin-right: auto;
  }

  .inputs-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 10px;
  }

  .input {
    width: 300px;
  }
  .left-input {
    text-align: left;
    position: relative;
    left: 69px;
  }
}

@media (min-width: 992px) {
  .user-image-container::before {
    height: 152px;
    width: 152px;
  }
}
