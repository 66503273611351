.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 13;
}

.modal-container {
  position: fixed;
  /* top: 48%; */
  /* transform: translate(-50%, -50%); */
  top: calc(var(--top-nav-height) + 20px);
  transform: translateX(-50%);
  left: 50%;
  background-color: #fff;
  padding: 10px;
  z-index: 14;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px,
    rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  width: 95%;
  max-width: 578px;
  max-height: calc(100vh - var(--top-nav-height) - 10px);
  overflow-y: auto;
}

.close-modal {
  font-size: 20px;
  position: absolute;
  right: 0;
  top: -10px;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
}

.close-modal:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.body-wrapper {
  color: #424242;
  /* color: rgba(131, 131, 131, 255); */
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 16px;
  width: 100%;
  pointer-events: all;
}

@media (min-width: 576px) {
  .modal-container {
    width: 98%;
  }
}

@media (min-width: 768px) {
  .modal-container {
    width: 100%;
    padding: 20px;
  }
}
