.badge-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
}
.badge-item span{
  color: #9398A0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.badge {
  width: 50px;
}