.well-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  min-height: 55px;
  background-color: var(--color-primary);
  font-size: 17px;
  line-height: 1.3;
  padding: 0 5px;
  font-style: italic;
  z-index: 2;
  position: relative;
  font-weight: 600;
}

a.well-banner {
  text-decoration: underline;
}

a.well-banner:hover {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

.well-banner-text {
  margin-bottom: 0;
  font-style: italic;
}

.banner-link {
  text-decoration: underline;
  cursor: pointer;
}
.special-break {
  display: none;
}

.hide-banner-btn {
  position: absolute;
  right: 20px;
  top: 48%;
  transform: translateY(-50%);
  font-size: 20px;
  z-index: 7;
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: none;
}

.hidden {
  display: none !important;
}

@media (min-width: 992px) {
  .well-banner-text {
    padding: 5px 2rem;
  }
  .well-banner-text {
    font-size: 18px;
  }
  .special-break {
    display: block;
  }

  .hide-banner-btn {
    display: block;
  }
}
