.container {
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.sessions {
  background-color: #fff1d5;
}

.peers {
  background-color: #ccebff;
}

.divider {
  height: 1px;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 10px;
  opacity: 0.4;
}

.sessions .divider {
  background-color: #f9b731;
}

.peers .divider {
  background-color: #5FA2CD;
}

/* Top */
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #151519;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.number {
  color: #151519;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 47px;
  position: relative;
  top: -5px;
}

/* Middle */
.middle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}

.cur-badge-container {
  border-radius: 100%;
  padding: 3px;
  margin-right: 10px;
  width: 60px;
}

.badge-middle {
  width: 82%;
  height: auto;
}

.middle-text {
}

.middle-text h4 {
  color: #151519;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}
.middle-text h5 {
  color: #9398a0;
  font-size: 12px;
  font-weight: 500;
}

/* Bottom */
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.badge-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0.5;
}

.badge-item.active {
  opacity: 1;
}

.badge-bottom {
  width: 40px;
  margin-bottom: 5px;
}

.badge-item h4 {
  color: #151519;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.badge-item h5 {
  color: #9398a0;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

/* Circe  */
.circular-chart {
  display: block;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  /* stroke: #eee; */
  stroke: white;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.sessions .circle {
  stroke: #f9b731;
}

.peers .circle {
  stroke: #5fa2cd;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
