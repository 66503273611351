.input-container {
  max-width: 300px;
  transition: border 0.25s ease-out;
}

.submit-button {
  background: linear-gradient(to bottom, #87bdee 0%, #3e94e3 100%);
  height: 35px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-weight: 500;
  font-size: 14px;
}

.has-error {
  border: 1px solid var(--color-error);
}

.open-input {
  padding: 0.375rem 0rem;
  gap: 0.5rem;
  color: #45C0C7;
  cursor: pointer;
  font-weight: 600;
}
.open-input:hover {
  text-decoration: underline;
}
