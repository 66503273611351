.well-meatballs-container {
  z-index: 2;
  text-align: right;
  font-size: 18px;
  color: white;
  position: relative;
}

.text {
  color: black;
}

.well-meatballs-dropdown {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  top: 35px;
  left: 50%;
  border-radius: 5px;
  text-align: left;
  transform: translateX(-48%);
}

.meatballs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

.well-meatballs-dropdown.open {
  display: block;
}

.well-meatballs-option {
  display: block;
  cursor: pointer;
  color: black;
  font-size: 1rem;
  padding: 7px 10px;
  border-bottom: 1px solid #dcdcdd;
  border-left: 1px solid #dcdcdd;
  border-right: 1px solid #dcdcdd;
  font-weight: 400;
  text-align: center;
}

.well-meatballs-option:hover {
  text-decoration: none;
  background-color: #eaeaea;
}

.well-meatballs-option:first-child {
  border-top: 1px solid #dcdcdd;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.well-meatballs-option:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.disabled-option {
  opacity: 0.6;
  cursor: not-allowed;
}
.disabled-option:hover {
  background-color: white !important;
}

.well-meatballs-option::after,
.well-meatballs-option::before {
  content: '';
  position: absolute;
  z-index: 11;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.well-meatballs-option::before {
  border-bottom: 11px solid #dcdcdd;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  height: 0;
  top: -10.5px;
  width: 0;
}
