.image-container {
  text-align: center;
  margin-bottom: 16px;
}
.image {
  max-width: 200px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.subscribe-button {
  padding: 5px 16px;
}

@media (min-width: 992px) {
  .title {
    font-size: 22px;
  }
}
