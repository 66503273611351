.sessions-date-container {
  padding-top: 1rem;
  padding-left: 0;
  flex-basis: 100%;
  margin: 0 !important;
}
.sessions-date {
  text-align: center;
  color: #151519;
  font-size: 22px;
  font-weight: 400;
}

.no-sessions-notice {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  text-align: center;
}

.session-list-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.session-list-row > div {
  margin: 20px 0;
}

@media (min-width: 768px) {
  .session-list-row {
    justify-content: space-evenly;
  }

  .no-sessions-notice {
    font-size: 2rem;
  }

  .session-list-row > div {
    margin: 20px 20px 20px 0;
  }
}

@media (min-width: 992px) {
  .session-list-row {
    justify-content: normal;
  }

  .sessions-date {
    text-align: left;
    font-size: 24px;
  }
}
