.title {
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}

.boiler-plate {
  text-align: center;
  margin: 16px 0;
  padding: 0 5px;
}

.modal-btn-container {
  text-align: center;
  margin-top: 16px;
}

.modal-btn {
  padding: 10px 20px;
  margin: 0 auto;
}

.is-done {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  text-align: center;
  margin-top: 32px;
}

.is-done-image-container {
  text-align: center;
}

.is-done-image {
  max-width: 300px;
  margin: 16px auto 0;
}

.botton-content {
  margin: 1rem 0;
}

@media (min-width: 576px) {
  .subscriptions-row {
    display: flex;
  }
}

@media (min-width: 768px) {
  .is-done {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .title {
    font-size: 28px;
    margin-bottom: 32px;
  }

  .botton-content {
    margin: 2rem 0;
  }
}
