.how-it-works-container {
  overflow-x: hidden;
}

.how-it-works-container {
  --section-padding: 32px;
  --section-title: 24px;
  --section-title-size: 24px;
  --section-subtitle-size: 22px;
  --section-text-size: 16px;
}

@media (min-width: 992px) {
  .how-it-works-container {
    --section-padding: 64px;

    --section-title: 32px;
    --section-title-size: 32px;
    --section-subtitle-size: 24px;
    --section-text-size: 18px;
  }
}

.how-it-works-container section {
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
}

.how-it-works-container section.white {
  background-color: white;
}

.how-it-works-container section.gradient {
  background: linear-gradient(90deg, #58a3e7 0, #25ba6c);
  color: #fff;
}

.blue {
  color: #1870bf;
}

.white-link {
  color: white !important;
  text-decoration: underline;
}

.centered-content-container,
.centered-content-container-wide {
  text-align: center;
  max-width: 1300px;
  padding: 0 10px;
  margin: 0 auto;
}

.centered-content-container {
  max-width: 1300px;
}

.section-title {
  font-size: var(--section-title-size);
  font-weight: 500;
  margin-bottom: 24px;
  text-align: center;
}

.subsection-title {
  font-size: var(--section-subtitle-size);
  font-weight: 400;
  text-align: center;
  margin: 20px 0;
}

.alternating-columns-row {
  max-width: 1100px;
  padding: 0 10px;
  margin: 0 auto;
}

.alternating-columns-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  /* margin: 20px 0; */
  text-align: left;
}

.item-padding {
  margin: 50px auto;
}

.section-text,
.section-text-large,
.section-text-connected,
.section-text-wide {
  font-size: var(--section-text-size);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0 auto;
  text-align: center;
  max-width: 700px;
}

.section-text {
  margin: 16px auto;
}

.section-text-wide {
  margin: 20px 0;
  max-width: 1400px;
}

.section-text-large {
  font-size: 16px;
  max-width: 1000px;
  margin-bottom: 32px;
}

.what-sessions-like {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.yellow-dot {
  background-color: rgb(252, 181, 46);
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 5px;
}

.like-item {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  padding: 5px 0;
  align-items: center;
  justify-content: center;
}

.slight-right {
  position: relative;
  right: -4px;
}

.view-sessions-btn-container {
  margin-top: 32px;
}

.view-sessions-btn {
  padding: 5px 10px;
}

.user-container {
  text-align: left;
}

.top-button {
  padding: 8px;
  margin-top: 20px;
}

.list-item {
  margin-bottom: 35px;
}
.list-item h4 {
  color: #1870bf;
  font-weight: 400;
  font-size: var(--section-subtitle-size);
  margin-bottom: 2px;
}

.list-item p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.bottom-text {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 0;
}

.membership-item {
  max-width: 450px;
  margin: 0 auto;
}

.membership-image {
  margin-bottom: 8px;
  max-width: 300px;
}

.membership-item > div {
  font-size: 18px;
}

.membership-title {
  font-size: 24px !important;
  margin-bottom: 0;
  font-weight: bold;
}
.membership-price{
  font-size: 22px !important;
  margin-bottom: 0;
  font-weight: 500;
}

.divider{
  width: 225px;
  height: 1px;
  margin: 16px auto;
  background-color: rgba(0, 0, 0, 0.15);
}

.blue {
  font-weight: 600;
  color: #0d83d8;
}

.membership-button {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  width: 275px;
  height: 40px;
  font-size: 18px;
}

.ready-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto 20px;
  position: relative;
  padding-bottom: 75px;
}

.ready-image {
  max-width: 200px;
}

.ready-title {
  font-weight: bold;
  font-size: 22px;
}

.ready-text {
  font-weight: 400;
  margin: 10px auto;
  max-width: 300px;
}

.ready-btn {
  margin-top: 16px;
  padding: 7px 14px;
  position: absolute;
  bottom: 20px;
}

.image-one {
  max-width: 700px;
  margin-bottom: 20px;
}

.image-two,
.image-three,
.image-four {
  max-width: 150px;
}

.image-six,
.image-seven,
.image-eight {
  max-width: 275px;
}

.image-nine {
  max-width: 250px;
}

.image-five,
.image-ten {
  max-width: 400px;
}

@media (min-width: 768px) {
  .users-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .user-container {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
  }

  .membership-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 32px;
    margin-top: 32px;
  }

  .added-height {
    position: relative;
    top: 50px;
  }

  .image-two,
  .image-three,
  .image-four {
    max-width: 250px;
  }

  .image-six,
  .image-seven,
  .image-eight {
    max-width: 350px;
  }

  .image-nine {
    max-width: 400px;
  }

  .image-five,
  .image-ten {
    max-width: 550px;
  }
}

@media (min-width: 992px) {
  .subsection-title {
    text-align: left;
  }

  .section-text {
    max-width: 900px;
  }

  .section-text-large {
    font-size: 20px;
  }

  .alternating-columns-item {
    flex-direction: row-reverse;
    max-width: 1100px;
  }

  .reverse {
    flex-direction: row;
  }

  .limit {
    max-width: 960px;
    margin: 0 auto;
  }

  .alternating-columns-item p {
    text-align: left;
    margin-left: 0;
  }

  .view-sessions-btn-container {
    margin-top: 64px;
  }

  .safe-row {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .list-item {
    max-width: 670px;
  }

  .ready-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-bottom: 32px;
    margin-top: 32px;
  }

  .ready-item {
    margin: 10px 20px;
    height: 350px;
    padding: 20px;
  }

  .image-three,
  .image-six,
  .image-eight {
    margin-right: 30px;
  }

  .image-two,
  .image-four,
  .image-seven {
    margin-left: 30px;
  }

  /* animations */
  .image-two,
  .image-four,
  .image-nine {
    opacity: 0;
    transition-duration: 600ms;
    transition: all 0.75s ease-in-out;
    transform: translateX(75px);
  }

  .image-three {
    opacity: 0;
    transition-duration: 600ms;
    transition: all 0.75s ease-in-out;
    transform: translateX(-75px);
  }

  .image-five,
  .image-six,
  .image-seven,
  .image-eight,
  .ready-item,
  .membership-item {
    opacity: 0;
    transition-duration: 600ms;
    transition: all 0.75s ease-in-out;
    transform: translateY(75px);
  }

  .image-two.animate,
  .image-three.animate,
  .image-four.animate,
  .image-five.animate,
  .image-six.animate,
  .image-seven.animate,
  .image-eight.animate,
  .image-nine.animate,
  .image-ten.animate,
  .ready-item.animate,
  .membership-item.animate {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* *********************************************************************************** */
/* *********************************************************************************** */
/* PARTNERS */
.handshake {
  max-width: 700px;
  margin: 30px auto;
}

.selection {
  max-width: 400px;
  margin: 0 auto 30px;
  background-color: #f0ecec;
  border: 1px solid rgba(255, 194, 204, 0.5);
  box-shadow: 0.0425rem 0.0425rem 0.425rem hsl(0deg 0% 45% / 40%);
  padding: 30px;
}

.selection-top {
  color: black;
  font-weight: 700;
  font-size: 26px;
}

.selection-text {
  font-weight: 500;
  color: #151519;
  font-size: 16px;
}

.thats-me-btn {
  margin-top: 20px;
  width: 125px;
  height: 40px;
}

.download-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  text-decoration: none;
  font-weight: 600;

  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  width: 200px;
  height: 40px;
  font-size: 18px;
  color: #fff;
  background: linear-gradient(90deg, #fc9e43 0, #ffb62f);
  box-shadow: 0 0 6px rgb(0 0 0 / 20%);
}

.download-btn:hover {
  color: white;
}

@media (min-width: 576px) {
  .selection-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .selection {
    margin: 0 auto 30px;
  }
}

@media (min-width: 768px) {
  .selection-text {
    max-width: 300px;
    margin: 0 auto;
  }
}
