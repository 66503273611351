.container {
  position: relative;
  width: 100%;
  margin: 20px 0;
}

.top-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 10px auto;
}

.image-container {
  border-radius: 100%;
  border: 3px solid #69ace9;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  z-index: 2;
  position: relative;
  max-width: 100px;
}

.user-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.right-container {
  width: 100%;
  margin-top: 18px;
}

.username-container {
  padding: 20px;
  background-color: #eeeeee;
  border-radius: 20px;
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  transform: translateX(-15px);
}

.username {
  color: #424242;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0;
  padding-left: 10px;
}

.about-user {
  margin-bottom: 0;
  color: #151519;
  font-size: 18px;
}

.credentials {
  position: relative;
  top: -10px;
}

.credential {
  background: var(--color-primary);
  color: white;
  font-size: 16px;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 10px;
  font-weight: 500;
  position: relative;
  margin-bottom: 5px;
  margin-right: 5px;
}

.about-user {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;

  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-word;
  position: relative;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.no-margin {
  margin-top: 0;
}

.plus-sign-container {
  background-color: #69ace9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 5px solid white;
  width: 86px;
  height: 86px;
}

.plus-sign {
  color: white;
  font-size: 66px;
  position: relative;
  top: -7px;
}

@media (min-width: 768px) {
  .container {
    margin: 10px 15px;
  }
}

@media (min-width: 992px) {
  .image-container {
    max-width: 125px;
  }

  .plus-sign-container {
    width: 115px;
    height: 115px;
  }
}
