.reply-item {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-basis: 100%;
  margin-left: 5px;
  margin-bottom: 25px;
}

.unseen {
  box-shadow: rgb(3 102 214 / 30%) 0px 0px 2px 3px !important;
}

.reply-item-content {
  background-color: #efefef;
  border-radius: 10px;
  padding: 5px 16px 5px 10px;
  position: relative;
  min-width: 180px;
  border-top-right-radius: 0;
}

.reply-item-user {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  color: black;
  margin-bottom: 8px;
}
.reply-item-user:hover {
  text-decoration: underline;
  color: black;
}
.edited {
  position: relative;
  font-size: 13px;
  font-weight: 400;
  margin-left: 3px;
}

.reply-item-text {
  font-size: 16px;
  margin-bottom: 0;
  white-space: pre-line;
  overflow-wrap: anywhere;
  word-break: break-word;
}
.reply-item-input {
  min-width: 300px;
}

.image-container {
  margin-top: 5px;
}

.cancel-btn,
.save-btn {
  height: 25px;
  width: 80px;
  margin: 10px 5px 0 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.time-container {
  position: absolute;
  left: 1px;
  bottom: -16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-container > div {
  font-size: 12px;
}

.options-container {
  position: absolute;
  right: 0;
  top: 15px;
  z-index: 5;
  cursor: pointer;
}

.loader-container {
  min-height: 30px;
  position: relative;
  margin-top: 11px;
}

@media (min-width: 576px) {
  .reply-item-content {
    min-width: 180px;
  }
}
