
.welcome-text-container{
  width: 100%;
  height: 100%;
}


.welcome-text {
  color: white;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-text:hover {
  text-decoration: underline;
  color: white;
}

.homepage-row {
  display: flex;
  margin: 0 5px;
  align-items: flex-start;
  justify-content: center;
}

.center-container {
  /* position: relative; */
  margin: 16px 5px 0 8px;
  max-width: 720px;
  flex-basis: calc(100% - 45px);
  flex-shrink: 0;
}

.loading-container {
  position: absolute;
  left: 50%;
  min-height: 100vh;
  transform: translateX(-50%);
}

.left {
  position: sticky;
  top: 0;
  height: 100%;
  z-index: 6;
  width: 45px;
  flex-basis: 45px;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
}

.right {
  margin-top: 16px;
  display: none;
  max-height: 87vh;
  top: 80px;
  position: sticky;
  overflow-y: auto;
  overflow-x: clip;
  width: 290px;
  flex-basis: 290px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  display: inline-block;
  color: #151519;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
}

.view-all-session {
  font-size: 14px;
  padding-top: 6px;
  display: inline-block;
  position: relative;
  left: 2px;
}

.create-session-btn {
  width: 175px;
  height: 35px;
}

@media (min-width: 768px) {
  .homepage-row {
    margin: 0 20px;
    margin-left: 0;
    justify-content: flex-start;
  }
  .center-container {
    flex-basis: 100%;
    flex-shrink: 200;
    padding-left: 10px;
  }

  .left {
    flex-wrap: wrap;
    width: 290px;
    flex-basis: 290px;
    position: sticky;
    top: 0;
    overflow-y: auto;
    overflow-x: visible;
    height: auto;
  }
}

@media (min-width: 992px) {
  .left {
    top: 75px;
  }
}

@media (min-width: 1200px) {
  .homepage-row {
    justify-content: space-between;
  }
  .center-container {
    padding-right: 10px;
    max-width: 750px;
  }
  .right {
    display: flex;
    max-height: 72vh;
  }
}

@media (min-width: 1224px) {
  .center-container {
    margin-left: 0;
  }
}

/* ******************************************** */
/* ******************************************** */
/* ******************************************** */

.home-prompt {
  right: 15px;
  top: 11px;
  position: absolute;
}

.info-icon {
  z-index: 2;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  z-index: 1000;
  text-align: center;
  border-radius: 10px;
  /* width: 100%; */
  width: 99%;
  max-width: 900px;
  margin: 0 10px;
}

.modal-content p {
  font-size: 18px;
}

.modal-content .no-margin {
  margin-bottom: 0;
}

.title {
  font-size: 16px;
}
