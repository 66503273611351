.well-badge-container{
  position: absolute;
  background-color: #d55750;
  display: flex;
  align-items: center;
  justify-self: center;

  border-radius: 50%;
  width: 23px;
  height: 23px;
  text-align: center;
  z-index: 2;
}


.number{
  flex-basis: 100%;
  font-size: 16px;
  font-weight: 600;
  color:white;
}


.greater-container{
  width: 23px;
  height: 23px;
}


.greater{
  font-size: 14px;
}

.number sup{
  font-size: 10px;
}



.is-large{
  width: 30px;
  height: 30px;
}
.is-large .number{
  font-size:18px;
}

.is-large .greater{
  font-size: 17px;
}