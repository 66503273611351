.legal-document {
  background-color: rgba(242, 242, 242, 255);
  padding-bottom: 4rem;
}

.last-updated {
  color: #151519;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
}

.legal-document-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.legal-document-container h3 {
  color: #151519;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.legal-document-container h4 {
  color: #151519;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  /* margin-bottom: 20px; */
}

.legal-document-container p,
.legal-document-container ul li {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
}

.legal-document-container p,
.legal-document-container ul {
  margin-bottom: 40px;
}

.no-bottom {
  margin-bottom: 10px !important;
}

.h3-resources {
  color: #151519;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
  margin-top: 16px;
  margin-bottom: 12px;
}

ul > li {
  line-height: 25px !important;
}
