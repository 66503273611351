.container {
  background-color: rgba(255, 255, 255, 0.85);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  backdrop-filter: blur(12px);
  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  z-index: 2;
  position: relative;
}

.left{
  display: flex;
  align-items: center;
  justify-content:flex-start
}

.channel-info {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title-container {
  max-width: 250px;
  position: relative;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.session-title {
  color: var(--header-large-color);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.member-count {
  color: rgb(83, 100, 113);
  font-size: 13px;
  margin-bottom: 0;
  position: relative;
  top: -2px;
  display: inline-block;
}

/* MEMBER LIST */
.member-count-container {
  flex-basis: 100%;
  position: relative;
}
.member-list,
.title-modal-container {
  position: absolute;
  top: 0;
  z-index: 5;
  background-color: white;
  display: none;
  border-radius: 10px;
  box-shadow: 0 12px 28px 0 rgb(0 0 0 / 10%), 0 2px 4px 0 rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px 5px;
}

.member-list {
  top: 25px;
}

.title-modal-container {
  left: 10px;
  top: -35px;
}

.title-modal {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
  position: relative;
}

.member-list-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
  position: relative;
  padding-bottom: 3px;
}
.member-list-title::after {
  content: '';
  width: 100%;
  border: 1px solid rgba(112, 181, 244, 0.3);
  position: absolute;
  bottom: 0;
  left: 0;
}

.member-item {
  max-width: 100px;
  font-size: 12px;
  display: block;
  color: black;
}
.member-item:hover {
  color: black;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .member-list,
  .title-modal-container {
    display: block;
  }
}
