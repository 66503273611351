.section {
  margin-top: var(--section-margin-bottom);
  margin-bottom: var(--section-margin-bottom);
  text-align: center;
}

.section-title {
  color: var(--section-title-color);
  font-size: var(--section-title-size);
  font-weight: var(--section-title-weight);
}

.sessions-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sessions-row > div {
  margin-bottom: 10px;
  margin: 5px 10px;
}

.view-more {
  color: #58a3e7;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.view-more:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .section {
    margin-top: 0;
  }
}

@media (min-width: 1080px) {
  .section {
    text-align: left;
  }

  .sessions-row {
    justify-content: flex-start;
  }

  .sessions-row > div {
    margin: 0;
    margin-bottom: 20px;
    margin-right: 20px;
  }
}
