.container {
  position: relative;
  margin: 16px auto;
  max-width: 800px;
  padding: 10px 20px 10px 0;
  border-radius: 6px;
}

.question {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left;
  color: #2b7bb9;
  font-weight: 600;
}

.answers-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer-item {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 8px;
}

.answer-number {
  margin-right: 7px;
}

.check {
  width: 25px;
  height: 25px;
}

.empty {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-inside {
  width: 19px;
  height: 19px;
  border: 1px solid #6bbe66;
  border-radius: 2px;
  position: relative;
  top: 1px;
}

.answer-face {
  margin-right: 7px;
}

.face {
  width: 31px;
  height: 31px;
}

.answer-text {
  font-weight: 500;
  color: #6b6b6b;
  font-size: 18px;
}

/* to delete */
.nps-answer-text{
  font-weight: 500;
  color: #6b6b6b;
  font-size: 14px;
}

.answer-item-onboarding {
  background-color: #dbd6d6;
  color: black;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 8px;
}

.answer-item-onboarding.selected {
  background-color: #4ea9a4;
  color: white;
}

.answer-text-onboarding {
  font-weight: 500;
  font-size: 16px;
}

@media (min-width: 768px) {
  .answers-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .answer-item {
    min-width: 80px;
  }


  /* to delete */
  .nps-answer-item {
    flex-direction: column;
  }
  .nps-lower{
    position: relative;
    top: 11px;
  }

}

@media (min-width: 992px) {
  .question {
    font-size: 24px;
  }

  .container {
    max-width: 900px;
  }
}
