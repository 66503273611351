.preferences-container{
  position: relative;
}

.title{
  font-size: 26px;
}


.input-container{
  margin: 20px auto;
}

.input-container > div{
  margin-bottom: 5px;
}


.submit-btn{
  height: 40px;
  width: 120px;
}