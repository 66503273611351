.group-list-page {
  position: relative;
  background-color: rgba(248, 247, 243, 255);
  min-height: calc(100vh - var(--top-nav-height));
}

.group-list-container {
  width: 100%;
  padding: 1rem 15px 4rem;
  /*box-shadow: inset 0 15px 45px 0 rgba(0,0,0,0.1);*/
}

.list-item {
  position: relative;
  margin-bottom: 28px;
}

.section-title {
  text-align: center;
  margin-bottom: 0;
  color: #151519;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
}

.list-item-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}


.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
}

.select-container {
  display: none;
  z-index: 5;
  position: relative;
}

.select-container > div {
  width: 200px;
  z-index: 5;
  position: relative;
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .group-list-container {
    justify-content: normal;
  }
  .section-title {
    text-align: left;
  }
  .list-item-row {
    justify-content: space-between;
  }

  .odd::after {
    content: '';
    width: 358px;
    height: 300px;
  }

  .title-container {
    justify-content: space-between;
  }
  .select-container {
    display: block;
  }
}

@media (min-width: 1188px) {
  .group-list-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1188px;
  }
}

@media (min-width: 1570px) {
  .group-list-container {
    /* max-width: 1570px; */
  }
}
