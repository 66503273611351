.wrapper {
  overflow: scroll;
}

.modal-wrapper {
  min-height: 200px;
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 8px;
}

.main-text {
  text-align: center;
  font-size: 18px;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-side {
  display: none;
}

.left-side {
  background-color: #ffeab4;
  padding: 20px 5px;
}

.left-side h4 {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  padding: 0 5px;
}

.bullet-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px;
  margin-left: 10px;
}

.check {
  width: 20px;
  margin-right: 5px;
}

.btn-container {
  margin-top: 16px;
  text-align: center;
}

@media (min-width: 486px) {
  .right-side {
    display: block;
  }

  .left-side,
  .right-side {
    flex-basis: 50%;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .title {
    font-size: 28px;
  }

  .main-text {
    font-size: 18px;
  }
}


/* VIDEO MODAL */
.iframe{
  width: 100%;
  min-height: 300px;
}