.container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  padding: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  min-height: 200px;
  text-align: center;
}

.container h3 {
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 16px;
}

.container p {
  font-weight: 400;
  font-size: 18px;
}

.image-container {
  max-width: 450px;
  margin: auto;
}

.image-container-no-host {
  max-width: 333px;
  margin: auto;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .container h3 {
    margin-top: 32px;
  }

  .container p {
    font-size: 20px;
    margin-bottom: 0;
  }
}
