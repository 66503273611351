.container {
  position: relative;
  z-index: 2;
  padding-top: 64px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
}

.parallax-content-row {
  z-index: 2;
  background-color: white;
  width: 100%;
  text-align: center;
  left: 0;
  text-align: left;
  bottom: 10px;
  padding: 0;
}

.content-container {
  background-color: rgba(112, 181, 244, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  word-break: break-word;
}

.time-container {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  bottom: 25px;
  color: #151519;
  line-height: 1.2;
  margin-top: 10px;
  opacity: 0.85;
}

.group-name {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
}

.host {
  font-size: 17px;
  margin-top: 5px;
  font-weight: 500;
  color: #6e7680;
}
.host-name {
  font-weight: 500;
  padding-left: 4px;
  color: #6e7680;
}

.host-name:hover {
  color: #6e7680;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .parallax-content-row {
    display: flex;
  }

  .title {
    font-size: 28px;
  }

  .session-image-container {
    height: 265px;
    flex: 0 0 var(--left-percent);
    max-width: var(--left-percent);
  }
  .content-container {
    flex: 0 0 var(--right-percent);
    max-width: var(--right-percent);
  }

  .time-container {
    position: absolute;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .session-image-container {
    height: 360px;
  }
}
