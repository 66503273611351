.forum-input-container {
  position: relative;
  display: flex;
  width: 100%;
  word-break: break-word;
}

.activity-feed-div,
.hidden-input {
  border: 1px solid hsla(0, 0%, 100%, 0);
  border-radius: 4px;
  color: #475259;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  padding: 8px;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
  width: 100%;
  background: #f2f2f2;
  min-height: 40px;
  max-width: 100%;
  white-space: break-spaces;
  padding-right: 30px;
}

.hidden-input {
  position: absolute;
  background: transparent;
  color: black;
  -webkit-text-fill-color: transparent;
  resize: none;
  height: 100%;
}

.has-inline-options .activity-feed-div,
.has-inline-options .hidden-input {
  padding: 1px 50px 1px 5px;
  min-height: 25px;
}

.hidden-input:hover,
.hidden-input:active,
.hidden-input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

/* User Tagging */
.user-search-container {
  position: absolute;
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 15px,
    rgb(255 255 255 / 15%) 0px 0px 3px 1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  top: 101%;
  left: 0;
  min-width: 200px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 3;
  margin-top: 2px;
  border-radius: 10px;
  min-height: 20px;
}
.user-item {
  padding: 7px;
  cursor: pointer;
  font-weight: 500;
}
.user-item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.user-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.user-item:hover,
.user-item.active {
  background-color: rgb(211, 211, 211);
}
.user-link {
  color: var(--link-color);
  font-weight: 500;
  pointer-events: none;
  z-index: 5;
}

.emoji-picker-container {
  position: absolute;
  top: calc(100% + 2px);
  left: 1px;
  z-index: 2;
}

.emoji-picker-container :global(.emoji-mart-bar) {
  display: none;
}
