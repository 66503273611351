

.member-item {
  box-shadow: 0.0625rem 0.0625rem 0.625rem hsl(0deg 0% 55% / 40%);
  text-align: center;
  padding: 10px 15px 10px 20px;
  margin: 15px;
  position: relative;

  /*Adding*/
  background: #fff;
  background-clip: padding-box;
  background-size: cover;
  border: 1px solid rgba(46,62,72,.12);
  cursor: pointer;
  border-radius: 6px;
  width: 430px;
  height: 100px;
}


.member-item:hover {
  text-decoration: none;
  -webkit-box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: white;
}

.member-link{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.member-image-container{
  position: relative;
  margin-right: 15px;
}

.member-image {
  border-radius: 50%;
  height: 55px;
  width: 55px;
  position: relative;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.member-image-container::before {
  content: '';
  display: inline-block;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 57px;
  width:  57px;
}

.right-container{
  text-align: left;
}


.member-name {
  color: #151519;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  text-align: left;
  margin-bottom: 4px;
}

.metric-item{
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3px;
}

.metric-joined{
  color: #151519;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 0;
}

.metric{
  color: #575757;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;  
  display: inline-block;
  margin-bottom: 0;
}

.divider{
  font-size: 35px;
  margin: 0 5px;
  height: 0;
  position: relative;
  top: -18px;
  color: black;
}



/*CREATOR ONLY*/
.user-options-container{
  position: absolute;
  top: 0;
  left: 0;
}


@media(min-width: 576px){
  .metric-item{
    display: flex;
  }
}