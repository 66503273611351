.options-btn {
  position: relative;
  height: 40px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  border: 1px solid #7a7676;
}

.options-btn:hover {
  background: linear-gradient(to right, #87bdee 0%, #3e94e3 100%);
}
.options-btn:hover div::after {
  color: white !important;
}