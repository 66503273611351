.desktop-container{
	display: none;
}


@media (min-width: 992px){

	.desktop-container{
		display: block;
	}

	.mobile-container{
	    display: none;
	}
}



