.radio-container-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.radio-item {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dbdae3;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  margin-right: 16px;
  padding: 10px;
  margin-bottom: 5px;
}

.radio-item.active {
  background-color: rgba(112, 181, 244, 0.2);
  border: 1px solid #3659e3;
  color: #3659e3;
}

.label {
  color: #1e0a3c;
}

.label.active {
  color: var(--color-primary);
}
