.container {
  /* padding: 32px 0 0; */
  text-align: center;
}

.video-container {
  display: inline;
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 34px 34px 19px -15px rgb(0 0 0 / 30%);
}

.play-icon-container {
  height: 50px;
  width: 50px;
  margin: 0 auto;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  opacity: 0.9;

  display: flex;
  align-items: center;
  justify-content: center;
}

.play-icon-container-inpage {
  height: 50px;
  width: 50px;
  margin: 0 auto;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(-50%, -30%);
  opacity: 0.9;

  display: flex;
  align-items: center;
  justify-content: center;
}

.play-icon {
  position: relative;
  left: 2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 16.3px;
  border-color: transparent transparent transparent var(--color-primary);
}

.graphic {
  max-width: 400px;
  border-radius: 10px;
  margin: 10px 0;
}

.learn-more {
  font-size: 16px;
  font-style: italic;
  display: block;
}

.video-container:hover .play-icon-container {
  opacity: 1;
  background: var(--color-primary);
}

.video-container:hover .play-icon {
  border-color: transparent transparent transparent white;
}

.modal-wrapper-class {
  padding: 0 !important;
}
.iframe {
  height: 100%;
  width: 100%;
  min-height: 356px;
  z-index: 0;
  margin-top: 20px;
  border-radius: 5%;
}

.iframe-inpage {
  height: 100%;
  width: 75%;
  min-height: 213px;
  max-width: 410px;
  z-index: 0;
  border-radius: 5%;
}

.iframe-inpage-home {
  height: 100%;
  width: 100%;
  min-height: 253px;
  max-width: 479px;
  z-index: 0;
  border-radius: 5%;
}
