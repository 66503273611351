.title {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
}

.subtitle {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
}

.loader-container {
  position: relative;
  height: 100px;
}

.is-done {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  text-align: center;
  margin-top: 32px;
}

.is-done-image-container {
  text-align: center;
}

.is-done-image {
  max-width: 250px;
  margin: 16px auto 0;
}


.divider{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 85%;
  margin-top: 32px;
}

.current-card-container{
  margin-top: 32px;
  padding: 20px;
}

.current-card-container h3{
  font-size: 18px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .is-done {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .title {
    font-size: 24px;
  }
  .subtitle {
    font-size: 20px;
  }
}
