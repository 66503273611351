.radio-item,
.radio-item-circle {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dbdae3;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  margin-right: 16px;
  padding: 10px;
  min-width: 100px;
  margin-top: 10px;
}

.radio-item-circle{
  border-radius: 100%;
  min-width: auto;
  width: 45px;
  height: 45px;
  margin-top: 10px;
}

.radio-item.active,
.radio-item-circle.active {
  background-color: rgba(112, 181, 244, 0.2);

  border: 1px solid #3659e3;
  color: #3659e3;
}

.label {
  color: #1e0a3c;
}

.label.active {
  color: var(--color-primary);
}
