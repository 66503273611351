.sessions {
  position: relative;
}

.sessions-container {
  width: 100%;
  padding: 1rem 15px 4rem;
}

.top-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.create-session-btn-container {
  text-align: center;
}

.create-session-btn {
  height: 40px;
  width: 175px;
  margin-bottom: 16px;
}

.list-container {
  margin-top: 16px;
}

.no-sessions-notice {
  text-align: center;
  padding-top: 32px;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .top-items {
    justify-content: space-between;
  }
}


@media (min-width: 992px) {
  .sessions-container {
    justify-content: normal;
    min-height: 400px;
  }

  .sessions-container > div {
    margin-right: auto;
  }

  .create-session-btn-container {
    text-align: left;
  }
}

@media (min-width: 1188px) {
  .sessions-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1188px;
  }

}

@media (min-width: 1570px) {
  .sessions-container {
    max-width: 1570px;
  }
}
