.first-section {
  text-align: left;
  position: relative;
  padding: 20px;
  padding-top: 0;
  overflow: hidden;
  height: 300px;
}

.top-left {
  max-width: 95px;
  position: absolute;
  top: -25px;
  left: -20px;
}

.bottom-right {
  max-width: 95px;
  position: absolute;
  bottom: -20px;
  right: -20px;
}

.no-margin {
  margin-bottom: 5px;
  font-size: 18px;
}

.signature-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cece-image {
  border-radius: 100%;
  height: 90px;
  width: 90px;
  margin-right: 15px;
}

.cece-signature {
  max-width: 100px;
}

.modal-title {
  font-size: 21px;
  text-align: center;
  font-weight: 400;
}
.modal-subtitle {
  font-size: 17px;
  text-align: center;
  margin-bottom: 32px;
  font-weight: 400;
}

.buttons-container {
  margin-top: 32px;
  display: flex;
  justify-content: space-evenly;
}

.modal-btn {
  padding: 10px 10px;
}

.modal-btn-cancel {
  padding: 10px 10px;
  min-width: 140px;
  min-height: 35px;
}

.poll-row {
  margin-top: 16px;
  margin-bottom: 3rem;
}

.poll-answer {
  padding-left: 1.5rem;
}

.poll-answers {
  width: 98%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.answer-input {
  border: none;
  border-bottom: gray solid 1px;
  background: transparent;
  width: 60%;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.poll-answers-label {
  padding-left: 0.5rem;
  display: inline;
  height: 26px;
}
.poll-answer-text {
  margin-bottom: 0;
  font-size: 18px;
}

.checkbox {
  position: relative;
  min-height: 15px;
  min-width: 15px;
  cursor: pointer;
}

.checkbox:checked::after {
  content: '\2713';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 15px;
  width: 15px;
  background-color: #0088ce;
  color: white;
  border-radius: 4px;
  text-align: center;
  line-height: 1;
}

.input-container {
  padding-left: 25px;
}

.poll-answer.selected {
  color: white;
}

.poll-answer.selected:nth-child(1n) {
  background-color: #0b96c5;
}

.poll-answer.selected:nth-child(2n) {
  background-color: #07c497;
}

.poll-answer.selected:nth-child(3n) {
  background-color: #0aaca5;
}

.poll-answer.selected:nth-child(4n) {
  background-color: #0d83d8;
}

.is-done-image-container {
  text-align: center;
}

.is-done-image {
  max-width: 250px;
  margin: 0 auto 8px;
}

/* special offer */
.blue {
  color: #69ace9;
}
.subscription-row {
  display: inline-block;
  width: 300px;
}

.subscription-row > div {
  max-width: 260px;
  padding: 20px;
  margin: 0 auto !important;
}

.btns-row {
  margin-top: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  min-height: 30px;
}

.iframe {
  width: 100%;
  height: 100%;
  max-height: 400px;
}

@media (min-width: 576px) {
  .poll-answer {
    padding-left: 6rem;
  }
}
@media (min-width: 768px) {
  .poll-answer {
    padding-left: 7rem;
  }
}

@media (min-width: 992px) {
  .modal-title {
    font-size: 26px;
  }
  .modal-subtitle {
    font-size: 18px;
  }

  .first-section {
    height: 400px;
  }

  .no-margin {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .poll-answer {
    padding-left: 10rem;
  }
}
