.well-footer {
  box-shadow: rgb(101 119 134 / 31%) 0px 0px 15px,
    rgb(101 119 134 / 44%) 0px 0px 3px 1px;

  background-color: var(--color-primary);
  color: white;

  position: relative;
  z-index: 5;
  padding: 20px 10px 70px;
}

.well-footer-container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* TOP */
.footer-top-row {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.logo-col {
  padding-left: 0;
  display: none;
}

.sharewell-logo {
  width: 225px;
  position: relative;
  left: -2px;
  margin-bottom: 5px;
}

.mission {
  margin-bottom: 0;
  font-size: 14px;
}

.footer-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 0;
}
.footer-item {
  margin: 0 10px;
}

.footer-item-link {
  color: white;
  font-size: 13.5px;
}

.footer-item-link:hover {
  color: white;
  text-decoration: underline !important;
}

.footer-link-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 380px;
}

.white-line {
  width: 100%;
  margin: 10px auto;
  height: 1px;
  background-color: white;
}

/* Bottom */
.footer-item-bottom {
  text-align: center;
}
.bottom-row {
}

.rights {
  font-size: 14px;
}

.social-row {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}

.social-icon-container {
  text-align: center;
  margin-right: 10px;
}
.social-icon {
  width: 28px;
  border: 1px solid white;
  border-radius: 100%;
}

.social-icon:hover {
  transform: translateY(-2px);
  transition-duration: 0.3s;
}

/* Email capture */
.input-container {
  padding: 0 20px 10px;
}
.submit-button {
  background-color: var(--color-primary);
  cursor: pointer;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  transition: all 0.25s ease-in-out;
  font-weight: 500;
  border-radius: 1px;
  font-size: 14px;
}

.submit-button:hover {
  background-color: white;
  color: var(--color-primary);
}

.input-container input::placeholder {
  color: #fff !important;
  font-size: 13px !important;
}

.error {
  font-size: 13px;
  color: var(--color-error);
  position: relative;
  top: -6px;
  left: 6px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .well-footer {
    padding: 20px 10px;
  }

  .footer-top-row {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .footer-col {
    flex-direction: column;
  }
  .footer-item {
    margin: 1px 0;
    width: 116px;
  }
  /* bottom */
  .footer-item-bottom {
    text-align: left;
  }

  .social-row {
    justify-content: flex-start;
  }
}

@media (min-width: 1188px) {
  .well-footer-container {
    max-width: 1188px;
  }
}

@media (min-width: 1200px) {
  .logo-col {
    display: block;
  }
}

@media (min-width: 1570px) {
  .well-footer-container {
    max-width: 1570px;
  }
  .footer-item {
    width: 129px;
  }
  .footer-item-link {
    font-size: 15px;
  }
}
