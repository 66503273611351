.container{
  padding: 15px;
  background-color: white;
}

.section-title{
  color: var(--section-title-color);
  font-size: var(--section-title-size);
  font-weight: var(--section-title-weight);
}

.top p{
  color: #575757;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0;
}

.divider{
  margin: 25px 0;
  width: 100%;
  height: 2px;
  background-color: #D8D8D8;
}

.bottom{
  display: flex;
  flex-wrap: wrap;
}

.bottom > div{
  margin-right: 20px;
  min-width: 350px;
}