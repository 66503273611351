.group-info-page {
  min-height: calc(100vh - var(--top-nav-height) );
  min-height: calc( (calc(var(--vh, 1vh) * 100)) - var(--top-nav-height) );
  background-color: rgba(248,247,243,255);
  position: relative;  

}

.group-info-page-content{
  position: relative;
  min-height: 400px;
}



@media (min-width: 768px) {
  .group-info-page-content{
    min-height: 500px;
  }

}