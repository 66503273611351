.container {
}

:global(.ReactTags__tags) {
  position: relative;
}

:global(.ReactTags__clearAll) {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
:global(.ReactTags__tagInput) {
  border-radius: 5px;
  border: 1px solid #dddfe2;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(255, 255, 255);
  width: 100%;
  margin-top: 10px;
}

:global(.ReactTags__tagInput) :global(.ReactTags__tagInputField),
:global(.ReactTags__tagInput) :global(ReactTags__tagInputField):focus {
  border: none;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
}

:global(.ReactTags__editInput) {
  border-radius: 1px;
}

:global(.ReactTags__editTagInput) {
  display: inline-flex;
}

/* Styles for selected tags */
:global(.ReactTags__selected) :global(.ReactTags__tag),
.tag-item {
  position: relative;
  display: inline-block;
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 10px;
  font-size: 14px;
  background-color: #e8e4e4;
  margin-bottom: 5px !important;
}

:global(.ReactTags__selected) :global(.ReactTags__tag) {
  padding-right: 20px;
}

:global(.ReactTags__selected) :global(.ReactTags__tag):not(:first-child),
.tag-item:not(:first-child) {
  margin: 0 5px;
}

:global(.ReactTags__selected) :global(.ReactTags__remove) {
  color: black;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  position: absolute;
  right: -1px;
  top: 1px;
}
