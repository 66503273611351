/*each item gets the flex needed manually*/
.search-bar-container {
  position: relative;
}

.search-bar-container label {
  display: none;
}

.search-bar-container > div {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
