.forum-container{
  width: 100%;
}



.loading-container{
  position: relative;
  min-height: 300px;
  width: 100%;
}

.there-is-new, .no-more{
  background-color: rgb(248, 252, 254);
  cursor: pointer;
}


.load-more{
  background-color: rgb(248, 252, 254);
  width: 100%;
  height: 50px;
  position: relative;  
}


.loading-more-inner{
  color: #0ba8e0;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  padding: 12px;
  text-align: center;  
}

.no-more{
  cursor: default;
}