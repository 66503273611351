.upcoming-sessions-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  margin-bottom: 10px;
}

.upcoming-sessions-text h3 {
  font-size: 18px;
  color: #151519;
  font-weight: 500;
  margin-bottom: 0;
}
.view-all {
  font-weight: 600;
  font-size: 14px;
}



.top-items-container{
  margin-bottom: 16px;
}


.notice-container {
  height: 100%;
  display: none;
}

.notices {
  -moz-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 1px 0 rgb(0 0 0 / 5%), 0 1px 3px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 5%), 0 1px 3px 0 rgb(0 0 0 / 15%);
  background-color: white;
  max-height: 400px;
  margin-left: 10px;
  border: none;
}

.notices:hover{
  -webkit-box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  color: white;
}

.notices img{
  height: 350px;
}



@media (min-width: 768px) {
  .upcoming-sessions-text,
  .top-items-container{
    display: flex;
  
  }
}

@media (min-width: 992px) {

  .left-session {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }  

  .top-items-container {
    display: flex;
    /* justify-content: space-around; */
    justify-content: flex-start;
    /* flex-wrap: wrap; */
    position: relative;
    margin-top: 10px;
  }

  .notice-container {
    margin-left: 10px;
  }

  .left-session > a {
    margin-bottom: 0;
  }


  .notices-container {
    width: 300px;
    margin-left: 20px;
    margin-top: 73px;
  }

  


}



@media (min-width: 1344px) {
  .notice-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }  
  
}