.dropdown {
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  text-align: left;
}

.dropdown-item {
  padding: 5px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-text {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
}

.dropdown-item:hover {
  color: var(--color-primary);
}

@media (min-width: 992px) {
  .dropdown {
    min-width: 160px;
  }

  .dropdown-text {
    font-size: 16px;
  }
}
