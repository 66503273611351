.room-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.room-textarea {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px px auto 0;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border: 3px solid #c8ddff;
  border-radius: 6px;
  height: 80px;
}

.room-modal {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 95vw;

  background: #fff;
  border-radius: 4px;

  padding: 20px 25px 16px;
  z-index: 100000;
  opacity: 0.99;

  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  -moz-box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  margin: 10px;
  margin-left: 0;
  color: rgba(0, 0, 0, 0.87);
}

.close-modal {
  position: absolute;
  cursor: pointer;
  top: -2px;
  right: 3px;
}

@media (min-width: 992px) {
  .room-modal {
    max-width: 750px;
  }
}

/* INNER ITEMS */

.inner-modal-title {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #424242;
}

.error {
  color: red;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.inner-modal-subtitle {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #7a7a7a;
  padding-bottom: 10px;
  width: 95%;
}
.inner-modal-paragragh {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  padding-left: 3%;
}
.inner-container {
  text-align: center;
}

.inner-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 500px;
  margin: 20px auto 0;
  width: 100%;
}

.inner-no-button {
  background: linear-gradient(to right, #4e9ce6 0%, #4e9ce6 100%);
  color: white !important;
  font-weight: 800 !important;
}

.inner-yes-button {
  background: linear-gradient(to right, #dc3444 0%, #dc3444 100%);
  color: white !important;
  font-weight: 800 !important;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid grey; */
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.form-container input {
  border: 1px solid grey;
  width: 80px;
  margin: 0;
  padding-right: 0;
  /* margin-right: 5px; */
  height: 25px;
}

.minutes {
  margin-left: 5px;
  font-weight: 400;
}

@media (min-width: 992px) {
  .inner-modal-title {
    font-size: 22px;
  }
}

/* .open {
  display: flex !important;
}
.closed {
  display: none !important;
}
.room-modal {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  width: 100%;

  background: #fff;
  background-image: linear-gradient(48deg, #fff 0%, #e5efe9 100%);
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;

  padding: 40px 30px 32px;
  z-index: 100000;
  opacity: 0.99;

  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgb(0 0 0 / 30%);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgb(0 0 0 / 30%);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

.modal-input {
  height: 45px !important;
}
.small {
  min-height: 230px !important;
}
.top-text {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.close-modal {
  position: absolute;
  color: black;
  top: 0;
  right: 9px;
  cursor: pointer;
  font-size: 1.5rem;
}

.input-container {
  margin-bottom: 2rem;
}

.buttons-container {
  width: 100%;
  text-align: center;
}

.yes-button,
.no-button {
  margin: 0 10px;
  min-width: 130px !important;
  margin-bottom: 1rem;
}

.yes-button {
  background: var(--color-error);
}

.room-modal-content textarea {
  border-radius: 15px;
}

.rules-title {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.rules-list {
  font-size: 1rem;
}
.rules-p {
  font-size: 1rem;
  text-align: center;
}

.no-visibility {
  visibility: hidden;
}
.after-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  text-align: center;
}

@media (min-width: 576px) {
  .room-modal {
    width: auto;
    height: auto;
    min-width: 530px;
  }
}

@media (min-width: 992px) {
  .rules-title {
    font-size: 1.5rem;
  }
} */
