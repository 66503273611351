.blog-item {
  border-radius: 10px;
  max-width: 310px;
  cursor: pointer;
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 2rem;
  position: relative;
}

.blog-item {
  transition: opacity 0.2s ease-in;
}
.blog-item:hover {
  opacity: 0.95;
}

.blog-item-image-container {
  position: relative;
  margin-bottom: 10px;
}

/*.blog-item:hover img::after{*/
.blog-item:hover .blog-item-image-container::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 101%;
  height: 101%;
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
  z-index: 1;
  border-radius: 10px;
}

.blog-item-image {
  position: relative;
  z-index: 2;
  border-radius: 10px;
}

.blog-item-content {
  padding: 10px 2px;
  height: calc(100% - 310px);
  position: relative;
  text-align: center;
}
.blog-item-category {
  color: #58a3e7;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  margin-bottom: 10px;
}
.blog-item-title {
  color: #151519;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 5px;
}
.blog-item-subtitle {
  color: #151519;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 10px;
}
.blog-item-date {
  color: #6b6b6b;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.blog-item-length {
  color: #9398a0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 23px;
}

@media (min-width: 310px) {
  .blog-item-image {
    width: 310px;
    height: 310px;
    max-width: none;
  }
}

@media (min-width: 768px) {
  .blog-item-content {
    min-height: 183px;
    text-align: left;
  }
  .blog-item-length {
    position: absolute;
    bottom: 5px;
  }
  .blog-item:last-child {
    left: 24px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
