.recommendations-section {
  background-color: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.recommendations-section-title {
  color: #424242;
  font-size: var(--section-title);
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  padding: 0 10px;
}

.recommendations-section-p {
  text-align: center;
  margin-bottom: 2rem;
  padding: 0 10px;
}

.recommendations-row {
  display: flex;
  justify-content: space-evenly;
  margin: 0 15px;
  flex-wrap: wrap;
}

.item {
  background-color: #f6f6f6;
  height: 100%;
  text-align: center;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 425px;
  padding-bottom: 125px;
}

.recommendation-text {
  color: #151519;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0;
  line-height: 25px;
}

.recommendation-author-information {
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: left;
}
.quotes-img {
  top: -9px;
  right: -10px;
  position: absolute;
  width: 45px;
  z-index: 2;
}

.author-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 10px;
  z-index: 1;
}

.recommendation-author-name {
  position: absolute;
  font-weight: 500;
  margin-bottom: 0;
  right: 20px;
  bottom: 0;
  width: 125px;
}

.name {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 18px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-style: italic;
}

/* NEW LONG VERSION */
.item-long {
  text-align: center;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: white;
  margin-top: 32px;
  text-align: left;
}

.text-container {
  text-align: right;
}

.text-container h3 {
  text-align: left !important;
  font-size: 16px !important;
  font-weight: bold;
  font-style: italic;
  color: white;
}

.recommendation-author-information-long {
}

.recommendation-author-name-long {
  max-width: 100px;
}

.quotes-img-long {
  position: absolute;
  width: 45px;
  z-index: 2;
  right: 0;
  top: -11px;
}

.author-image-long {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 10px;
  z-index: 1;
}


@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .recommendations-section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .recommendations-row {
    margin: 0 30px;
  }

  .item {
    width: 400px;
    height: 280px;
    margin: 20px;
    padding-bottom: 20px;
  }

  .recommendation-text {
    font-size: 18px;
  }

  .recommendation-author-information {
    padding-right: 20px;
  }

  .recommendation-author-information {
    bottom: -22px;
    left: -30px;
  }

  .quotes-img {
    top: -25px;
    right: -5px;
    width: 64px;
  }

  .author-image {
    width: 90px;
    height: 90px;
  }
}

@media (min-width: 1440px) {
  .recommendations-container {
    max-width: 1350px;
  }
  .recommendations-row {
    margin: 0 30px;
  }
}
