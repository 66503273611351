.create-group-page {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  min-height: calc(100vh - var(--top-nav-height));
  background-color: rgba(220, 216, 197, 0.2);
}
.create-group-form {
  position: relative;
  max-width: 1000px;
  padding: 40px 15px 10px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
}
.create-group-header {
  font-weight: 400;
  color: var(--color-primary);
  font-size: 22px;
}

.section-text, .section-list li{
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  color: #151519;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  word-break: break-word;
}
.section-list {

  list-style-type: none;
}
.title-input{
  margin: 32px auto 16px !important;
  max-width: 550px !important;
}
.description-input {
  margin: 32px auto 16px !important;
}
.image-input{
  margin: 16px auto 16px !important;
  max-width: 550px !important;
}


.review-item-title h3 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  word-break: break-word;
  color: var(--color-primary);
}
.review-item-desc h3 {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  word-break: break-word;
}

.review-item-note{
  margin-top: 20px;
}
.review-item-note h4{
  font-style: italic;
  font-size: 16px;
  margin-top: 40px;
}

.extra-padding {
  padding-bottom: 16px;
}

.name-taken-notice {
  color: #d55750;
  font-size: 20px;
}

.btn-row {
  margin: 32px auto 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  min-height: 40px;
  max-width: 600px;
}
.back-btn,
.forward-btn {
  height: 40px;
  width: 130px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.extra-padding-bottom{
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .create-group-form {
    min-height: 300px;
    padding: 40px 40px 10px;
  }
  .create-group-header{
    font-size: 28px;
  }
  .btn-row{
    /* margin: 85px auto 10px;     */
  }
}

@media (min-width: 860px) {
  .create-group-form {
    margin-left: auto;
    margin-right: auto;
  }
}
