
.container{
  padding: 15px;
  background-color: white;
}



.section-title{
  color: var(--section-title-color);
  font-size: var(--section-title-size);
  font-weight: var(--section-title-weight);
  padding-left: 5px;
  margin-bottom: 20px;
  text-align: center;
}



@media (min-width: 768px) {
  .badge-container{
    display: flex;
    justify-content: space-evenly;
  }


}



@media (min-width: 992px) {
  .badge-container{
    display: block;
  }

  .section-title{
    text-align: left
  }

}