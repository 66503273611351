
.session-canceled-container{
  padding-bottom: 6rem;
  margin-top: 2rem;
}


.session-canceled-row{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}


.item-container{
  width: 100%;
  align-items: center;
  padding: 24px 20px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.05);  
  margin-bottom: 20px;
  text-align: center;
}

.item h4{
  display: inline-block;
  margin-bottom: 20px;
  font-size: 26px;  
  margin-bottom: 24px;
}

.item p{
  color: #151519;
  margin-bottom: 16px;
}

.link-btn{
  margin-top:16px;
  height: 40px;
  width: 200px;
}

@media (min-width: 992px){
  .session-canceled-container{
    min-height: 400px;
  }
  .session-canceled-row{
    justify-content: flex-start;
  }
}


