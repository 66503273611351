.mobile-menu {
  background-color: white;
  border-bottom: 1px solid #dadada;
  box-shadow: 0 3px 4px rgb(0 0 0 / 8%);
  z-index: 2;
  color: #4a4a53;
  height: var(--top-nav-height);
  font-size: 1rem;

  z-index: 15;
  position: relative;
}

.in-room {
  display: none;
}

.logo-link {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  max-width: 160px;
  cursor: pointer;
}
.logo-link img {
  width: 200px;
}

.logo-link:hover {
  color: #4a4a53;
  text-decoration: none;
}

.hamburger-icon-container {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.hamburger-icon {
  width: 35px;
  cursor: pointer;
}

.nav-container {
  position: fixed;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  pointer-events: none;
  z-index: 1000;
}

.nav-container .bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  background: #000;
}

.nav-content {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #ececec;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateX(-100%);
  transition: transform 0.3s;
  will-change: transform;
}

.mobile-nav-open .nav-content {
  transform: translateX(0);
}

.nav-top {
  height: var(--top-nav-height);
  background: white;
  border-bottom: 1px solid #dadada;
  box-shadow: 0 3px 4px rgb(0 0 0 / 8%);
  position: relative;
}

.close-button-container {
  color: black;
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.close-button-container {
  width: 30px;
}

.nav-items {
  height: 100%;
  overflow: hidden;
}

.nav-item {
  background-color: #ffffff;
  height: calc((100vh - var(--top-nav-height)) / 9);
  height: calc(((var(--vh, 1vh) * 100) - var(--top-nav-height)) / 9);

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px !important;
  border-left: none;
  border-right: none;
  cursor: pointer;
  padding: 20px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 400;
  color: #4a4a53;
}

.nav-item:hover {
  color: var(--color-primary);
}

.logged-out .nav-item {
  height: calc((100vh - var(--top-nav-height)) / 6);
  height: calc(((var(--vh, 1vh) * 100) - var(--top-nav-height)) / 6);
}
