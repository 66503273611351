.well-panel {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: rgba(220, 216, 197, 0.2);
  min-height: calc(100vh - var(--top-nav-height));
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--top-nav-height));
}

.well-panel-inner {
  position: relative;
  max-width: 860px;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.1);
  min-height: 200px;

  margin-left: 15px;
  margin-right: 15px;
}


@media (min-width: 768px) {
  .well-panel {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}


@media (min-width: 860px) {
  .well-panel-inner {
    margin-left: auto;
    margin-right: auto;
  }
}