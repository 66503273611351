.divider-container {
  position: relative;
  width: 100%;
}
.divider {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 0;
  -webkit-transition: width 0.75s ease-in-out;
  -moz-transition: width 0.75s ease-in-out;
  -o-transition: width 0.75s ease-in-out;
  transition: width 0.75s ease-in-out;
}

.divider.animate {
  width: 85%;
}
