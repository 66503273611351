
.social-share-items{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
	text-align: center;
	padding: 1rem;
    z-index: 6;
    -webkit-box-shadow: 0 0 2px 0 rgb(46 62 72 / 12%), 0 2px 4px 0 rgb(46 62 72 / 12%);
    box-shadow:  0 0 2px 0 rgb(46 62 72 / 12%), 0 2px 4px 0 rgb(46 62 72 / 12%);
    background-color: white;
    border-radius: 10px;
    height: 400px;
    width: 365px;
	display: block;
}


.close-btn{
    position: absolute;
    font-size: 2rem;
    cursor: pointer;
    color: #2e3e48;
    top: -5px;
    right: -2px;
    height: 50px;
    width: 35px;
    z-index: 2;
}



.share-title{
	font-size: 28px;
	text-align: left;
	margin-bottom: 0;
}


.social-share-row{
	align-items: center;
	justify-content: center;
}

.share-item{
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    color: rgba(59,130,246,0.7);
    padding: 10px 15px;
    cursor: pointer;
    padding-bottom: 20px;
}

.share-item:last-child{
	border-bottom: none
}


.link-text{
	margin-left: 10px;
    font-size: 1rem;
}

.link-text:hover{
    color: rgba(59,130,246,1) !important;
}

.mail-icon{
    position: relative;
    top: 3px;
    width: 40px;	
}


.copy-link-icon{
	width: 35px;
}

.email-link{
	color: rgba(59,130,246,0.7) !important;
}

.email-link:hover{
	text-decoration: none;
}

.copy-text{
    position: absolute;
    left: 0;
    z-index: -1;
    /*visibility: hidden;*/
    opacity: 0;
}


.blink-me{
  animation: blinker 0.5s linear 1;
}



@media (min-width: 992px){
	.social-share-items{
		text-align: left;
	}
	.social-share-row{
		justify-content: flex-start;
	}

}










@keyframes blinker {
  50% {
    opacity: 0;
  }
}