/*FOOTER*/
.session-room-footer {
  bottom: 0;
  width: 100%;
  background-color: #54545f;

  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  height: 140px;
  color: white;
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: var(--footer-height);
}

.session-room-footer > div {
  height: 100%;
}

.footer-row {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center,
.left-side,
.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer-icon-container {
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer-icon-container:hover {
  background-color: #909097;
}

.footer-icon {
  position: relative;

  display: block;
}

.footer-icon-text {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  bottom: 1px;
  transform: translateX(-50%);
}

.unread-count {
  top: -2px;
  right: -10px;
}

.inner-list {
  padding-left: 20px;
}

.rules-p,
.rules-list {
  font-size: 14px;
}

@media (min-width: 768px) {
  .rules-p,
  .rules-list {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: none;
    margin: 0 10;
  }
  .footer-row {
    justify-content: space-between;
  }
  .chat-container {
    display: flex;
  }
}

/* ICONS */
.audio-icon {
  width: 40px;
  padding-bottom: 10px;
}
.rules-icon {
  width: 41px;
  padding-bottom: 14px;
}
.host-hide {
  display: none;
}

.agenda-icon {
  position: relative;
  width: 45px;
  padding-bottom: 14px;
  top: -3px;
  left: -5px;
  transform: scaleX(-1);
}

.chat-icon {
  width: 49px;
  top: -13px;
}

.rooms-icon {
  width: 55px;
  top: -6px;
}

.device-change-icon {
  width: 41px;
  top: -5px;
  margin: 2px;
}

.leave-icon {
  width: 40px;
  top: -5px;
  left: 5px;
}

@media (min-width: 768px) {
  .host-hide {
    display: flex;
  }
}
