.container {
  margin-bottom: 2rem;
}

.session-info-left {
  background-color: white;
  padding: 25px;
  height: 100%;
}

.title {
  font-size: var(--title-font-size);
  color: var(--title-color);
  font-weight: var(--title-font-weight);
  letter-spacing: var(--title-letter-spacing);
  line-height: var(--title-line-height);
}

.description-container {
  padding-bottom: 20px;
}

.description {
  white-space: pre-wrap;
  color: #151519;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  overflow-wrap: anywhere;
  word-break: break-word;
  margin-bottom: 20px;
}

.external-notice-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 7px 0;
  padding: 5px;
  background-color: rgba(112, 181, 244, 0.1);
  font-style: italic;
  font-size: 14px;
  line-height: 1.3;
}

.external-logo {
  width: 35px;
  height: 35px;
}
.checkmark {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}

.additional-text {
  line-height: 1.3;
  font-size: 14px;
  font-style: italic;
  margin: 7px 0;
  padding: 5px;
  background-color: rgba(112, 181, 244, 0.1);
}

.testimonials-container {
  display: block;
}

.host-container {
  margin-top: 32px;
}

@media (min-width: 576px) {
  .external-notice-row {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  .external-logo {
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .container {
    margin-bottom: 0;
  }

  .need-offset {
    height: calc(100% - var(--timer-size));
  }
  .host-container {
    margin-top: 0;
  }

  .testimonials-container {
    display: block;
  }
}
