.like-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.is-reaction {
  position: absolute;
  left: 98%;
  transform: translateX(-50%);
  bottom: -9px;
}

.is-reaction .thumbs-container {
  width: 15px;
}

.is-reaction .total-likes {
  font-size: 12px;
  margin-left: 2px;
}

/* List */
.emoji-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.like-item .emoji-container {
  cursor: pointer;
}

.emoji-container-inner {
  display: flex;
  flex-direction: row-reverse;
}

.emoji {
  /* background-color: white; */
  border-radius: 100%;
}

.is-reaction .emoji {
  font-size: 14px;
}

.not-reaction .emoji:nth-child(3) {
  z-index: 1;
  position: relative;
  left: 3px;
}

.not-reaction .emoji:nth-child(2) {
  z-index: 2;
  position: relative;
  right: 3px;
}

.not-reaction .emoji:nth-child(1) {
  z-index: 3;
  position: relative;
  right: 7px;
}

.is-reaction .emoji:nth-child(2) {
  z-index: 2;
  position: relative;
  left: 6px;
}

.is-reaction .emoji:nth-child(3) {
  z-index: 3;
  position: relative;
  left: 10px;
}

/* Total likes number */
.total-likes {
  color: rgb(101, 103, 107);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  position: relative;
  top: 4px;
}

.not-reaction .total-likes {
  right: 4px;
}

.is-reaction .total-likes {
  right: 2px;
}

.is-reaction .no-likes {
  cursor: pointer;
  width: 15px;
}

/* EMOJIS SELECTOR */
.emojis-selector-container {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);

  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2px;
  /* width: 50px; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
}

.is-reaction .emojis-selector-container {
  left: auto;
  right: -25px;
}

.emoji-selector-item {
  padding: 3px;
  margin: 1px;
  cursor: pointer;
  border-radius: 3px;
}

.emoji-selector-item.active {
  background-color: rgba(112, 181, 244, 0.8);
}

.emoji-selector-item:hover {
  transition: transform 0.2s; /* Animation */
  transform: scale(1.2);
}

/* List */
.people-liked {
  color: rgb(101, 103, 107);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  padding-left: 5px;
}

/* LIKE ITEM BUTTON */
.like-item-button {
  width: 50%;
  padding: 7px 0;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.like-item-button:hover {
  background-color: #f2f2f2;
}

.like-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-align: center;
}

.like-button svg {
  width: 20px;
  margin-right: 5px;
}

.liked-text,
.unliked-text {
  font-size: 14px;
  font-weight: bold;
}

.unliked-text {
  color: rgb(28, 30, 33);
}
.liked-text {
  color: var(--color-primary);
}

@media (min-width: 756px) {
  .is-reaction .emojis-selector-container {
    left: 50%;
    right: auto;
  }
}
