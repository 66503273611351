.is-small {
  position: relative;
  z-index: 2;
}

.avatar-container {
  position: relative;
  margin-right: 10px;
}
.avatar-container.is-small {
  margin-right: 5px;
}

.avatar {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.avatar.is-small {
  width: 35px;
  height: 35px;
}

.placeholder {
  background: linear-gradient(to right, #58a3e7 0%, #25ba6c 100%);
}
