.container {
  width: 100%;
  background-color: #f4f1eb;
  text-align: center;
  padding: 50px;
}

.heading {
  padding-bottom: 15px;
  padding-top: 15px;
}

.items-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.item {
  margin: 1rem 2rem;
}

.item a {
  width: 100%;
  display: block;
  height: 100%;
}

.wsj {
  width: 150px;
}

.fortune {
  width: 150px;
}

.msnbc {
  width: 150px;
}

@media (min-width: 576px) {
  .container {
    padding-bottom: 65px !important;
  }

  .items-container {
    flex-direction: row;
  }

  .item {
    margin: 0 2rem;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
