.persistent-chat {
  /* Variables */
  --total-width: 100vw;
  --total-height: 100vh;
  --total-height: calc((calc(var(--vh, 1vh) * 100)));

  --header-height: 50px;
  --header-large-font: 20px;
  --header-large-color: rgb(15, 20, 25);

  --message-font-size: 14px;
  --message-line-height: 18px;
  --message-font-weight: 400;

  --footer-height: 60px;
}

.persistent-chat {
  height: var(--total-height);
  position: fixed;
  top: 0;
  z-index: 15;
  display: flex;
  background-color: white;
  width: var(--total-width);
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.persistent-chat > div {
  width: 100%;
}

@media (min-width: 768px) {
  .persistent-chat {
    /* Variables */
    --total-height: 450px;
    --total-width: 350px;

    height: auto;
    top: auto;
    bottom: 0;
    right: 80px;
  }
}
