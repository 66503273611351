.container {
  padding: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-style: solid;
  border-width: 8px 1px 1px;
  border-color: rgba(129, 131, 140, 0.5);
  border-radius: 6px;
  background-color: #fff;
  text-align: left;
  margin: 0 10px;
  cursor: pointer;
}

.selected,
.container:hover {
  -webkit-box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  box-shadow: 0.1875rem 0.1875rem 0.625rem #8c8c8c;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  border-color: var(--color-primary);
}

.subscription {
  padding: 10px 10px 10px 10px;
  border: 1px solid transparent;
  border-radius: 8px;
  border: 2px solid #dbdbde;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  opacity: 0.90;
  max-width: 300px;
  margin: 0 auto 16px;
}

.subscription:hover {
  /* background-color: rgba(135, 189, 238, 0.4); */
  border: 2px solid rgba(255, 194, 204, 0.2);
  box-shadow: 0.0425rem 0.0425rem 0.425rem hsl(0deg 0% 45% / 40%);
  opacity: 1;
  border: 1px solid #eeee;
}
.subscription.active {
  /* background-color: rgba(135, 189, 238, 0.4); */
  border: 2px solid rgba(255, 194, 204, 0.4);
  box-shadow: 0.0425rem 0.0425rem 0.425rem hsl(0deg 0% 45% / 40%);
  opacity: 1;
  border: 2px solid rgba(0,0,0,0.6);
}

.one {
  background-color: #eeeeee;
}

.two {
  background-color: #c4e2ff;
}

.three {
  background-color: #ffbb23;
}

.subscription .top {
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  color: #0d0c22;
  font-size: 18px;
}

.subscription .bottom {
  font-weight: 500;
  display: block;
  margin: 2px 0 10px;
  font-size: 28px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.free-trial {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: #6e6d7a;
}

.slashed-price {
  font-size: 25px;
  margin-right: 5px;
  opacity: 1;
  position: relative;
  color: #9f9c9c;
}

.slashed-price:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;

  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  transform: rotate(-8deg);
}

.cadence {
  line-height: 10px;
  margin-bottom: 5px;
}
.price-per-month {
  font-weight: bold;
  font-size: 18px;
}

@media (min-width: 678px) {
  .subscription {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .subscription .top {
    font-size: 22px;
  }

  .subscription .bottom {
    font-size: 32px;
  }
}
