.well-meatballs-container {
  z-index: 2;
  text-align: right;
  font-size: 18px;
  color: white;
  position: relative;
}
.well-meatballs-container::after {
  content: '•••';
  position: relative;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.well-meatballs-dropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 2px;
  right: 21px;
  border-radius: 5px;
  text-align: left;
}

.is-left {
  top: 21px;
  left: 23px;
}

.well-meatballs-dropdown.open {
  display: block;
}

.well-meatballs-option {
  display: block;
  cursor: pointer;
  color: black;
  font-size: 1rem;
  padding: 7px 10px;
  border-bottom: 1px solid #dcdcdd;
  border-left: 1px solid #dcdcdd;
  border-right: 1px solid #dcdcdd;
  font-weight: 400;
}

.well-meatballs-option:hover {
  text-decoration: none;
  background-color: #eaeaea;
}

.well-meatballs-option:first-child {
  border-top: 1px solid #dcdcdd;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.well-meatballs-option:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.disabled-option {
  opacity: 0.6;
  cursor: not-allowed;
}
.disabled-option:hover {
  background-color: white !important;
}
